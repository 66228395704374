import {LngLatLike} from "mapbox-gl";
import {Feature} from "@turf/helpers";

export interface CellGeoData {
  address: string,
  center: [number, number],
  context: CellGeoDataContext[],
  geometry: CellGeoDataGeometry,
  place_name: string,
  place_type: string[],
  properties: CellGeoDataProperties,
  relevance: number,
  text: string,
  type: string
}

export interface CellGeoDataProperties {
  accuracy: string,
  mapbox_id: string //it is not the feature.id, seems to be more like a request 'id' as it's always new
}

export interface CellGeoDataGeometry {
  coordinates: LngLatLike,
  type: string,
  id: string,
}

export interface CellGeoDataContext {
  id: string,
  mapbox_id: string,
  text: string,
  language: string,
  wikidata: string,
  short_code: string,
  center: number[]
}

export class GeocoderCache {
  private readonly cellDataStore = new Map<Feature['id'], CellGeoData>()


  constructor() {
  }

  public addCell(id: Feature['id'], data: CellGeoData) {
    if (!this.cellDataStore.has(id)) {
      this.cellDataStore.set(id, data)
    }
  }

  public getCellData(id: Feature['id']) {
    return this.cellDataStore.get(id)
  }
}
