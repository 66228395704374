import {Pipe, PipeTransform} from '@angular/core';
import {FeatureType, UserAccessService} from "../../../../../user/access/user-access.service";

@Pipe({
  name: 'isGroupAccessible'
})
export class IsGroupAccessiblePipe implements PipeTransform {

  constructor(private accessService: UserAccessService) {
  }

  transform(featuresArray: FeatureType[], isPlanUpdated: boolean | null): unknown {
    if (isPlanUpdated) {
      return featuresArray.some(feature => this.accessService.userHasAccess(feature))
    }
    return false;
  }

}
