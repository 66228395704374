import {FilteredPoiChartData, POIs} from "./pois.service";
import {
  BEAUTY_POI_CHART,
  COMPETITORS_POI_CHART,
  CONVENIENCE_POI_CHART, FAMILY_POI_CHART, FINANCIAL_SERVICES_POI_CHART,
  HEALTHCARE_POI_CHART, OUTDOOR_POI_CHART, RETIREMENT_POI_CHART
} from "../../../../../shared/types/feature-data-type";

const FOOD_AND_BEVERAGE = 'Food and beverage';
const GAS_STATIONS = 'Gas Stations';
const PHARMACIES = 'Pharmacies';
const CAR_WASHES = 'Car Washes';
const EV_CHARGERS = 'EV Chargers';
const CONVENIENCE_STORES = 'Convenience Stores';
const GROCERY = 'Grocery';
const SHOPPING_MALLS = 'Shopping Malls';
const URGENT_CARE = 'Urgent Care';

const CINEMAS_THEATERS_CONCERT_HALLS = 'Cinema & Concert'
const STADIUMS = 'Stadiums'
const FITNESS_CLUBS_GYMS = 'Fitness clubs, Gyms'

const DENTISTS = 'Dentists';
const HOSPITAL_AND_CLINICS = 'Hospitals and clinics';
const WELLNESS = 'Wellness'
const DOCTORS_OFFICES = 'Doctors\' Offices'
const HEALTH_CENTERS = 'Health Centers'

const NURSING_HOME = 'Nursing home';
const RETIREMENT_HOME = 'Retirement home'

const ATM = 'ATM'
const BANK = 'Bank'
const FINANCIAL_ADVICE = 'Financial advice'
const INSURANCE = 'Insurance'
const TAX_ADVISORS = 'Tax advisors'

const CHILDCARE = 'Childcare'
const BBQ_AND_PICNIC_AREAS = 'BBQ & Picnic areas'
const PLAYGROUNDS = 'Playgrounds'
const SOCIAL_FACILITY = 'Social facility'

const RECREATIONAL_PARKS = 'Recreational parks'
const PITCHES = 'Pitches'
const WALKING_PATHS = 'Walking paths'
const BICYCLE_ROUTES = 'Bicycle routes'

const MASSAGE = 'Massage'
const LASHES_AND_BROWS = 'Lashes and brows'
const TANNING = 'Tanning'
const BEAUTY_SALONS_AND_SPA = 'Beauty salons and spa'
const SKIN_CARE = 'Skin care'
const NAILS = 'Nails'
const HAIR = 'Hair'
const HAIR_REMOVAL = 'Hair removal'


type PropertyDetails = {
  color: string;
  regexp: RegExp;
};

// Map of properties to their details (color and regexp)
export const propertyDetailsMap: Map<string, PropertyDetails> = new Map([
  [RECREATIONAL_PARKS, { color: '#1F77B4', regexp: /(?:^|[;,\s])(nature_center|park|recreation|State Park|recreation center|recreational|rec_center|Urban Park|recration_ground|rectreation_ground|recreation_ground|planned_park|recreation_center|garden|outdoor_seating|dog_park|spray_park|spraypark|recreation_area)(?:$|[;,\s])/i }],
  [PITCHES, { color: '#FF9D00', regexp: /(?:^|[;,\s])(pitch|training_pitch|practice_pitch|ptich)(?:$|[;,\s])/i }],
  [WALKING_PATHS, { color: '#2CA02C', regexp: /(?:^|[;,\s])(walkway|trailhead|path|trails)(?:$|[;,\s])/i }],
  [BICYCLE_ROUTES, { color: '#D62728', regexp: /(?:^|[;,\s])(track|cycleway|bicycle;yes|designated)(?:$|[;,\s])/i }],
  [CHILDCARE, { color: '#9467BD', regexp: /(?:^|[;,\s])(kindergarten|childcare|day_care|daycare|Daycare Center|pre_school|Learning_Center\/_Day_Care|nursery|child_amusement_center|Child Care Center)(?:$|[;,\s])/i }],
  [BBQ_AND_PICNIC_AREAS, { color: '#8C564B', regexp: /(?:^|[;,\s])(bbq|picnic shelter|picnic area|picnic_site|indoor playground|picnic_area|picnic_table|picnic tables|picnic_shelter|picnic_awning|picnic)(?:$|[;,\s])/i }],
  [PLAYGROUNDS, { color: '#E377C2', regexp: /(?:^|[;,\s])(playground|palyground|indoor playground|playground_structure|playhouse|plagyround|plaground|play_ground|playgound)(?:$|[;,\s])/i }],
  [SOCIAL_FACILITY, { color: '#7F7F7F', regexp: /(?:^|[;,\s])(social_center|family_life_center|social_facility|social_security|social_service|social_services)(?:$|[;,\s])/i }],
  [CINEMAS_THEATERS_CONCERT_HALLS, { color: '#BCBD22', regexp: /(?:^|[;,\s])(cinema|theatre|theater_arts_center|concert_hall)(?:$|[;,\s])/i }],
  [ATM, { color: '#17BECF', regexp: /(?:^|[;,\s])(atm)(?:$|[;,\s])/i }],
  [BANK, { color: '#FFDC00', regexp: /(?:^|[;,\s])(bank)(?:$|[;,\s])/i }],
  [FINANCIAL_ADVICE, { color: '#005904', regexp: /(?:^|[;,\s])(financial_advice|financial_services|accountants|accounting|financial|financial_advisor|finance|investment_service|investments|accountant|bail_bond_agent|investment|investment_advisor|consulting|financial_planner|finances|financial_agent|financial_aid|financial_consult|tax_advisor;accountant;notary)(?:$|[;,\s])/i }],
  [INSURANCE, { color: '#FF8A8A', regexp: /(?:^|[;,\s])(insurance|insurance_agent)(?:$|[;,\s])/i }],
  [TAX_ADVISORS, { color: '#2196F3', regexp: /(?:^|[;,\s])(tax|tax_advisor|tax_service|tax preparation|tax_preparation)(?:$|[;,\s])/i }],
  [NURSING_HOME, { color: '#FF4081', regexp: /(?:^|[;,\s])(Nursing_Home|nursing_room|nursing facility|Elder Care|Home Nursing|Nursing|nursing_service|adult_care|elder_assistance|eldercare)(?:$|[;,\s])/i }],
  [RETIREMENT_HOME, { color: '#04E6CB', regexp: /(?:^|[;,\s])(retirement_home|Retirement_Community|Retirement Center|Retirement community|retirement_village|Residential care facility|Assisted Living)(?:$|[;,\s])/i }],
  [DENTISTS, { color: '#9C27B0', regexp: /(?:^|[;,\s])(dentist|dental|medical_&_dental_center|healthcare:dentist)(?:$|[;,\s])/i }],
  [HOSPITAL_AND_CLINICS, { color: '#BE5F00', regexp: /(?:^|[;,\s])(clinic|hospital|health_post)(?:$|[;,\s])/i }],
  [WELLNESS, { color: '#001DC3', regexp: /(?:^|[;,\s])(wellness|wellness_centre|Mental health clinic|mental_health_service)(?:$|[;,\s])/i }],
  [DOCTORS_OFFICES, { color: '#333333', regexp: /(?:^|[;,\s])(doctors|Medical_Office|Health Office|Pediatric Therapy|Public Health Office|medical_practise|therapist|physician|doctor|medical_offices|physical_therapist)(?:$|[;,\s])/i }],
  [URGENT_CARE, { color: '#D4E157', regexp: /(?:^|[;,\s])(emergency_service|first_aid|ambulance_station|immediate care center|emergency_services|emergency)(?:$|[;,\s])/i }],
  [HEALTH_CENTERS, { color: '#591DBB', regexp: /(?:^|[;,\s])(Health Center|Mercy Medical Center|Good Samaritan Medical Center|Metro West Medical Center|Morton Hospital And Medical Center|North Shore Medical Center\/Union Hospital|Saints Memorial Medical Center|St. Elizabeth's Medical Center|V.A. Medical Center)(?:$|[;,\s])/i }],
  [GAS_STATIONS, { color: '#FD9778', regexp: /(?:^|[;,\s])(fuel|gas_station|gasoline|gasstation|gas|gas-station)(?:$|[;,\s])/i }],
  [GROCERY, { color: '#87E2FF', regexp: /(?:^|[;,\s])(supermarket|grocery|grocery store|grocery_store)(?:$|[;,\s])/i }],
  [FOOD_AND_BEVERAGE, { color: '#DEAE14', regexp: /(?:^|[;,\s])(restaurant|fast_food|cafe|bar|bistro|cafeteria|café|pub)(?:$|[;,\s])/i }],
  [EV_CHARGERS, { color: '#83AB55', regexp: /(?:^|[;,\s])(charging_station|car_charging)(?:$|[;,\s])/i }],
  [STADIUMS, { color: '#890D0D', regexp: /(?:^|[;,\s])(stadium|baseball stadium)(?:$|[;,\s])/i }],
  [FITNESS_CLUBS_GYMS, { color: '#C0AAFF', regexp: /(?:^|[;,\s])(pilates|fitness_centre|gym|fitness_station|yoga_studio|fitness)(?:$|[;,\s])/i }],
  [CONVENIENCE_STORES, { color: '#FDFFA9', regexp: /(?:^|[;,\s])(convenience|convenience_store)(?:$|[;,\s])/i }],
  [SHOPPING_MALLS, { color: '#3FF847', regexp: /(?:^|[;,\s])(mall|shopping_center|strip_mall|shopping_centre)(?:$|[;,\s])/i }],
  [PHARMACIES, {color: '#793300', regexp: /(?:^|[;,\s])(pharmacy|drug_store|Retail_and_Pharmacy|drugstore|pharmacist)(?:$|[;,\s])/i} ],
  [CAR_WASHES, {color: '#F4B3FF', regexp: /(?:^|[;,\s])(car_wash|carwash|car wash)(?:$|[;,\s])/i} ],
  [NAILS, {color: '#FFA0F2', regexp: /(?:^|[;,\s])(dipping_nails|nail_service|manicure_in_corpus_christi|gel_lcn_nails|pedicure_in_corpus_christi|nails|nail|pedicure|manicures|pedicures|manicure|nail_salon|acrylic_nails|gel_manicure|full_set_nails|dipping_power_nails|nails_spa|day_sp|acrylic|glitter_toes|dipping_powder|_nails|_Nail_Salon|_Pedicure|_Manicure)(?:$|[;,\s])/i}],
  [HAIR, {color: '#E6EE2D', regexp: /(?:^|[;,\s])(hair|hair_care|haircutting|natural_hair_care|barber_shop|hair_replacement|kids_haircuts|ladies_haircuts|mens_haircuts|hair_supplier|braiding_service|african_hair_braidinghairdressing|extensions|hair_colour|hair_products|_Beauty_Salon|_Keratin_Treatment|_Hair_Color|_Hair_Stylist|balayage|perm|vivid_hair_color|ombre|foilage|blowout|hair_restoration|women_hair_cuts|man_hair_cuts|senior_cuts|hot_towel_shave|brazilian_blowout|roller_sets|barber|digital_perm|straightening|hair_color|haircut|styling|wigs|men's_haircut|hair_braiding|hair_extension|hair_extensions|hair_salon|beauty_salon|hair_cuts|keratin_treatment|dominican_blowout|hair_stylist|full_service_hair_salon|haircuts|hair_styling|hair_coloring|hair_highlights)(?:$|[;,\s])/i}],
  [SKIN_CARE, {color: '#FFF2CC', regexp: /(?:^|[;,\s])(co2re|dermaplane|facials_mckinney|lip_enhancement|kybella|dermal_fillers|skin_treatments|chemical_peels|chemical_peel|microneedling|esthetics|injectables|hydrafacial|skincare|laser_skin_rejuvenation|permanent_makeup|injections|skin_polish|highlights|lip_fillers|care|aesthetic|skin_clinic|_make-up|skin_care|skin|cosmetics|botox|juvederm|restylane|jeuveau|microdneedling|makeup|facial|facials)(?:$|[;,\s])/i}],
  [TANNING, {color: '#B9922F', regexp: /(?:^|[;,\s])(tanning|spray_tanning|tan|tanning_salon|spray_tan)(?:$|[;,\s])/i}],
  [MASSAGE, {color: '#D0CFCF', regexp: /(?:^|[;,\s])(massage|_Massage_Therapy|body_contouring|body_sculpting)(?:$|[;,\s])/i}],
  [LASHES_AND_BROWS, {color: '#B593EE', regexp: /(?:^|[;,\s])(microblading|eyelash_extension_services|brow|theading|lash_extensions|brows|brow_lamination|brow_henna|threading|lash|Threading_Service|eyebrow_tinting|eyebrow_lamination|eyebrow_threading|eyebrow|eyelash|eyebrows|eyelashes|eyelash_extensions|eyelash_extention|lashes|extensions|eyelash_extension)(?:$|[;,\s])/i}],
  [HAIR_REMOVAL, {color: '#E2EFDA', regexp: /(?:^|[;,\s])(removal|sugaring|facial_hair|_waxing_and_hair_removal|electrolysis|foot|body_waxing|_electrolysis|laser|lasers|manzilian_waxing|bikini_waxing|brazilian_waxing|facial_waxing|lazer_hair_removal|_Waxing_&|wax|waxing|hair_removal|laser_hair_removal|full_body_waxing|waxing_services|waxing_in_corpus_christi)(?:$|[;,\s])/i}],
  // [BEAUTY_SALONS_AND_SPA, {color: '#9FF8F8', regexp: /(?:^|[;,\s])(medical_spa|salon_services|Salon|day_spa|spa|_spa|_Day_Spa|foot_spa)(?:$|[;,\s])/i}],
]);
function sortData(data: POIs, propertyMap: Map<string, PropertyDetails>): FilteredPoiChartData {
  const sortedData: FilteredPoiChartData = {};

  // Initialize sortedData keys with 0
  propertyMap.forEach((_, key) => {
    sortedData[key] = 0;
  });

  for (const [key, value] of Object.entries(data)) {

    for (const [propertyType, { regexp }] of propertyMap) {
      if (regexp.test(key)) {
        sortedData[propertyType] += value;
        break;
      }
    }
  }

  return filterOutLowPercentage(sortedData);
}

const outdoorMap = new Map<string, PropertyDetails>([
  [RECREATIONAL_PARKS, propertyDetailsMap.get(RECREATIONAL_PARKS)!],
  [PITCHES, propertyDetailsMap.get(PITCHES)!],
  [WALKING_PATHS, propertyDetailsMap.get(WALKING_PATHS)!],
  [BICYCLE_ROUTES, propertyDetailsMap.get(BICYCLE_ROUTES)!]
]);

const beautyMap = new Map<string, PropertyDetails>([
  [MASSAGE, propertyDetailsMap.get(MASSAGE)!],
  [TANNING, propertyDetailsMap.get(TANNING)!],
  [NAILS, propertyDetailsMap.get(NAILS)!],
  // [BEAUTY_SALONS_AND_SPA, propertyDetailsMap.get(BEAUTY_SALONS_AND_SPA)!],
  [LASHES_AND_BROWS, propertyDetailsMap.get(LASHES_AND_BROWS)!],
  [SKIN_CARE, propertyDetailsMap.get(SKIN_CARE)!],
  [HAIR, propertyDetailsMap.get(HAIR)!],
  [HAIR_REMOVAL, propertyDetailsMap.get(HAIR_REMOVAL)!]
])

export function sortBeautyData(data: POIs): FilteredPoiChartData {
  return sortData(data, beautyMap)
}

export function sortOutdoorData(data: POIs): FilteredPoiChartData {
  return sortData(data, outdoorMap);
}

const familyMap = new Map<string, PropertyDetails>([
  [CHILDCARE, propertyDetailsMap.get(CHILDCARE)!],
  [BBQ_AND_PICNIC_AREAS, propertyDetailsMap.get(BBQ_AND_PICNIC_AREAS)!],
  [PLAYGROUNDS, propertyDetailsMap.get(PLAYGROUNDS)!],
  [SOCIAL_FACILITY, propertyDetailsMap.get(SOCIAL_FACILITY)!],
  [CINEMAS_THEATERS_CONCERT_HALLS, propertyDetailsMap.get(CINEMAS_THEATERS_CONCERT_HALLS)!]
]);
export function sortFamilyData(data: POIs): FilteredPoiChartData {
  return sortData(data, familyMap);
}

const financialServicesMap = new Map<string, PropertyDetails>([
  [ATM, propertyDetailsMap.get(ATM)!],
  [BANK, propertyDetailsMap.get(BANK)!],
  [FINANCIAL_ADVICE, propertyDetailsMap.get(FINANCIAL_ADVICE)!],
  [INSURANCE, propertyDetailsMap.get(INSURANCE)!],
  [TAX_ADVISORS, propertyDetailsMap.get(TAX_ADVISORS)!]
]);
export function sortFinancialServicesData(data: POIs): FilteredPoiChartData {
  return sortData(data, financialServicesMap);
}

const retirementMap = new Map<string, PropertyDetails>([
  [NURSING_HOME, propertyDetailsMap.get(NURSING_HOME)!],
  [RETIREMENT_HOME, propertyDetailsMap.get(RETIREMENT_HOME)!]
]);
export function sortRetirementData(data: POIs): FilteredPoiChartData {
  return sortData(data, retirementMap);
}

const healthcareMap = new Map<string, PropertyDetails>([
  [DENTISTS, propertyDetailsMap.get(DENTISTS)!],
  [HOSPITAL_AND_CLINICS, propertyDetailsMap.get(HOSPITAL_AND_CLINICS)!],
  [WELLNESS, propertyDetailsMap.get(WELLNESS)!],
  [DOCTORS_OFFICES, propertyDetailsMap.get(DOCTORS_OFFICES)!],
  [URGENT_CARE, propertyDetailsMap.get(URGENT_CARE)!],
  [HEALTH_CENTERS, propertyDetailsMap.get(HEALTH_CENTERS)!]
]);
export function sortHealthcareData(data: POIs): FilteredPoiChartData {
  return sortData(data, healthcareMap);
}

const convenienceMap = new Map<string, PropertyDetails>([
  [GAS_STATIONS, propertyDetailsMap.get(GAS_STATIONS)!],
  [GROCERY, propertyDetailsMap.get(GROCERY)!],
  [FOOD_AND_BEVERAGE, propertyDetailsMap.get(FOOD_AND_BEVERAGE)!],
  [EV_CHARGERS, propertyDetailsMap.get(EV_CHARGERS)!],
  [STADIUMS, propertyDetailsMap.get(STADIUMS)!],
  [FITNESS_CLUBS_GYMS, propertyDetailsMap.get(FITNESS_CLUBS_GYMS)!],
]);
export function sortConvenienceData(data: POIs): FilteredPoiChartData {
  return sortData(data, convenienceMap);
}

const competitorsMap = new Map<string, PropertyDetails>([
  [FOOD_AND_BEVERAGE, propertyDetailsMap.get(FOOD_AND_BEVERAGE)!],
  [GAS_STATIONS, propertyDetailsMap.get(GAS_STATIONS)!],
  [PHARMACIES, propertyDetailsMap.get(PHARMACIES)!],
  [CAR_WASHES, propertyDetailsMap.get(CAR_WASHES)!],
  [EV_CHARGERS, propertyDetailsMap.get(EV_CHARGERS)!],
  [CONVENIENCE_STORES, propertyDetailsMap.get(CONVENIENCE_STORES)!],
  [GROCERY, propertyDetailsMap.get(GROCERY)!],
  [SHOPPING_MALLS, propertyDetailsMap.get(SHOPPING_MALLS)!],
  [URGENT_CARE, propertyDetailsMap.get(URGENT_CARE)!]
]);
export function sortCompetitorsData(data: POIs): FilteredPoiChartData {
  return sortData(data, competitorsMap);
}

export const chartToMap = new Map<string, Map<string, PropertyDetails>>([
  [COMPETITORS_POI_CHART, competitorsMap],
  [CONVENIENCE_POI_CHART, convenienceMap],
  [HEALTHCARE_POI_CHART, healthcareMap],
  [BEAUTY_POI_CHART, beautyMap],
  [RETIREMENT_POI_CHART, retirementMap],
  [FINANCIAL_SERVICES_POI_CHART, financialServicesMap],
  [FAMILY_POI_CHART, familyMap],
  [OUTDOOR_POI_CHART, outdoorMap],
])

function filterOutLowPercentage(sortedData: FilteredPoiChartData): FilteredPoiChartData {
  const totalValue = Object.values(sortedData).reduce((acc, val) => acc + val, 0)

  // Value in % below which we won't show labels in pois chart
  const ignoredPercentage = 1

  return Object.fromEntries(Object.entries(sortedData)
    .filter(([key, value]) =>  (value / totalValue) * 100 > ignoredPercentage)
    .map(([key, value]) => [key, Math.round(value)]))
}
