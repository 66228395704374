import {animate, keyframes, state, style, transition, trigger} from "@angular/animations";

export const inputAnimations = [
  trigger('shake', [
    state('inactive', style({transform: 'translateX(0)'})),
    state('active', style({transform: 'translateX(0)'})),
    transition('inactive => active', [
      animate('300ms', keyframes([
        style({transform: 'translateX(0)'}),
        style({transform: 'translateX(-10px)'}),
        style({transform: 'translateX(10px)'}),
        style({transform: 'translateX(-10px)'}),
        style({transform: 'translateX(10px)'}),
        style({transform: 'translateX(0)'})
      ]))
    ])
  ])
]
