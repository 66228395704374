// https://stackoverflow.com/a/34890276/9064478

export function groupBy(xs: any, key: string) {
    return xs.reduce((rv: any, x: any) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}
// https://stackoverflow.com/questions/1960473
export function onlyUnique(items: any[]) {
  return [...new Set(items)]
}

// https://gist.github.com/DazzGranto/8d53b062dd17fa63992ea13bb44e9728
export const range = (start: number, stop: number, step = 1) => {
 return Array.from({length: (stop - start) / step + 1}, (_, i) => start + (i * step));
}

//
/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export function groupByLambda<K, V>(list: Array<V>, keyGetter: (input: V) => K,): Map<K, Array<V>> {
  const map = new Map<K, Array<V>>();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
