import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {BreakpointObserverService} from "./breakpoint-observer.service";

export const PORTRAIT = 'portrait'
export const LANDSCAPE = 'landscape'
export const LANDSCAPE_MOBILE = 'LANDSCAPE_MOBILE'

@Injectable({
  providedIn: 'root'
})
export class DeviceOrientationService {
  public orientation: Subject<string> = new Subject<string>();

  constructor(
    public breakpointObserverService: BreakpointObserverService,
  ) {
    // First run detect orientation to have initial value in subject
    this.detectOrientation()
    window.addEventListener('orientationchange', (event) => {
      this.detectOrientation()
    });
  }


  public detectOrientation(): void {
    const currentOrientation = window.screen?.orientation;
    if (currentOrientation && currentOrientation.type && currentOrientation.type.includes(PORTRAIT)) {
      this.orientation.next(PORTRAIT);
    } else if (currentOrientation && currentOrientation.type && currentOrientation.type.includes(LANDSCAPE) && this.breakpointObserverService.checkIfMobileDevice()) {
      this.orientation.next(LANDSCAPE_MOBILE);
    }
  }
}
