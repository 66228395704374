<div class="forgot-password-container container">
  <div class="forgot-password-window main-window">
    <div
      (click)="goToSigninMenu()"
      class="menu-close-btn"
    >
      <img src="../../../../assets/icons/rightMenuCloseBtn.svg" alt="exit">
    </div>

    <div class="menu-close-btn-mobile" (click)="goToSigninMenu()">
      <img src="../../../../assets/icons/Cancel.svg" alt="exit">
    </div>

    <h2>Forgot password</h2>

    <form [formGroup]="resetPasswordForm" class="reset-password-form">
    <div class="input-wrapper">
      <div class="input-container password-container">
        <label for="password">Password</label>
        <input formControlName="password" [type]="hidePassword ? 'password' : 'text'"
               [@shake]="passwordShakeState"
               (@shake.done)="passwordShakeState='inactive'"
               [class.invalid-input]="isInputStatusInvalid('password')"
               (ngModelChange)="formSubmitted = false"
               id="password">
        <button type="button" class="show-password-btn" (click)="hidePassword = !hidePassword">
          <img *ngIf="hidePassword; else showIcon" src="../../../../assets/icons/EyeClosed.svg" alt="hide-password">
          <ng-template #showIcon>
            <img src="../../../../assets/icons/Eye.svg" alt="show-password">
          </ng-template>
        </button>
      </div>
      <div class="error-messages" *ngIf="resetPasswordForm.get('password')!.touched">
        <label *ngIf="resetPasswordForm.get('password')!.errors?.lengthError">Password must be between 8 and 32 characters.</label>
        <label *ngIf="resetPasswordForm.get('password')!.errors?.lowercaseError">Password must contain at least one lowercase character.</label>
        <label *ngIf="resetPasswordForm.get('password')!.errors?.uppercaseError">Password must contain at least one uppercase character.</label>
        <label *ngIf="resetPasswordForm.get('password')!.errors?.digitOrSpecialCharError">Password must contain at least one digit or special character.</label>
        <label *ngIf="resetPasswordForm.get('password')!.errors?.illegalCharError">Password contains an invalid character.</label>
      </div>


      <div class="input-container password-container">
        <label for="passwordConfirm">Confirm password</label>

        <input formControlName="passwordConfirm" [type]="hidePassword ? 'password' : 'text'"
               [@shake]="passwordShakeState"
               (@shake.done)="passwordShakeState='inactive'"
               [class.invalid-input]="isInputStatusInvalid('passwordConfirm')"
               (ngModelChange)="formSubmitted = false"
               id="passwordConfirm">

        <button type="button" class="show-password-btn" (click)="hidePassword = !hidePassword">
          <img *ngIf="hidePassword; else showIcon" src="../../../../assets/icons/EyeClosed.svg" alt="hide-password">
          <ng-template #showIcon>
            <img src="../../../../assets/icons/Eye.svg" alt="show-password">
          </ng-template>
        </button>
      </div>
      <div class="error-messages" *ngIf="!doesPasswordMatch()">
        <label>Password must match</label>
      </div>
    </div>
    </form>
    <button class="gradient-button" (click)="onSubmit()">Update password</button>

    <span class="go-to-signup">Do not have an account? <span class="link-like-text" (click)="goToSignupMenu()">Create account</span></span>
  </div>
</div>

