<div class="container">
  <div class="main-window sign-in-window">

    <!--    <div-->
    <!--      (click)="closeSignInMenu()"-->
    <!--      class="menu-close-btn"-->
    <!--    >-->
    <!--      <img src="../../../assets/icons/rightMenuCloseBtn.svg" alt="exit">-->
    <!--    </div>-->


    <!--    <div class="menu-close-btn-mobile" (click)="closeSignInMenu()">-->
    <!--      <img src="../../../assets/icons/Cancel.svg" alt="exit">-->
    <!--    </div>-->

    <h2>Welcome to Map AI</h2>
    <div class="google-sign-in-wrapper">
      <div id="google-sign-in" class="google-sign-in"></div>
      <label>or</label>
    </div>

    <form class="sign-in-form" [formGroup]="signInForm" (ngSubmit)="onSubmit()"
          #myForm="ngForm">
      <div class="input-wrapper">
        <div class="input-container">
          <label for="email">Email</label>
          <label *ngIf="isInputStatusInvalid('email') && !badCredentials" for="email" class="invalid-email-label">Invalid
            email format</label>
          <input formControlName="email"
                 (change)="updateStoredEmail($event)"
                 [@shake]="emailShakeState"
                 (@shake.done)="emailShakeState='inactive'"
                 [class.invalid-input]="isInputStatusInvalid('email') || badCredentials"
                 (ngModelChange)="formSubmitted = false"
                 (focus)="badCredentials = false"
                 id="email">

        </div>
      </div>

      <div class="input-wrapper">
        <div class="input-container password-container">
          <label for="password">Password</label>

          <input formControlName="password" [type]="hidePassword ? 'password' : 'text'"
                 [@shake]="passwordShakeState"
                 (@shake.done)="passwordShakeState='inactive'"
                 [class.invalid-input]="isInputStatusInvalid('password') || badCredentials"
                 (ngModelChange)="formSubmitted = false"
                 (focus)="badCredentials = false"
                 id="password">

          <button type="button" class="show-password-btn" (click)="hidePassword = !hidePassword">
            <img *ngIf="hidePassword; else showIcon" src="../../../assets/icons/EyeClosed.svg" alt="show map-ai password">
            <ng-template #showIcon>
              <img src="../../../assets/icons/Eye.svg" alt="hide password">
            </ng-template>
          </button>
        </div>
        <div class="error-messages" *ngIf="badCredentials">
          <label>Invalid email or password</label>
        </div>
      </div>


      <span class="forgot-password-text" (click)="goToPasswordRecovery()">Forgot password</span>
    </form>


    <div class="buttons-container">
      <button class="sign-in-button gradient-button"
              (click)="checkInputs(); myForm.ngSubmit.emit()"
              [class.inactive]="signInForm.invalid && signInForm.dirty"
              [attr.data-cy]="'log-in-button'"
      >Log in
      </button>
      <button class="create-account-button white-button-gradient" (click)="goToSignupMenu()" [attr.data-cy]="'create-account-button'">Create account</button>
    </div>


  </div>
</div>
