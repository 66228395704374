import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BreakpointObserverService {
    public readonly isMobile = new BehaviorSubject<boolean>(false);

    constructor(private breakpointObserver: BreakpointObserver) {
        this.breakpointObserver
            .observe(['(max-width: 768px)'])
            .subscribe((result) => {
                this.isMobile.next(result.matches);
                // this.isMobile.next(result.matches && this.checkIfMobileDevice())
            });
    }

    //http://detectmobilebrowsers.com/ - list of mobile devices
    public checkIfMobileDevice(): boolean {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /android|webos|iphone|ipad|ipod|iemobile|opera mini|windows phone|silk|mobile|xoom|sch-i800|playbook|tablet|kindle|nook|samsung|lg|htc|sony|motorola|nokia|huawei|xiaomi|vivo|oppo|realme|blackberry|alcatel|zte|tcl|lenovo|google pixel|miui|palm|symbian|panasonic|sharp|philips|sanyo|spice|portalmmm|skyfire|teashark|uc browser|meizu|micromax|benq|bada\/|brew|maui|docomo|simvalley|wolfgang|haier|jbrowser|lynx|vodafone|wap|windows ce|xda|xiino/i.test(
            userAgent
        );
    }
}
