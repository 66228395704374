import {Injectable} from '@angular/core';
import {AllLayers} from '../../base/layers';
import {LayerStoreService} from '../../services/layer-store.service';
import {FeatureFocusService} from '../../../feature-focus/feature-focus.service';
import {BehaviorSubject, debounceTime} from 'rxjs';
import {COMBINED_HWW_BY_COUNTRY, COMBINED_HWW_BY_STATE, hwwFeatures} from "../../../../shared/types/feature-data-type";
import { Buckets } from '../../../menu/right-menu/layers-menu/shared/util';

@Injectable({
  providedIn: 'root'
})
export class ColorscaleService {
  public colorScaleColors!: any[][];
  public cellNumHistogram!: number[];
  public isBeingLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  private readonly commonColorScale =
    AllLayers.description[AllLayers.COMMON_LAYER].colorScale!;

  private readonly genderPayGapColorScale =
    AllLayers.description[AllLayers.GENDER_PAY_GAP_COLORSCALE].colorScale!

  private readonly progressiveColorScale =
    AllLayers.description[AllLayers.PROGRESSIVE_SCALE].colorScale!;

  private readonly medianIncomeColorScale =
    AllLayers.description[AllLayers.MEDIAN_INCOME].colorScale!

  private readonly giniIndexColorScale =
    AllLayers.description[AllLayers.GINI_INDEX].colorScale!

  private readonly trendsColorScale =
    AllLayers.description[AllLayers.TRENDS_COLORSCALE].colorScale!

  private readonly moreNegativeColorScale =
    AllLayers.description[AllLayers.MORE_NEGATIVE_COLORSCALE].colorScale!

  private healthIndexColorScale = AllLayers.description[AllLayers.HEALTH_INDEX].colorScale!
  private wealthIndexColorScale = AllLayers.description[AllLayers.WEALTH_INDEX].colorScale!
  private wiseIndexColorScale = AllLayers.description[AllLayers.WISE_INDEX].colorScale!


  constructor(private layerStore: LayerStoreService,
              private focusedFeatures: FeatureFocusService,) {
    this.focusedFeatures.focusedFeatures
      .pipe(debounceTime(200))
      .subscribe((focusedFeature) => {
        const visibleFeature = this.layerStore.activeLayer.getValue()
        const values = focusedFeature.ofType(visibleFeature).values();

        this.isBeingLoaded.next(focusedFeature.isBeingLoaded() || focusedFeature.getFeatures().length === 0)

        if (hwwFeatures.includes(visibleFeature) && (visibleFeature !== COMBINED_HWW_BY_STATE && visibleFeature !== COMBINED_HWW_BY_COUNTRY)) {
          if (AllLayers.description[AllLayers.HEALTH_INDEX].layers.includes(visibleFeature)) {
            this.colorScaleColors = this.healthIndexColorScale.toThresholdColorArray(
              focusedFeature.ofType(visibleFeature).values()
            );
          } else if (AllLayers.description[AllLayers.WEALTH_INDEX].layers.includes(visibleFeature)) {
            this.colorScaleColors = this.wealthIndexColorScale.toThresholdColorArray(
              focusedFeature.ofType(visibleFeature).values()
            );
          } else if (AllLayers.description[AllLayers.WISE_INDEX].layers.includes(visibleFeature)) {
            this.colorScaleColors = this.wiseIndexColorScale.toThresholdColorArray(
              focusedFeature.ofType(visibleFeature).values()
            );
          }
        } else if (AllLayers.description[AllLayers.TRENDS_COLORSCALE].layers.includes(visibleFeature)) {
          this.colorScaleColors = this.trendsColorScale.toThresholdColorArray(
            focusedFeature.ofType(visibleFeature).values()
          );
        } else if (AllLayers.description[AllLayers.GENDER_PAY_GAP_COLORSCALE].layers.includes(visibleFeature)) {
          this.colorScaleColors = this.genderPayGapColorScale.toThresholdColorArray(
            focusedFeature.ofType(visibleFeature).values())
        } else if (AllLayers.description[AllLayers.MORE_NEGATIVE_COLORSCALE].layers.includes(visibleFeature)) {
          this.colorScaleColors = this.moreNegativeColorScale.toThresholdColorArray(
            focusedFeature.ofType(visibleFeature).values()
          );
        } else if (AllLayers.description[AllLayers.PROGRESSIVE_SCALE].layers.includes(visibleFeature)) {
          this.colorScaleColors = this.progressiveColorScale.toThresholdColorArray(
            focusedFeature.ofType(visibleFeature).values()
          );
        } else if (AllLayers.description[AllLayers.MEDIAN_INCOME].layers.includes(visibleFeature)) {
          this.colorScaleColors = this.medianIncomeColorScale.toThresholdColorArray(
            focusedFeature.ofType(visibleFeature).values()
          )
        } else if (AllLayers.description[AllLayers.GINI_INDEX].layers.includes(visibleFeature)) {
          this.colorScaleColors = this.giniIndexColorScale.toThresholdColorArray(
            focusedFeature.ofType(visibleFeature).values()
          )
        } else {
          this.colorScaleColors = this.commonColorScale.toThresholdColorArray(
            focusedFeature.ofType(visibleFeature).values()
          );
        }
        const bucketsRanges = this.colorScaleColors.map(c => c[0]);
        const buckets = new Buckets(bucketsRanges);
        // TODO:filter out values filtered by filters
        this.cellNumHistogram = buckets.countBucketsWithin(values);
      })

  }
}
