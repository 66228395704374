export const environment = {
  production: true,
  apiUrl: 'https://interactive-map-ai.com/api/',
  domainUrl: 'https://interactive-map-ai.com/',
  mapbox: {
    accessToken: 'pk.eyJ1IjoibWFwLWFpIiwiYSI6ImNsbGdudHJxZjAyOXAzaG12c29hbm92OTUifQ.eUarODw_mnWwuJwnwWm_aA'
  },
  // URL-bounded token
  googleAuthClientId: '1017199604475-2dn7ms2dl5m12il90e3lpljqf3382bnl.apps.googleusercontent.com',
  SENTRY_AUTH_TOKEN: "20155b74856ab93994b089e6bf06ca3d860b7ccd0f5b4dafee90a921fc1b35bc"
};
