import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BreakpointObserverService} from "../../../../shared/services/breakpoint-observer.service";
import {MenuGroupType} from "../../../map.component";

@Component({
  selector: 'app-map-menu',
  templateUrl: './map-menu.component.html',
  styleUrls: ['./map-menu.component.scss'],
})
export class MapMenuComponent implements OnInit {
  @Input() activeMenuGroup!: MenuGroupType

  @Output() closeRightMenu = new EventEmitter<void>;


  constructor(public breakpointObserverService: BreakpointObserverService) {
  }

  ngOnInit(): void {
  }

  public emitCloseMenuMobile(): void {
    this.closeRightMenu.emit()
  }
}
