import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { JsonResponse } from '../../shared/api/backend-config';
import { catchError } from 'rxjs';
import { GoogleAuth0Response, renderGoogleSignIn } from '../oauth';
import { inputAnimations } from '../../shared/util/inputAnimations';
import { REDIRECT_AFTER_LOGIN_PATH } from '../../app.component';

export interface AuthDataJSON {
  tokens: {
    access: string;
    refresh: string;
  };
}

export const EMAIL_KEY = 'email';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss',
    '../../app.component.scss',
    '../user.shared.scss'],
  animations: inputAnimations
})
export class SignInComponent implements OnInit, AfterViewInit {
  public emailShakeState: string = 'inactive';
  public passwordShakeState: string = 'inactive';
  public formSubmitted = false;
  public hidePassword = true;
  public badCredentials = false;

  public signInForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required]
  })


  constructor(private fb: FormBuilder,
              private router: Router,
              private authService: AuthenticationService,
              private ngZone: NgZone) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    renderGoogleSignIn('google-sign-in', this.handleCredentialResponse.bind(this));
    if (sessionStorage.getItem(EMAIL_KEY)) {
      this.signInForm.get('email')?.setValue(sessionStorage.getItem(EMAIL_KEY))
    }
  }

  private handleCredentialResponse(response: GoogleAuth0Response) {
    this.authService.loginGoogle(response)
        .subscribe((data: JsonResponse<AuthDataJSON>) => {
          if (!!data.object.tokens.access){
            this.ngZone.run(() => {
              sessionStorage.removeItem(EMAIL_KEY)
              this.router.navigateByUrl(localStorage.getItem(REDIRECT_AFTER_LOGIN_PATH) ?? '/');
              localStorage.removeItem(REDIRECT_AFTER_LOGIN_PATH)
            });
          }
        })
  }

  public onSubmit() {
    this.formSubmitted = true;

    if (!this.signInForm.invalid) {
      this.authService.login(this.signInForm.value.email!, this.signInForm.value.password!)
        .pipe(catchError(err => {
          this.badCredentials = true;
          throw err
        }))
        .subscribe((data: JsonResponse<AuthDataJSON>) => {
          if (!!data.object.tokens.access){
            sessionStorage.removeItem(EMAIL_KEY)
          }
        })
    }
  }

  public updateStoredEmail(event: any) {
    // Use session storage as it's automatically cleared when the tab is closed
    sessionStorage.setItem(EMAIL_KEY, event.target.value);
  }

  public isInputStatusInvalid(inputName: string) {
    return this.signInForm.get(inputName)?.invalid && (this.signInForm.get(inputName)?.touched || this.formSubmitted || this.signInForm.get(inputName)?.dirty);
  }

  public closeSignInMenu(): void {
    this.router.navigate(['/'])
  }

  public goToSignupMenu(): void {
    this.router.navigate(['/sign-up'])
  }

  public goToPasswordRecovery(): void {
    this.router.navigate(['/password-recovery'])
  }

  public checkInputs(): void {
    if (this.isInputStatusInvalid('email')) {
      this.emailShakeState = 'active';
    }
    if (this.isInputStatusInvalid('password')) {
      this.passwordShakeState = 'active';
    }
  }

}
