/**
 * Represents a range of numbers, fromInclusive to toExclusive.
 */
export class Range {
  fromInclusive: number;
  toExclusive: number;

  constructor(fromInclusive: number, toExclusive: number) {
    if (fromInclusive >= toExclusive) {
      throw new Error('fromInclusive must be smaller than toExclusive, got '
        + fromInclusive + ' - ' + toExclusive);
    }
    this.fromInclusive = fromInclusive;
    this.toExclusive = toExclusive;
  }

  /**
   * @return true if this range contains the whole other range.
   * true if ranges are equal.
   *ex, true if this = [0; 5], range = [1; 4] / range = [0; 5]
   */
  public containsWholeRange(range: Range): boolean {
    return this.contains(range.fromInclusive) && range.toExclusive <= this.toExclusive;
  }

  contains(value: number): boolean {
    return this.fromInclusive <= value && value < this.toExclusive;
  }

  equals(range: Range): boolean {
    return this.fromInclusive === range.fromInclusive
      && this.toExclusive === range.toExclusive;
  }
}

export class AgeRange extends Range {
  public static readonly MAX_AGE = Number.MAX_VALUE;

  /**
   * @return age range including any possible value.
   */
  public static ofAnyAge() {
    return new AgeRange(0, this.MAX_AGE);
  }

  public override toString() {
    const to = this.toExclusive < AgeRange.MAX_AGE ? '-' + this.toExclusive : '+';
    return this.fromInclusive + to;
  }

  public toInclusiveString() {
    const to = this.toExclusive < AgeRange.MAX_AGE
      ? '-' + (this.toExclusive - 1)
      : '+';
    return this.fromInclusive + to;
  }

}

export class IncomeRange extends Range {
  public static readonly MAX_INCOME = 2147483649


  public static ofAnyIncome() {
    return new IncomeRange(0, this.MAX_INCOME);
  }

  public toInclusiveString() {
    const to = this.toExclusive < IncomeRange.MAX_INCOME
      ? '-' + (this.toExclusive - 1)
      : '+';
    return this.fromInclusive + to;
  }
}

export class RentRange extends Range {
  public static readonly MAX_RENT = 2147483649

  public toInclusiveString() {
    if (this.fromInclusive === 0) {
      return '<' + this.toExclusive;
    } else if (this.toExclusive >= RentRange.MAX_RENT) {
      return '>' + (this.fromInclusive);
    } else {
      return this.fromInclusive + '-' + (this.toExclusive - 1);
    }
  }
}

export class YearRange extends Range {

  public get singleYear() {
    if (this.fromInclusive === this.toExclusive - 1) {
      return this.fromInclusive;
    }
    throw new Error('Can\'t get single year from range' + this.fromInclusive + ' - ' + this.toExclusive);
  }

  public static ofSingleYear(year: number): YearRange {
    return new YearRange(year, year + 1);
  }

}
