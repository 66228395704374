import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'enumToArray'})
export class EnumToArrayPipe implements PipeTransform {
  transform(value: any) : any[]{
    return Object.keys(value)
        // @ts-ignore
        // skip not-enum values like function or additional fields
        .filter(e => typeof  value[e] != 'object')
  }
}
