import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {inputAnimations} from "../../../shared/util/inputAnimations";
import {FormBuilder, Validators} from "@angular/forms";
import {passwordValidator} from "../../sign-up/passwordValidation";
import {AuthenticationService} from "../../authentication.service";
import {catchError} from "rxjs";
import {JsonResponse} from "../../../shared/api/backend-config";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss',
  '../../user.shared.scss',
  '../../../app.component.scss',
  '../forgot-password-page.component.scss'],
  animations: inputAnimations
})
export class ResetPasswordComponent implements OnInit {

  public passwordShakeState: string = 'inactive';
  public formSubmitted = false;
  public hidePassword = true;

  public resetPasswordForm = this.fb.group({
    password: ['', [Validators.required, passwordValidator]],
  })

  private token!: string

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private authService: AuthenticationService,
              private toast: ToastrService) { }

  ngOnInit(): void {
    // Parse token from URL that user got from email to then send it with password update request
    this.route.queryParams.subscribe((params: Params )=> {
      this.token = params['token']
    })
  }

  public onSubmit() {
    this.formSubmitted = true;

    this.authService.resetPassword(this.resetPasswordForm.value.password!, this.token)
      .pipe(catchError(err => {
        this.toast.error('Can\'t send confirmation letter. ' +
          'Please try again later. We\'re already working on the problem ',
          'Error')
        throw err
      }))
      .subscribe((data: JsonResponse<any>) => {
        this.toast.success('Password successfully updated')
        this.redirectAfterPasswordReset()
      })
  }

  public isInputStatusInvalid(inputName: string) {
    return this.resetPasswordForm.get(inputName)?.invalid && (this.resetPasswordForm.get(inputName)?.touched || this.formSubmitted || this.resetPasswordForm.get(inputName)?.dirty);
  }

  public goToSigninMenu(): void {
    this.router.navigate(['/sign-in'])
  }

  public goToSignupMenu(): void {
    this.router.navigate(['/sign-up'])
  }

  private redirectAfterPasswordReset(): void {
    if (this.authService.getUserEmail()) {
      this.router.navigate(['user/account-settings'])
    } else {
      this.router.navigate(['sign-in'])
    }
  }

  public doesPasswordMatch(): boolean {
    return this.resetPasswordForm.get('passwordConfirm')?.touched ? this.resetPasswordForm.get('password')?.value === this.resetPasswordForm.get('passwordConfirm')?.value : true
  }}
