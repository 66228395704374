<svg *ngIf="!isFeatureInaccessibleType else inaccessible"
     (click)="clickable ? handleTooltipClick($event) : ''"
     style="margin-left: 3px"
     [matTooltip]="tooltipText"
     [matTooltipPosition]="tooltipPosition"
     matTooltipClass="tooltip"
     [routerLink]="tooltipRouterLink" width="20"
     height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle [ngStyle]="{'color': fillColor, 'fill-opacity': opacity}" cx="10" cy="10" r="10" fill="currentColor" fill-opacity="0.35"
  />
  <path [ngStyle]="{'color': strokeColor}"
    d="M8.83398 6.52033C8.83398 5.82508 9.35814 5.33331 10.0007 5.33331C10.6601 5.33331 11.1673 5.85899 11.1673 6.52033C11.1673 7.19862 10.6601 7.74126 10.0007 7.74126C9.35814 7.74126 8.83398 7.23254 8.83398 6.52033ZM9.05379 14.6666V9.41665H10.9475V14.6666H9.05379Z"
    fill="currentColor"/>
</svg>

<ng-template #inaccessible>
  <div (mouseover)="hovered = true;"
       (mouseout)="hovered = false;"
       style="display: inline-block; justify-items: center; margin-left: 3px; width: 25px; height: 25px; cursor: pointer">
    <svg *ngIf="!hovered" width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.4462 15.476C1.04727 15.476 0.706467 15.3402 0.4238 15.0685C0.141267 14.7967 0 14.469 0 14.0854V6.86659C0 6.483 0.141267 6.15531 0.4238 5.88351C0.706467 5.61185 1.04727 5.47601 1.4462 5.47601H2.4V3.93755C2.4 2.97704 2.75027 2.15999 3.4508 1.4864C4.15133 0.812808 5.00107 0.476013 6 0.476013C6.99893 0.476013 7.84867 0.812808 8.5492 1.4864C9.24973 2.15999 9.6 2.97704 9.6 3.93755V5.47601H10.5538C10.9527 5.47601 11.2935 5.61185 11.5762 5.88351C11.8587 6.15531 12 6.483 12 6.86659V14.0854C12 14.469 11.8587 14.7967 11.5762 15.0685C11.2935 15.3402 10.9527 15.476 10.5538 15.476H1.4462ZM1.4462 14.3222H10.5538C10.6257 14.3222 10.6847 14.3 10.7308 14.2556C10.7769 14.2113 10.8 14.1545 10.8 14.0854V6.86659C10.8 6.79749 10.7769 6.74076 10.7308 6.6964C10.6847 6.65204 10.6257 6.62986 10.5538 6.62986H1.4462C1.37433 6.62986 1.31533 6.65204 1.2692 6.6964C1.22307 6.74076 1.2 6.79749 1.2 6.86659V14.0854C1.2 14.1545 1.22307 14.2113 1.2692 14.2556C1.31533 14.3 1.37433 14.3222 1.4462 14.3222ZM6 11.8222C6.38867 11.8222 6.7192 11.6913 6.9916 11.4295C7.26387 11.1676 7.4 10.8497 7.4 10.476C7.4 10.1023 7.26387 9.78448 6.9916 9.52255C6.7192 9.26076 6.38867 9.12986 6 9.12986C5.61133 9.12986 5.2808 9.26076 5.0084 9.52255C4.73613 9.78448 4.6 10.1023 4.6 10.476C4.6 10.8497 4.73613 11.1676 5.0084 11.4295C5.2808 11.6913 5.61133 11.8222 6 11.8222ZM3.6 5.47601H8.4V3.93755C8.4 3.29653 8.16667 2.75165 7.7 2.30294C7.23333 1.85422 6.66667 1.62986 6 1.62986C5.33333 1.62986 4.76667 1.85422 4.3 2.30294C3.83333 2.75165 3.6 3.29653 3.6 3.93755V5.47601Z" fill="#A3A3A3"/>
    </svg>

    <svg *ngIf="hovered" width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.4462 5.47607H8.4V3.93761C8.4 3.29659 8.16667 2.75172 7.7 2.303C7.23333 1.85428 6.66667 1.62992 6 1.62992C5.33333 1.62992 4.76667 1.85428 4.3 2.303C3.83333 2.75172 3.6 3.29659 3.6 3.93761H2.4C2.4 2.9771 2.75027 2.16005 3.4508 1.48646C4.15133 0.812869 5.00107 0.476074 6 0.476074C6.99893 0.476074 7.84867 0.812869 8.5492 1.48646C9.24973 2.16005 9.6 2.9771 9.6 3.93761V5.47607H10.5538C10.9527 5.47607 11.2935 5.61191 11.5762 5.88357C11.8587 6.15537 12 6.48306 12 6.86665V14.0855C12 14.4691 11.8587 14.7968 11.5762 15.0686C11.2935 15.3402 10.9527 15.4761 10.5538 15.4761H1.4462C1.04727 15.4761 0.706467 15.3402 0.4238 15.0686C0.141267 14.7968 0 14.4691 0 14.0855V6.86665C0 6.48306 0.141267 6.15537 0.4238 5.88357C0.706467 5.61191 1.04727 5.47607 1.4462 5.47607ZM1.4462 14.3222H10.5538C10.6257 14.3222 10.6847 14.3 10.7308 14.2557C10.7769 14.2113 10.8 14.1546 10.8 14.0855V6.86665C10.8 6.79755 10.7769 6.74082 10.7308 6.69646C10.6847 6.6521 10.6257 6.62992 10.5538 6.62992H1.4462C1.37433 6.62992 1.31533 6.6521 1.2692 6.69646C1.22307 6.74082 1.2 6.79755 1.2 6.86665V14.0855C1.2 14.1546 1.22307 14.2113 1.2692 14.2557C1.31533 14.3 1.37433 14.3222 1.4462 14.3222ZM6 11.8222C6.38867 11.8222 6.7192 11.6913 6.9916 11.4295C7.26387 11.1676 7.4 10.8498 7.4 10.4761C7.4 10.1024 7.26387 9.78454 6.9916 9.52261C6.7192 9.26082 6.38867 9.12992 6 9.12992C5.61133 9.12992 5.2808 9.26082 5.0084 9.52261C4.73613 9.78454 4.6 10.1024 4.6 10.4761C4.6 10.8498 4.73613 11.1676 5.0084 11.4295C5.2808 11.6913 5.61133 11.8222 6 11.8222Z" fill="#02A4FF"/>
    </svg>
  </div>
</ng-template>
