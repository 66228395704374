import { Injectable } from '@angular/core';
import { FeatureStatus, NaturalId } from '../population-menu/population.service';
import { convertArea } from '@turf/helpers';
import { Plotly } from 'angular-plotly.js/lib/plotly.interface';
import { getFRCLabels } from '../../../../../shared/util/FrcDistribution';
import { MapHttpService } from '../../../../mapbox/services/map-http.service';
import { JsonResponse } from '../../../../../shared/api/backend-config';
import area from '@turf/area';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { of } from 'rxjs';
import {getPieChartLayout} from "../shared/chart-utility";

export interface RoadLengthBackend {
  // length multiplied by lanes num
  roadLengthLanesWeighted: RoadLength,
  // summary length without considering lanes
  roadLength: RoadLength;
}

export interface RoadLength {
  motorway?: number;
  motorway_link?: number;
  primary?: number;
  primary_link?: number;
  trunk?: number;
  trunk_link?: number;
  residential?: number;
  secondary?: number;
  secondary_link?: number;
  service?: number;
  tertiary?: number;
  tertiary_link?: number;
}

export interface RoadDensityFeatureStatus extends FeatureStatus {
  containsRoadDensity: boolean,
}

@Injectable({
  providedIn: 'root'
})
export class RoadDensityService {

  public roadDensityChart: {
    data: Plotly.Data,
    layout: Plotly.Layout
  } = {
    data: [],
    layout: {}
  }

  public readonly featureStatus: RoadDensityFeatureStatus = {
    featureId: undefined,
    isSelected: false,
    containsRoadDensity: false
  }

  constructor(private http: MapHttpService) { }

  public fillChartsData(feature: MapboxGeoJSONFeature): void {

    const areaMi2: number = convertArea(area(feature), 'meters', 'miles')
    const featureId: NaturalId = feature.properties!.external_id

    this.http.getRoadDensityData(featureId).subscribe((data: JsonResponse<RoadLengthBackend>) => {

      if (data.object.roadLengthLanesWeighted) {
        this.featureStatus.naturalId = featureId
        this.featureStatus.isSelected = true
      }

      const maxValue = Math.max(...Object.values(data.object.roadLengthLanesWeighted))

      if (!maxValue || maxValue === -Infinity) {
        this.featureStatus.naturalId = featureId
        this.featureStatus.containsRoadDensity = false
      } else {
        this.featureStatus.naturalId = featureId
        this.featureStatus.containsRoadDensity = true
      }

      const groupedByFRC = RoadDensityService.getRoadsGroupedByFRC(data.object.roadLengthLanesWeighted)


      const values = Object.values(groupedByFRC).map(el => this.getRoadDensityPerMi2(el, areaMi2).toFixed(2))
      const labels = Object.keys(groupedByFRC).map(el => el.charAt(0).toUpperCase() + el.slice(1))

      this.roadDensityChart.data = [{
        values: values,
        labels: labels,
        type: 'pie',
        textposition: 'inside',
        hoverinfo: 'label+percent+value',
        hovertemplate: '&nbsp;&nbsp;%{label}: %{value} mi per mi² (%{percent})&nbsp;&nbsp;<extra></extra>',
      }]

      this.roadDensityChart.layout = getPieChartLayout(undefined, 'Road type', 'mi per mi²')
    }, (error) => {
      this.featureStatus.isSelected = true
      this.featureStatus.containsRoadDensity = false
      console.error('ERROR FETCHING ROAD DENSITY', error)
      return of(null);
    })
  }

  public static getRoadsGroupedByFRC(roadLengthData: RoadLength): {[key: string]: number} {
    const roadLengthByFrc: { [key: string]: number } = {};

    for (let [frc, length] of Object.entries(roadLengthData)) {
      const frcKey = `FRC ${getFRCLabels(frc)}`;

      if (roadLengthByFrc[frcKey]) {
        roadLengthByFrc[frcKey] += length;
      } else {
        roadLengthByFrc[frcKey] = length;
      }
    }

    return roadLengthByFrc;
  }

  private getRoadDensityPerMi2(roadLength: number, featureAreaMi2: number): number {
    const metersInMile = 1609.344
    const roadLengthMi = roadLength / metersInMile
    const roadDensityPerMi2 = roadLengthMi / featureAreaMi2

    return roadDensityPerMi2;
  }
}
