import { groupByLambda, onlyUnique } from '../util/arrays';
import { AgeRange, YearRange } from './range';

export enum Sex {
    MALE = 'M',
    FEMALE = 'F',
    ANY = 'ANY',
}

/**
 * Attributes for samples of populations.
 * Shows characteristics but not the population itself.
 */
export class PopulationSampleAttributes {
    /**
     * Males/Females or Both. Unknown should be considered as both.
     */
    readonly sex: Sex;
    readonly ageRange: AgeRange;

    /**
     * Single year represents as range with single year.
     */
    readonly yearRange: YearRange;

    constructor(sex: Sex, ageRange: AgeRange, yearRange: YearRange) {
        this.sex = sex;
        this.ageRange = ageRange;
        this.yearRange = yearRange;
    }

    public equals(other: PopulationSampleAttributes): boolean {
        return JSON.stringify(this) === JSON.stringify(other);
    }

    public withAgeRange(ageRange: AgeRange): PopulationSampleAttributes {
        return new PopulationSampleAttributes(
            this.sex,
            ageRange,
            this.yearRange
        );
    }
}

/**
 * Sample of population satisfying given attributes.
 */
export class PopulationSample {
    public readonly attributes: PopulationSampleAttributes;

    public readonly population: number;

    public withAgeRange(ageRange: AgeRange): PopulationSample {
        return new PopulationSample(
            this.attributes.withAgeRange(ageRange),
            this.population
        );
    }

    get yearRange(): YearRange {
        return this.attributes.yearRange;
    }

    get sex(): Sex {
        return this.attributes.sex;
    }

    get ageRange(): AgeRange {
        return this.attributes.ageRange;
    }

    public constructor(
        attributes: PopulationSampleAttributes,
        population: number
    ) {
        this.attributes = attributes;
        this.population = population;
    }

    public hasSameAttributes(other: PopulationSample): boolean {
        return this.attributes.equals(other.attributes);
    }

    /**
     * Sum population for samples having same attributes.
     * ex, when aggregating by age, different age ranges are forces set to single
     * to sum up.
     * If samples with different attributes are passed, exception is thrown.
     */
    public summed(other: PopulationSample) {
        if (this.hasSameAttributes(other)) {
            return new PopulationSample(
                this.attributes,
                this.population + other.population
            );
        } else {
            throw new Error(
                "Can't unite samples with different attributes: " +
                    JSON.stringify(this) +
                    ' ' +
                    JSON.stringify(other)
            );
        }
    }
}

/**
 * Populations with attributes.
 * Wrapper for an array of {@link PopulationSample} for convenient processing.
 * This class uses
 * <a href="https://www.oreilly.com/library/view/java-8-pocket/9781491901083/apa.html">Fluent API</a>
 * <a href="https://en.wikipedia.org/wiki/Fluent_interface">Fluent API 2</a>.
 */
export class PopulationSamples {
    private readonly samples: PopulationSample[];

    public constructor(samples: PopulationSample[]) {
        this.samples = samples;
    }

    public filter(
        predicate: (sample: PopulationSample) => boolean
    ): PopulationSamples {
        return new PopulationSamples(this.samples.filter(predicate));
    }

    public filterByYearRange(yearRange: YearRange) {
        return this.filter((pop) =>
            yearRange.containsWholeRange(pop.yearRange)
        );
    }

    /**
     * @return samples with exactly same sex as given.
     */
    public filterSex(sexToRemain: Sex): PopulationSamples {
        return new PopulationSamples(
            this.samples.filter((s) => s.sex === sexToRemain)
        );
    }

    public sortedByYear(): PopulationSamples {
        return new PopulationSamples(
            this.samples.sort(
                (a, b) => a.yearRange.fromInclusive - b.yearRange.fromInclusive
            )
        );
    }

    public sortedByAge(): PopulationSamples {
        return new PopulationSamples(
            this.samples.sort(
                (a, b) => a.ageRange.fromInclusive - b.ageRange.fromInclusive
            )
        );
    }

    public populations(): number[] {
        return this.samples.map((s) => Math.round(s.population));
    }

    /**
     * Total population of all samples.
     */
    public sum() {
        return this.samples.map((s) => s.population).reduce((a, b) => a + b, 0);
    }

    public byYears(): PopulationSamplesByYears {
        const map = groupByLambda(this.samples, (s) => s.yearRange.singleYear);
        return new PopulationSamplesByYears(map);
    }

    /**
     * @return sample where population is summed up inside each of given ranges.
     * NB! If males and females (or any property) are there for same ranges,
     * they are summed up.
     * If one wants to have only one sex/age/other properties,
     * use filter first like {@link PopulationSamples#filterSex}.
     *
     * @param ranges range to sum up population inside.
     * Caller is responsible for ranges to not be overlapped.
     *
     * This method is basically scum.
     * It works for our single case.
     * If one needs more of it, make sure it works properly.
     */
  public sumInsideAgeRanges(ranges: AgeRange[]): PopulationSamples {
    const res = [];

    for (let ageRange of ranges) {
      const summedSample = PopulationSamples.sumSamples(
        this.samples
          .filter((s) => ageRange.containsWholeRange(s.ageRange))
          .map((s) => s.withAgeRange(ageRange))
      );

      if (summedSample !== undefined) {
        res.push(summedSample);
      }
    }

    return new PopulationSamples(res);
  }

    /**
     * Sum population for samples having same attributes.
     * ex, when aggregating by age, different age ranges are forces set to single
     * to sum up.
     * If samples with different attributes are passed, exception is thrown.
     */
    private static sumSamples(
        samples: PopulationSample[]
    ): PopulationSample | undefined {
        if (samples.length === 0) {
            return undefined;
        }
        return samples.reduce((s, o) => s.summed(o));
    }

    public uniqueAgeRangesStrings(): string[] {
        return onlyUnique(
            this.samples
                .map((s) => s.ageRange)
                .sort((a, b) => a.fromInclusive - b.fromInclusive)
                .map((s) => s.toInclusiveString())
        );
    }

  public getSamples(): PopulationSample[] {
    return this.samples
  }

  public getGenerationGroups(sex: Sex, ageRanges: AgeRange[]): PopulationSamples {
    const filteredSex = this.filterSex(sex)
    return filteredSex.sumInsideAgeRanges(ageRanges);
  }
}

export class PopulationSamplesByYears {
    private readonly populationByYears: Map<number, PopulationSample[]>;

    constructor(populationByYears: Map<number, PopulationSample[]>) {
        this.populationByYears = populationByYears;
    }

    public asArray(): Array<[number, PopulationSamples]> {
        const array: Array<[number, PopulationSamples]> = [];

        for (let [key, value] of this.populationByYears.entries()) {
            array.push([key, new PopulationSamples(value)]);
        }
        return array.sort((a, b) => a[0] - b[0]);
    }
}

export class Generation {
  constructor(
    public name: string,
    public ageRange: AgeRange,
    public WAWA: number,
    public population?: number,
    public male?: number,
    public female?: number,
    public targetAudience?: number
  ) {}
}

export class SexGroup {
  constructor(
    public sex: Sex,
    public WAWA: number,
    public targetAudience?: number
  ) {}
}
