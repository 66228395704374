<div class="subscriptions-container">
  <div class="toggle-wrapper">
    Monthly
    <mat-slide-toggle [(ngModel)]="!isPeriodMonthly" (change)="isPeriodMonthly = !isPeriodMonthly"></mat-slide-toggle>
    Yearly
  </div>

  <div class="info-container">
    <div class="plans-container">
      <app-plan-with-description
        *ngFor="let plan of plans; let i = index"
        [planInfo]="plan"
        #planElement
        [isPeriodMonthly]="isPeriodMonthly"
        [isThisSubscriptionActive]="plan.requestKey == userSubscription"
        [isSubscriptionUrlSelected] = "i === urlSelectedPlanIndex"
        (toggleOuterButton)="onToggleOuterButton(i)"
      ></app-plan-with-description>
    </div>
  </div>
</div>
