import {
  targetAudienceIndustries,
  trendLayers,
  writtenInStateFeatures
} from "../../../../shared/types/feature-data-type";


export class ColorScale {
  constructor(private colorRamp: string[],
              private brackets?: { min?: number, max?: number}[],
              private isTrend?: boolean,
              private colorsToShow?: number) {
  }

  /**
   * Maps values to [[threshold]['color']] format compatible with mapbox.
   */
  public toThresholdColorArray(values: number[]): any[][] {
    if (this.isTrend && this.colorsToShow) {
      return this.getTrendsColorArray(values, this.colorsToShow)
    } else if (this.brackets) {
      // Handle the case where we've defined a bracket-based color scale
      return this.getBracketsColorArray(this.brackets)
    } else {
      return this.getCommonColorArray(values)
    }
  }

  private getCommonColorArray(values: number[]): any[][] {
    const sortedData = values.sort((a, b) => a - b)
      .map(el => Number(el));
    const Q1 = ColorScale.quantile(sortedData, 0.25);
    const Q3 = ColorScale.quantile(sortedData, 0.75);
    const IQR = Q3 - Q1;
    const lowerBound = Q1 - 1.5 * IQR;
    const upperBound = Q3 + 1.5 * IQR;
    const minNumber = Math.max(lowerBound, Math.min(...values));
    const maxNumber = Math.min(upperBound, Math.max(...values));

    return this.countColorMap(minNumber, maxNumber);
  }

  private getBracketsColorArray(brackets: { min?: number, max?: number }[]): any[][] {
    return brackets.map((bracket, index) => [
      bracket.min !== undefined && bracket.max !== undefined
        ? [bracket.max, this.colorRamp[index]]
        : bracket.min! > 1000
          ? [`>${bracket.min! / 1000}K`, this.colorRamp[index]]
          : [`>${bracket.min!}`, this.colorRamp[index]]
    ]).flat();
  }

  private getTrendsColorArray(values: number[], colorsToShow: number): any[][] {
    const negativeValues = Array.from(new Set(values.filter(value => value < 0).sort((a, b) => a - b)));
    const positiveValues = Array.from(new Set(values.filter(value => value > 0).sort((a, b) => a - b)));

    const totalValues = negativeValues.length + positiveValues.length;
    const negativeRatio = negativeValues.length / totalValues;
    const positiveRatio = positiveValues.length / totalValues;

    const totalColors = colorsToShow
    let negativeSteps = Math.round(negativeRatio * (totalColors - 1));
    let positiveSteps = totalColors - 1 - negativeSteps;

    const getTrendsThresholds = (sortedValues: number[], steps: number) => {
      const len = sortedValues.length;
      const thresholds = [];
      for (let i = 1; i <= steps; i++) {
        const index = Math.round((len / (steps + 1)) * i);
        thresholds.push(sortedValues[index - 1]);
      }
      return thresholds;
    }

    const negativeEdges = Array.from(new Set(getTrendsThresholds(negativeValues, negativeSteps)));
    const positiveEdges = Array.from(new Set(getTrendsThresholds(positiveValues, positiveSteps)));

    const {negativeColors, positiveColors} = this.getAdaptedColors(negativeSteps, positiveSteps);

    const colorSteps = [
      ...negativeEdges.map((edge, i) => [edge, negativeColors[i]]),
      [0, this.colorRamp[Math.floor(this.colorRamp.length / 2)]], // Zero color is in middle of colorRamp
      ...positiveEdges.map((edge, i) => [edge, positiveColors[i]])
    ];

    return colorSteps
  }

  // Calculate colors in scale dynamically based on numbers of each type
  private getAdaptedColors(numNegative: number, numPositive: number): { negativeColors: string[], positiveColors: string[] } {
    const totalColors = this.colorRamp.length; // Assuming 15 here
    const zeroIndex = Math.floor(totalColors / 2)// Assuming white for 0 is at the center

    const negativeRamp = this.colorRamp.slice(0, zeroIndex);
    const negativeColors = this.selectColors(negativeRamp, numNegative);

    const positiveRamp = this.colorRamp.slice(zeroIndex + 1);
    const positiveColors = this.selectColors(positiveRamp, numPositive);

    return {negativeColors, positiveColors};
  }


  private selectColors(colorRamp: string[], num: number): string[] {
    const colors: string[] = [];
    const step = num === 1 ? 0 : Math.floor((colorRamp.length - 1) / (num - 1));

    for (let i = 0; i < num; i++) {
      colors.push(colorRamp[i * step]);
    }

    return colors;
  }

  /**
   * Maps values to mapbox's 'step' expression color for given layer.
   * @param values values to build color scale by
   * @param layer layerId to color values on
   */
  public toThresholdColorExpression(values: number[], layer: string): any[] {
    const colors = this.toThresholdColorArray(values).flatMap(
      (thresholdColor) => [thresholdColor[1], thresholdColor[0]]
    );

    // expression format requires color to be the last element of array
    colors.pop();

    if (writtenInStateFeatures.includes(layer) ||
      trendLayers.includes(layer) ||
      targetAudienceIndustries.includes(layer)) {
      return ['step', ['to-number', ['feature-state', layer], 0], ...colors]
    }
    return ['step', ['to-number', ['get', layer], 0], ...colors];
  }

  private countColorMap(minNumber: number, maxNumber: number): any[][] {
    const maxColorIndex = this.colorRamp.length;

    // map to [threshold; color]
    return this.colorRamp.map((d, i) => [
      (i / maxColorIndex) * (maxNumber - minNumber) + minNumber,
      d,
    ]);
  }

  private static quantile(arr: number[], q: number): number {
    const index = q * (arr.length - 1);
    const lowerIndex = Math.floor(index);
    const fraction = index - lowerIndex;
    return (
      arr[lowerIndex] + fraction * (arr[lowerIndex + 1] - arr[lowerIndex])
    );
  }
}

