import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { passwordValidator } from '../../sign-up/passwordValidation';
import { AuthenticationService, ENTERPRISE, UserPersonalData } from '../../authentication.service';
import { EmailConfirmationService } from '../../email-confirmation/email-confirmation.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {
  extractEmailSite,
  getPasswordRecoveryRequestError,
  getPasswordRecoveryRequestSuccess
} from '../../email-confirmation/email-util';
import {catchError, of} from "rxjs";
import {JsonResponse} from "../../../shared/api/backend-config";
import {AuthDataJSON} from "../../sign-in/sign-in.component";

@Component({
  selector: 'app-account-settings-form',
  templateUrl: './account-settings-form.component.html',
  styleUrls: ['./account-settings-form.component.scss',
    '../../user.shared.scss',
    '../../../app.component.scss']
})
export class AccountSettingsFormComponent implements OnInit {
  public formSubmitted = false;
  public isEnterpriseAccount: boolean = false;

  public isEmailConfirmed: boolean = true;

  public settingsForm = this.fb.group({
    email: ['', [Validators.email]],
    firstName: [''],
    lastName: [''],
    password: ['', [Validators.required, passwordValidator]],
    phone: [''],
    city: [''],
    stateCounty: [''],
    zipCode: [''],
    country: [''],
    companyName: [''],
    position: ['']
  })


  constructor(private fb: FormBuilder,
              private authService: AuthenticationService,
              private confirmEmailService: EmailConfirmationService,
              private router: Router,
              private toast: ToastrService) {
    this.authService.userPersonalData.subscribe(this.onUserInfoUpdated.bind(this));
  }

  ngOnInit(): void {
    this.authService.refreshUserInfo();
    this.notifyEmailJustConfirmed();
  }

  private notifyEmailJustConfirmed() {
    const emailConfirmedParam = 'email-confirmed';
    const emailJustConfirmed = new URL(window.location.href)
        .searchParams.get(emailConfirmedParam);
    if(emailJustConfirmed == 'true') {
      // variable is already updated, just notify user
      const toast = this.toast.success('Email successfully confirmed. ' +
          'Time to explore the map!', 'Email confirmed')
      toast.onTap.subscribe(next => {
        this.router.navigate(['/'])
      })
    }
    if(emailJustConfirmed == 'false') {
      const error = new URL(window.location.href)
          .searchParams.get('error') ||
          'Please, try again later';
      this.toast.error(error, 'Email confirmation failed');
    }
  }

  private onUserInfoUpdated(user: UserPersonalData | null): void {
    if (user == null) {
      return;
    }
    this.isEnterpriseAccount = user.category === ENTERPRISE;
    this.isEmailConfirmed = user.emailConfirmed;

    this.settingsForm.patchValue({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      companyName: user.companyName,
      position: user.companyPosition,
      phone: user.phone,
      zipCode: user.zipCode,
      city: user.city,
      stateCounty: user.stateCounty,
      country: user.country,
    });
  }

  public isInputStatusInvalid(inputName: string) {
    return this.settingsForm.get(inputName)?.invalid && (this.settingsForm.get(inputName)?.touched || this.formSubmitted || this.settingsForm.get(inputName)?.dirty);
  }

  public onUpdateUserInfo(): void {
    const updateData = {
      ...this.authService.userPersonalData.getValue(),

      firstName: this.settingsForm.get('firstName')!.value!,
      lastName: this.settingsForm.get('lastName')!.value!,
      email: this.settingsForm.get('email')!.value!,
      phone: this.settingsForm.get('phone')!.value!,
      zipCode: this.settingsForm.get('zipCode')!.value!,
      city: this.settingsForm.get('city')!.value!,
      stateCounty: this.settingsForm.get('stateCounty')!.value!,
      country: this.settingsForm.get('country')!.value!,
      companyName: this.settingsForm.get('companyName')!.value!,
      companyPosition: this.settingsForm.get('position')!.value!,
    };

    this.authService.updateUserInfo(updateData)
      .subscribe(updatedUser => {
        this.authService.userPersonalData.next(updatedUser.object)
        this.toast.success('Profile successfully updated')
      }, catchError(error => {
        this.toast.error('Something went wrong with updating your profile')
        console.error('Profile update failed', error)
        return of(null)
      }));
  }

  public sendConfirmEmailLetter() {
    this.confirmEmailService.sendConfirmEmailLetter()
        .subscribe({
            next: (response) => {
              const emailSite = extractEmailSite(this.settingsForm.get('email')!.value!);
              const emailSiteText = emailSite != null ? 'Click me to open ' + emailSite : undefined;
              const toast = this.toast.info(
                  emailSiteText,
                  'Please check ' + response.object + ' to confirm your email');
              if(emailSite != null) {
                toast.onTap.subscribe(next => {
                  window.open(emailSite, '_blank');
                });
              };
        }, error: (error) => {
           this.toast.error('Can\'t send confirmation letter. ' +
               'Please try again later. We\'re already working on the problem ',
               'Error');
        }})
    ;
  }

  public requestChangePassword(): void {
      const email = this.authService.getUserEmail()

      this.formSubmitted = true;

      this.authService.requestPasswordRecovery(email)
        .pipe(catchError(err => {
          this.formSubmitted = true;
          const errorMessage: string = err.error.errorMessage
          if (errorMessage) {
            const {text, title, emailSite} = getPasswordRecoveryRequestError(email, errorMessage)

            const toast = this.toast.warning(text, title)
            if (emailSite != null) {
              toast.onTap.subscribe(() => {
                window.open(emailSite, '_blank')
              })
            }
          } else {
            this.toast.error('Can\'t send confirmation letter. ' +
                'Please try again later. We\'re already working on the problem ',
                'Error');
          }
          throw err
        }))
        .subscribe((response: JsonResponse<AuthDataJSON>) => {
          this.formSubmitted = true;

          const { text, title, emailSite } = getPasswordRecoveryRequestSuccess(email)
          const toast = this.toast.success(text, title)
          if (emailSite != null) {
            toast.onTap.subscribe(next => {
              window.open(emailSite, '_blank');
            });
          };
        })
  }

}
