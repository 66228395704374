import { MetaDefinition } from '@angular/platform-browser';

export interface SeoTagsInfo {
    title: string;
    tags: {
        name?: string;
        property?: string;
        content: string;
    }[];
}

export const mapPageTags: SeoTagsInfo = {
    title: 'Map AI | Location analysis tool',
    tags: [
      {
        name: 'description',
        content:
          'Find where to move and do business with a 1-mile resolution at Map AI location intelligence platform. Access demand forecasting, competitive market analysis, and neighborhood analysis, including detailed demographics by zip code vs. 1 mile, real estate analytics, and more.',
      },
      {
        name: 'keywords',
        content:
          'Map-ai, population density by zip, median income by zip code map, median income map, median household income map, retail store location analysis, location analysis, location intelligence, competitive landscape analysis, map ai',
      },
      {
        property: 'og:title',
        content: 'Map AI | Location analysis tool',
      },
      {
        property: 'og:description',
        content:
          'Find where to move and do business with a 1-mile resolution at Map AI location intelligence platform.',
      },
      {
        property: 'og:url',
        content: 'https://interactive-map-ai.com/',
      },
    ]
};

export const reportsPageTags: SeoTagsInfo = {
  title: 'Map AI | Location Analysis Tool & Reporting System',
  tags: [
    {
      name: 'description',
      content:
        'Map AI location intelligence platform and reporting system for a detailed neighborhood analysis, business location analysis, demand forecasting, and more.',
    },
    {
      name: 'keywords',
      content:
        'Map ai, map-ai, mapai, ai demand forecasting, retail demand forecasting, revenue forecasting, sales forecasting tools, demand forecasting software, demographics by zip, top neighborhoods',
    },
    {
      property: 'og:title',
      content: 'Map AI | Location Analysis Tool & Reporting System',
    },
    {
      property: 'og:description',
      content:
        'Map AI location intelligence platform and reporting system for a detailed neighborhood analysis.',
    },
    {
      property: 'og:url',
      content: 'https://interactive-map-ai.com/reports/order',
    },
  ]
}

export const dataSourcesPageTags: SeoTagsInfo = {
  title: 'Map AI | Location intelligence for business & life',
  tags: [
    {
      name: 'description',
      content:
        'Map AI: the Data You Can Trust. Map AI’s location intelligence tool gathers data from 100+ sources to provide our clients with high-resolution and up-to-date insights for life and business decision-making.',
    },
    {
      name: 'keywords',
      content:
        'Map ai, map-ai, mapai, demand forecast accuracy, location analysis tools, where do I live, where should I live, area median income, location intelligence platform',
    },
    {
      property: 'og:title',
      content: 'Map AI | Location intelligence for business & life',
    },
    {
      property: 'og:description',
      content:
        'Map AI: the Data You Can Trust. Map AI’s location intelligence tool provides high-resolution and up-to-date insights.',
    },
    {
      property: 'og:url',
      content: 'https://interactive-map-ai.com/info/data-sources',
    },
  ]
}

export const settingsPageTags = {
  title: 'Map AI | Location analysis',
  tags: [
    {
      name: 'description',
      content:
        'Accessible location analysis for life and business: 50+ parameters at 1-mile resolution, starting from $4.95.',
    },
    {
      name: 'keywords',
      content:
        'Map ai, map-ai, mapai, best location intelligence software, coffee shop location analysis, grocery demand forecasting, retail store location analysis, laundromat location analysis, median household income by zip code map, map of median income',
    },
    {
      property: 'og:title',
      content: 'Map AI | Location analysis',
    },
    {
      property: 'og:description',
      content:
        'Accessible location analysis for life and business: 50+ parameters at 1-mile resolution.',
    },
    {
      property: 'og:url',
      content: 'https://interactive-map-ai.com/user/account-settings',
    },
  ] as MetaDefinition[]
}
