import {Injectable} from '@angular/core';
import {FeatureStatus, NaturalId} from '../population-menu/population.service';
import {LayerStoreService} from '../../../../mapbox/services/layer-store.service';
import {MapIndustryService} from '../../../../map-industry.service';
import {MapBoxService} from '../../../../mapbox/mapbox.service';
import {Industries} from '../../../../../shared/util/industries';
import {MapHttpService} from '../../../../mapbox/services/map-http.service';
import {JsonResponse} from '../../../../../shared/api/backend-config';
import {catchError, debounceTime, forkJoin, map, Observable, of, share} from 'rxjs';
import {Plotly} from 'angular-plotly.js/lib/plotly.interface';
import {FeatureFocusService} from '../../../../feature-focus/feature-focus.service';
import {finalize} from 'rxjs/operators';

import {LoadingService} from '../../../../mapbox/services/loading.service';
import {FeatureMetaData} from '../../../../mapbox/visualization/map-coloring.service';
import * as mapboxgl from 'mapbox-gl';
import {MapboxGeoJSONFeature} from 'mapbox-gl';


export interface TargetAudienceByCells {
  naturalId: NaturalId,
  industries: { [industry: string]: number } | null
}

export interface TargetAudienceBackend {
  byCells: TargetAudienceByCells []
}

export interface WAWAGenerations {
  zoomers: number;
  millennials: number;
  generationX: number;
  boomers: number;
}

export interface WAWASex {
  male: number;
  female: number;
}

export interface WAWAIndustry {
  generations: WAWAGenerations;
  sex: WAWASex;
}

export interface WAWAForIndustries {
  [key: string]: WAWAIndustry;
}

export interface TargetAudienceFeatureStatus extends FeatureStatus {
  containsTargetAudienceInfo: boolean,
}


@Injectable({
  providedIn: 'root'
})
export class TargetAudienceService {
  private activeIndustry!: Industries

  private readonly naturalIds: NaturalId[] = []

  private readonly industryColors = {
    [Industries.CONVENIENCE_STORES]: 'rgba(107, 81, 150, 1)',
    [Industries.RETAIL]: 'rgba(152, 192, 118, 1)',
    [Industries.DRUGSTORES]: 'rgba(239, 183, 67, 1)',
    [Industries.BEAUTY]: '#d73027'
  };

  public readonly targetAudienceChart: {
    data: Plotly.Data,
    layout: Plotly.Layout,
  } = {
    data: [],
    layout: {},
  }

  private wasIndustrySelected!: boolean

  public readonly featureStatus: TargetAudienceFeatureStatus = {
    naturalId: undefined,
    isSelected: false,
    containsTargetAudienceInfo: false
  }

  constructor(private layerStore: LayerStoreService,
              private industryService: MapIndustryService,
              private mapboxService: MapBoxService,
              private http: MapHttpService,
              private featureFocusService: FeatureFocusService,
              private loadingService: LoadingService) {
  }

  public fillChartsData(feature: MapboxGeoJSONFeature): void {
    const naturalId = feature.properties!.external_id;
    let key = this.industryService.activeIndustryKey

    forkJoin([this.getTargetAudienceByCell(naturalId, key), this.getTargetAudienceByUSA(key)])
      .subscribe(([dataByCell, dataByUSA]) => {
        if (dataByCell !== null && dataByUSA !== null) {
          this.featureStatus.isSelected = true
          this.featureStatus.naturalId = naturalId

          this.createTargetAudienceChart(dataByCell, dataByUSA)
        }
      })

  }

  private getTargetAudienceByUSA(key: string): Observable<TargetAudienceBackend | null> {
    return this.http.getTargetAudienceData(['usa'], key)
      .pipe(
        map((data: JsonResponse<TargetAudienceBackend>) => {
          return data.object
        }),
        catchError((error) => {
          this.featureStatus.containsTargetAudienceInfo = true
          this.featureStatus.naturalId = 'USA'
          this.featureStatus.isSelected = false
          console.error('ERROR FETCHING TARGET AUDIENCE FOR CHART BY USA', error)
          return of(null)
        }))
  }

  private getTargetAudienceByCell(naturalId: NaturalId, key: string): Observable<TargetAudienceBackend | null> {
    return this.http.getTargetAudienceData([naturalId], key)
      .pipe(
        map((data: JsonResponse<TargetAudienceBackend>) => {
          return data.object
        }),
        catchError((error) => {
          this.featureStatus.containsTargetAudienceInfo = false
          this.featureStatus.naturalId = naturalId
          this.featureStatus.isSelected = true
          console.error('ERROR FETCHING TARGET AUDIENCE FOR CHART BY CELL', error)
          return of(null)
        }))
  }


  private createTargetAudienceChart(dataByCell: TargetAudienceBackend, dataByUSA: TargetAudienceBackend): void {
    let targetAudienceIndexesByCell = Object.values(dataByCell.byCells[0].industries!)
    let targetAudienceIndexesByUSA = Object.values(dataByUSA.byCells[0].industries!)
    let industries: string[] = Object.keys(dataByCell.byCells[0].industries!).map(key => (Industries as any)[key]);
    let barColors: string[] = [];

    industries.forEach(industry => {
      barColors.push((this.industryColors as any)[industry]);
    });


    const maxValue = Math.max(...targetAudienceIndexesByCell, ...targetAudienceIndexesByUSA);
    const bottomValue = Math.min(...targetAudienceIndexesByCell, ...targetAudienceIndexesByUSA) * 0.95;

    this.featureStatus.containsTargetAudienceInfo = true

    const chartData = [
      {
        name: 'Cell',
        x: industries,
        y: targetAudienceIndexesByCell.map(el => Number(el.toFixed(2))),
        type: 'bar',
        width: 0.5,
        marker: {
          color: barColors
        },
      },
      {
        name: 'National',
        x: industries,
        y: targetAudienceIndexesByUSA.map(el => Number(el.toFixed(2))),
        type: 'bar',
        width: 0.5,
        opacity: 0.5,
        marker: {
          color: barColors
        },
      },
    ];

    const layout = {
      autosize: true,
      margin: {
        r: 40,
        b: 60,
        t: 25,
      },
      legend: {
        x: -0.15,
        y: 1.2,
        orientation: 'h',
      },
      xaxis: {
        title: 'Industries',
        type: 'category',
        tickangle: 0,
        ticktext: industries.map(industry => {
          if (industry === 'Retail') return 'Supermarket'
          return industry.replace(" ", "<br>");
        }),
        tickvals: industries,
      },
      yaxis: {
        title: 'Target audience coefficient',
        range: [bottomValue, maxValue],
      },
      font: {
        family: 'Rising Sun, sans-serif'
      },
    };
    this.targetAudienceChart.data = chartData

    this.targetAudienceChart.layout = layout
  }

  public colorMapByIndustry(layer: string): void {
    const map = this.mapboxService.map;
    this.loadingService.isLoadingManual.next(true);

    this.naturalIds.length = 0;

    const naturalIdToFeatureMap: Map<string, FeatureMetaData> = new Map();

    const currentFocusedFeatures = this.featureFocusService.currentFocusedFeatures().getFeatures();
    currentFocusedFeatures.forEach(feature => {
      const featureMetaData: FeatureMetaData = {
        id: feature.id,
        source: feature.source,
        sourceLayer: feature.sourceLayer
      };

      const state = map.getFeatureState(featureMetaData);

      if (!state.hasOwnProperty(layer) && !state[`${layer}Requested`] && feature.properties!.external_id) {
        this.naturalIds.push(feature.properties!.external_id);
        naturalIdToFeatureMap.set(feature.properties!.external_id, featureMetaData);
      }
    });

    if (!this.naturalIds.length) {
      this.loadingService.isLoadingManual.next(false);
      return;
    }

    this.markAsBeingLoaded(naturalIdToFeatureMap.values(), map, true);
    this.featureFocusService.focusRenderedFeatures(
      map,
      this.layerStore.activeLevel.getValue()
    );

    this.http.getTargetAudienceData(this.naturalIds, layer)
      .pipe(debounceTime(150), share(), finalize(() => {
        this.loadingService.isLoadingManual.next(false);
        this.markAsBeingLoaded(naturalIdToFeatureMap.values(), map, false);
        this.featureFocusService.focusRenderedFeatures(
          map,
          this.layerStore.activeLevel.getValue()
        );
      }))
      .subscribe((data: JsonResponse<TargetAudienceBackend>) => {
          data.object.byCells.forEach((cell: TargetAudienceByCells) => {
            const correspondingFeature = naturalIdToFeatureMap.get(cell.naturalId);

            // County level has a broken feature 13303 which have industries: null
            if (correspondingFeature && cell.industries) {
              const value = isNaN(cell.industries[layer]) || cell.industries[layer] === null || cell.industries[layer] === undefined ? null : cell.industries[layer];

              const newState = {[`${layer}Requested`]: true, [layer]: value};
              map.setFeatureState(correspondingFeature, newState);
            }
          })
        },
        (error) => {
          console.error('ERROR FETCHING TARGET AUDIENCE FOR MAP COLORING', layer, error);
          return of(null);
        }
      )
  }

  private markAsBeingLoaded(cells: IterableIterator<FeatureMetaData>, map: mapboxgl.Map, isBeingLoaded = true) {
    for (let cell of cells) {
      map.setFeatureState(cell, {isBeingLoaded: isBeingLoaded})
    }
  }
}
