import {Pipe, PipeTransform} from '@angular/core';
import {LayersMenuService} from "../../../menu/right-menu/layers-menu/layers-menu.service";
import {Feature} from "@turf/helpers";
import {hwwFeatures} from "../../../../shared/types/feature-data-type";
import {LayerStoreService} from "../../services/layer-store.service";

@Pipe({
  name: 'sortProperties'
})
export class SortPropertiesPipe implements PipeTransform {
  private featureOrder: string[] = [];

  constructor(private layersMenuService: LayersMenuService,
              private layerStore: LayerStoreService) {
    this.initializeFeatureOrder();
  }

  transform(props: Feature['properties']): { [key: string]: any } {
    const sortedProps: { [key: string]: any } = {};

    if (!props) return sortedProps
    // Sort properties based on the featureOrder array
    this.featureOrder.forEach(featureConst => {
      if (props.hasOwnProperty(featureConst)) {
        sortedProps[featureConst] = props[featureConst];
      }
    });

    // Include any remaining properties that were not in the featureOrder
    Object.keys(props).forEach(key => {
      if (!sortedProps.hasOwnProperty(key)) {
        sortedProps[key] = props[key];
      }
    });

    return sortedProps;
  }

  private initializeFeatureOrder() {
    this.layersMenuService.groupsData.forEach(group => {
      this.featureOrder.push(this.layerStore.activeLayer.value)

      group.data.mapColor?.forEach(feature => {
        this.featureOrder.push(feature.featureConst);
        if (hwwFeatures.includes(feature.featureConst)) {
          this.addHwwSuffix(feature.featureConst)
        }
      });
      group.data.charts?.forEach(chart => {
        this.featureOrder.push(chart.featureConst);
      });
      group.data.places?.forEach(place => {
        this.featureOrder.push(place.featureConst);
      });
    });
  }

  private addHwwSuffix(feature: string): void {
    this.featureOrder.push(`${feature}_by_state`, `${feature}_by_country`)
  }

}
