import { Injectable } from '@angular/core';
import { LayerStoreService } from '../../../../mapbox/services/layer-store.service';
import { MapBoxService } from '../../../../mapbox/mapbox.service';
import {
  COMBINED_HWW_BY_COUNTRY,
  COMBINED_HWW_BY_STATE,
  HEALTH_INDEX_BY_COUNTRY,
  HEALTH_INDEX_BY_STATE,
  WEALTH_INDEX_BY_COUNTRY,
  WEALTH_INDEX_BY_STATE,
  WISE_INDEX_BY_COUNTRY,
  WISE_INDEX_BY_STATE,
} from '../../../../../shared/types/feature-data-type';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { FeatureFocusService } from '../../../../feature-focus/feature-focus.service';


@Injectable({
  providedIn: 'root'
})
export class HealthWealthWiseService {

  constructor(private layerStore: LayerStoreService,
              private mapService: MapBoxService,
              private focus: FeatureFocusService) { }

  public colorMapByCombinedHWW(): void {
    const map = this.mapService.map

    const featuresWithoutState = map.queryRenderedFeatures(undefined, {
      layers: [this.layerStore.activeLevel.getValue()]
    }).filter(feature => {
      return !feature.state.hasOwnProperty(COMBINED_HWW_BY_COUNTRY) || !feature.state.hasOwnProperty(COMBINED_HWW_BY_STATE)
    })

    featuresWithoutState.map(feature => {
      const combinedIndexes = this.getCombinedIndex(feature)

      map.setFeatureState(feature, {[COMBINED_HWW_BY_COUNTRY]: combinedIndexes[COMBINED_HWW_BY_COUNTRY], [COMBINED_HWW_BY_STATE]: combinedIndexes[COMBINED_HWW_BY_STATE]} )
    })
    // trigger recolor
    this.focus.focusRenderedFeatures(map, this.layerStore.activeLevel.getValue())
  }

  private getCombinedIndex(feature: MapboxGeoJSONFeature): {[COMBINED_HWW_BY_COUNTRY]: number, [COMBINED_HWW_BY_STATE]: number} {
    const byCountry = [HEALTH_INDEX_BY_COUNTRY, WEALTH_INDEX_BY_COUNTRY, WISE_INDEX_BY_COUNTRY]
    const byState = [HEALTH_INDEX_BY_STATE, WEALTH_INDEX_BY_STATE, WISE_INDEX_BY_STATE]

    const containsByCountry = byCountry.some(key => feature.properties!.hasOwnProperty(key))

    let totalByCountry = 0
    let totalByState = 0

    totalByState = this.sumIndexesInScope(byState, feature) / byState.length

    if (containsByCountry) {
      totalByCountry = this.sumIndexesInScope(byCountry, feature) / byCountry.length
    }

    return { [COMBINED_HWW_BY_COUNTRY]: totalByCountry,  [COMBINED_HWW_BY_STATE]: totalByState}

  }

  private sumIndexesInScope(indexes: string[], feature: MapboxGeoJSONFeature): number {
    return indexes.reduce((total, key) => {
      const value = feature.properties![key]

      return (total + (value ?? 0))
    }, 0)
  }
}
