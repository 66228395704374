<div class="register-container container" [attr.data-cy]="'register-individual-window'">
  <div class="main-window sign-up-form">

    <div
      (click)="closeSignInMenu()"
      class="menu-close-btn"
    >
      <img src="../../../../assets/icons/rightMenuCloseBtn.svg" alt="Close map AI sign-in">
    </div>


    <div class="menu-close-btn-mobile" (click)="closeSignInMenu()">
      <img src="../../../../assets/icons/Cancel.svg" alt="Cancel map AI sign-in">
    </div>

    <h2>Create Map AI account</h2>
    <div class="google-sign-in-wrapper registration-form">
      <div id="google-sign-in-register" class="google-sign-in"></div>
      <label>or</label>
    </div>

    <form class="sign-in-form" [formGroup]="signInForm" (ngSubmit)="onSubmit()" #myForm="ngForm">
      <div class="input-wrapper">
        <div class="input-container">
          <label for="firstName">First name</label>
          <input formControlName="firstName"
                 [@shake]="emailShakeState"
                 (@shake.done)="emailShakeState='inactive'"
                 [class.invalid-input]="isInputStatusInvalid('firstName')"
                 (ngModelChange)="formSubmitted = false"
                 id="firstName">
        </div>
        <div class="input-container">
          <label for="lastName">Last name</label>
          <input formControlName="lastName"
                 [@shake]="emailShakeState"
                 (@shake.done)="emailShakeState='inactive'"
                 [class.invalid-input]="isInputStatusInvalid('lastName')"
                 (ngModelChange)="formSubmitted = false"
                 id="lastName">
        </div>
      </div>



      <div class="input-wrapper">
        <div class="input-container">
          <label  for="email">Email</label>
          <input formControlName="email"
                 [@shake]="emailShakeState"
                 (@shake.done)="emailShakeState='inactive'"
                 [class.invalid-input]="isInputStatusInvalid('email')"
                 (ngModelChange)="formSubmitted = false; emailOccupiedErrorStatus = false"
                 id="email">

        </div>
        <div class="error-messages" *ngIf="isInputStatusInvalid('email')">
          <label>Invalid email format</label>
        </div>

        <div class="error-messages" *ngIf="emailOccupiedErrorStatus">
          <label>Account with this email already exists</label>
        </div>
      </div>

      <div class="input-wrapper" *ngIf="this.isEnterpriseRoute()">
        <div class="input-container password-container">
          <label for="companyName">Company</label>

          <input formControlName="companyName"
                 id="companyName">
        </div>
      </div>


      <div class="input-wrapper">
        <div class="input-container password-container">
          <label for="password">Password</label>
          <input formControlName="password" [type]="hidePassword ? 'password' : 'text'"
                 [@shake]="passwordShakeState"
                 (@shake.done)="passwordShakeState='inactive'"
                 [class.invalid-input]="isInputStatusInvalid('password')"
                 (ngModelChange)="formSubmitted = false"
                 id="password">
          <button type="button" class="show-password-btn" (click)="hidePassword = !hidePassword">
            <img *ngIf="hidePassword; else showIcon" src="../../../../assets/icons/EyeClosed.svg" alt="hide-password">
            <ng-template #showIcon>
              <img src="../../../../assets/icons/Eye.svg" alt="Show password for map AI registration form">
            </ng-template>
          </button>
        </div>
        <div class="error-messages" *ngIf="signInForm.get('password')!.touched">
          <label *ngIf="signInForm.get('password')!.errors?.lengthError">Password must be between 8 and 32 characters.</label>
          <label *ngIf="signInForm.get('password')!.errors?.lowercaseError">Password must contain at least one lowercase character.</label>
          <label *ngIf="signInForm.get('password')!.errors?.uppercaseError">Password must contain at least one uppercase character.</label>
          <label *ngIf="signInForm.get('password')!.errors?.digitOrSpecialCharError">Password must contain at least one digit or special character.</label>
          <label *ngIf="signInForm.get('password')!.errors?.illegalCharError">Password contains an invalid character.</label>
        </div>

        <div class="input-container password-container">
          <label for="passwordConfirm">Confirm password</label>

          <input formControlName="passwordConfirm" [type]="hidePassword ? 'password' : 'text'"
                 [@shake]="passwordShakeState"
                 (@shake.done)="passwordShakeState='inactive'"
                 [class.invalid-input]="isInputStatusInvalid('passwordConfirm')"
                 (ngModelChange)="formSubmitted = false"
                 id="passwordConfirm">

          <button type="button" class="show-password-btn" (click)="hidePassword = !hidePassword">
            <img *ngIf="hidePassword; else showIcon" src="../../../../assets/icons/EyeClosed.svg"
                 alt="Hide password for map ai sign up form">
            <ng-template #showIcon>
              <img src="../../../../assets/icons/Eye.svg" alt="Show password for map ai sign up form">
            </ng-template>
          </button>
        </div>
        <div class="error-messages right-input" *ngIf="!doesPasswordMatch()">
          <label>Password must match</label>
        </div>
      </div>

      <span class="password-requirements">Use 8 or more characters with a mix of letters and numbers</span>
    </form>


    <div class="buttons-container">
      <button class="sign-in-button gradient-button"
              (click)="checkInputs(); myForm.ngSubmit.emit()"
              [class.inactive]="signInForm.invalid && signInForm.dirty"
              [attr.data-cy]="'create-account-button'"
      >Create account</button>
    </div>
    <span class="log-in-span">Already have an account? <a (click)="goToSigninMenu()" class="log-in-link" >Log in</a></span>


  </div>

    <app-carousel
      class="carousel"
      [images]="[
      '../../../assets/images/register-carousel/1600/data_you_can_trust.png',
       '../../../assets/images/register-carousel/1600/high_resolution_data.png',
      '../../../assets/images/register-carousel/1600/affordable_prices.png']"
      [imagesAlt]="[
      'Median income home value rent data age sex pyramid for USA interactive map AI',
      'High resolution demographic data USA: median income, population density, gross rent, home value, wealth index, health index',
      'Subscription plans: realtor`s special, enterprise location intelligence, community compass, know your country. Parameters at national, state, county, 1-mile cell high resolution data levels'
      ]"
    ></app-carousel>
</div>
