import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {inputAnimations} from "../../shared/util/inputAnimations";
import {FormBuilder, Validators} from "@angular/forms";
import {catchError, debounceTime} from "rxjs";
import {JsonResponse} from "../../shared/api/backend-config";
import {AuthDataJSON} from "../sign-in/sign-in.component";
import {AuthenticationService} from "../authentication.service";
import {ToastrService} from "ngx-toastr";
import {
  getPasswordRecoveryRequestError,
  getPasswordRecoveryRequestSuccess
} from "../email-confirmation/email-util";

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss',
  './../../app.component.scss',
  '../user.shared.scss'],
  animations: inputAnimations
})
export class ForgotPasswordPageComponent implements OnInit {

  public forgotPasswordForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  })

  public emailShakeState: string = 'inactive';
  public formSubmitted = false;

  constructor(private router: Router,
              private fb: FormBuilder,
              private authService: AuthenticationService,
              private toast: ToastrService) { }

  ngOnInit(): void {
  }

  public goToSigninMenu(): void {
    this.router.navigate(['/sign-in'])
  }

  public goToSignupMenu(): void {
    this.router.navigate(['/sign-up'])
  }

  public isInputStatusInvalid(inputName: string) {
    return this.forgotPasswordForm.get(inputName)?.invalid && (this.forgotPasswordForm.get(inputName)?.touched || this.formSubmitted || this.forgotPasswordForm.get(inputName)?.dirty);
  }

  public onSubmit() {
    const email = this.forgotPasswordForm.value.email!
    this.authService.requestPasswordRecovery(email)
      .pipe(debounceTime(500), catchError((err) => {
        const errorMessage: string = err.error.errorMessage
        if (errorMessage) {
         const {text, title, emailSite} = getPasswordRecoveryRequestError(email, errorMessage)

          const toast = this.toast.warning(text, title)
          if (emailSite != null) {
            toast.onTap.subscribe(() => {
              window.open(emailSite, '_blank')
            })
          }
        } else {
          this.toast.error('Can\'t send confirmation letter. ' +
            'Please try again later. We\'re already working on the problem ',
            'Error');
        }

        throw err
      }))
      .subscribe((response: JsonResponse<AuthDataJSON>) => {
        this.formSubmitted = true;

        const { text, title, emailSite } = getPasswordRecoveryRequestSuccess(email)
        const toast = this.toast.success(text, title)
        if (emailSite != null) {
          toast.onTap.subscribe(next => {
            window.open(emailSite, '_blank');
          });
        };
      })
  }
}
