import { environment } from '../../environments/environment';

export interface GoogleAuth0Response {
    clientId: string,
    // JWT with email & user info
    credential: string,
}

export function renderGoogleSignIn(id: string, callback: (auth: GoogleAuth0Response) => void): void {
    const script = document.createElement('script')
    script.src = "https://accounts.google.com/gsi/client"

    document.head.appendChild(script)

    script.onload = function () {
      // @ts-ignore
      const google = window.google

      google.accounts.id.initialize({
        client_id: environment.googleAuthClientId,
        callback: callback,
        auto_select: false,
        cancel_on_tap_outside: true,
      });

      const googleSignInButton = document.getElementById(id)

      google.accounts.id.renderButton(
        googleSignInButton,
        {size: 'large', width: '195px' }
      );

      google.accounts.id.prompt((notification: any) => {});
    }
}
