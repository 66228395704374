import {Component, Input, OnInit} from '@angular/core';
import {
  EducationService,
  RACE_AMERICAN_INDIAN,
  RACE_ASIAN,
  RACE_BLACK,
  RACE_HISPANIC,
  RACE_NATIVE_HAWAIIAN,
  RACE_TWO_OR_MORE_RACES,
  RACE_WHITE,
  SCHOOL_GRADE_NUMERIC,
  SCHOOL_GRADE_SCALE,
  SchoolsPoiProperties
} from "../../../../menu/right-menu/layers-menu/education/education.service";

@Component({
  selector: 'app-schools-popup-list',
  templateUrl: './schools-popup-list.component.html',
  styleUrls: ['./schools-popup-list.component.scss',
  '../map-popup.component.scss']
})
export class SchoolsPopupListComponent implements OnInit {
  @Input() properties!: SchoolsPoiProperties;
  @Input() isSeeMoreActive!: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  public getGradeColor(grade: string): string {
    return EducationService.getColorForGrade(grade);
  }

  public getRaces() {
    const races = [
      { key: RACE_AMERICAN_INDIAN, acronym: 'AIAN' },
      { key: RACE_ASIAN, acronym: 'ASN' },
      { key: RACE_BLACK, acronym: 'BLK' },
      { key: RACE_HISPANIC, acronym: 'HIS' },
      { key: RACE_NATIVE_HAWAIIAN, acronym: 'NHPI' },
      { key: RACE_WHITE, acronym: 'WHT' },
      { key: RACE_TWO_OR_MORE_RACES, acronym: 'TWO' }
    ];
    return races.filter(race => this.properties[race.key as keyof SchoolsPoiProperties]! > 0);
  }

  public hasRaceData(): boolean {
    return this.getRaces().length > 0;
  }


  protected readonly SCHOOL_GRADE_SCALE = SCHOOL_GRADE_SCALE;
  protected readonly SCHOOL_GRADE_NUMERIC = SCHOOL_GRADE_NUMERIC;
}
