import {Injectable} from '@angular/core';
import {PopulationService} from './population-menu/population.service';
import {RoadDensityService} from './road-density/road-density.service';
import {TargetAudienceService} from './target-audience/target-audience.service';
import {MapboxGeoJSONFeature} from 'mapbox-gl';
import {HealthWealthWiseService} from './health-wealth-wise/health-wealth-wise.service';
import {IncomeDistributionService} from './wealth-menu/income-distribution.service';
import {HomeownershipService} from './homeownership/homeownership.service';
import {EducationService} from './education/education.service';
import {HealthService} from './health/health.service';
import {MapColoringService} from '../../../mapbox/visualization/map-coloring.service';
import {
  availableInBulkFeatures,
  TARGET_AUDIENCE,
  targetAudienceIndustries
} from '../../../../shared/types/feature-data-type';
import {LayerStoreService} from '../../../mapbox/services/layer-store.service';
import {PoisService} from './pois/pois.service';
import {PhysicalEnvironmentService} from './physical-environment/physical-environment.service';
import {
  EDUCATION_GROUP_TITLE,
  HEALTH_GROUP_TITLE,
  HOUSING_GROUP_TITLE,
  HWW_GROUP_TITLE,
  PHYSICAL_ENVIRONMENT_GROUP_TITLE,
  POI_GROUP_TITLE,
  POPULATION_GROUP_TITLE,
  ROAD_DENSITY_GROUP_TITLE,
  TARGET_AUDIENCE_GROUP_TITLE,
  WEALTH_GROUP_TITLE
} from "./layers-menu.service";

@Injectable({
  providedIn: 'root',
})
export class LayersDataService {
  private groupNameToStatusMap = new Map<string, boolean>()

  constructor(
    private ageSexService: PopulationService,
    private roadDensityService: RoadDensityService,
    private targetAudienceService: TargetAudienceService,
    private hwwService: HealthWealthWiseService,
    private incomeDistributionService: IncomeDistributionService,
    private homeownershipService: HomeownershipService,
    private educationService: EducationService,
    private healthService: HealthService,
    private POIsService: PoisService,
    private physicalEnvironmentService: PhysicalEnvironmentService,
    private mapColoring: MapColoringService,
    private layerStore: LayerStoreService,
  ) {
    this.layerStore.activeLayer.subscribe((layer) => {
      this.handleMoveEnd();
    });
  }

  public eventDispatcher(
    eventType: string,
    feature?: MapboxGeoJSONFeature
  ): void {
    switch (eventType) {
      case 'click':
        this.handleClick(feature!);
        break;
      case 'moveend':
        this.handleMoveEnd();
        break;

      default:
        throw new Error(
          'Implement switch for ' +
          eventType +
          ', layers-data.service.ts'
        );
    }
  }

  private handleClick(feature: MapboxGeoJSONFeature): void {

    if (this.groupNameToStatusMap.get(POI_GROUP_TITLE)) {
      this.POIsService.fillChartsData(feature);
    }

    if (this.groupNameToStatusMap.get(POPULATION_GROUP_TITLE)) {
      this.ageSexService.fillChartsData(feature);
    }
    if (this.groupNameToStatusMap.get(TARGET_AUDIENCE_GROUP_TITLE)) {
      this.targetAudienceService.fillChartsData(feature);
    }

    if (this.groupNameToStatusMap.get(WEALTH_GROUP_TITLE)) {
      this.incomeDistributionService.fillChartsData(feature);
    }

    if (this.groupNameToStatusMap.get(HOUSING_GROUP_TITLE)) {
      this.homeownershipService.fillChartsData(feature);
    }

    if (this.groupNameToStatusMap.get(EDUCATION_GROUP_TITLE)) {
      this.educationService.fillChartsData(feature);
    }

    if (this.groupNameToStatusMap.get(HEALTH_GROUP_TITLE)) {
      this.healthService.fillChartsData(feature);
    }

    if (this.groupNameToStatusMap.get(PHYSICAL_ENVIRONMENT_GROUP_TITLE)) {
      this.physicalEnvironmentService.fillChartsData(feature)
    }

    if (this.groupNameToStatusMap.get(ROAD_DENSITY_GROUP_TITLE)) {
      this.roadDensityService.fillChartsData(feature);
    }
  }

  public handleOpenedGroup(group: string, status: boolean): void {
    this.groupNameToStatusMap.set(group, status)
  }

  public getIsGroupOpened(group: string): boolean {
    return this.groupNameToStatusMap.get(group) || false
  }

  private handleMoveEnd(): void {
    const currentLayer = this.layerStore.activeLayer.getValue()
    if (this.groupNameToStatusMap.get(HWW_GROUP_TITLE)) this.hwwService.colorMapByCombinedHWW();

    if (this.groupNameToStatusMap.get(POI_GROUP_TITLE) && this.layerStore.isCurrentLevelCells()) {
      this.POIsService.handlePoisOnMap();
    }

    if (this.layerStore.activeLayer.getValue() === TARGET_AUDIENCE ||
      targetAudienceIndustries.includes(this.layerStore.activeLayer.getValue())) {
      this.targetAudienceService.colorMapByIndustry(currentLayer);
    }

    if (availableInBulkFeatures.includes(currentLayer)) {
      this.mapColoring.colorMapByCells(currentLayer)
    }
  }
}
