import { Injectable } from '@angular/core';
import {
  CRIME_GROUP_TITLE,
  HWW_GROUP_TITLE,
  LayersMenuService,
  TARGET_AUDIENCE_GROUP_TITLE,
} from '../layers-menu.service';
import { BehaviorSubject } from 'rxjs';

export interface FeatureFilter {
  featureConst: string;
  min: number | undefined;
  max: number | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class FiltersMenuService {
  public featuresList: string[] = [];
  public activeFilters: FeatureFilter[] = []

  // Subscribe to rerender map on filter changes
  public updateFiltersSubject: BehaviorSubject<any> = new BehaviorSubject<any>(true)

  constructor(private layersMenuService: LayersMenuService) {}

  public parseFeaturesIntoDropdown(): void {
    this.featuresList = this.layersMenuService.groupsData
      .filter((group) => {
        return group.title !== HWW_GROUP_TITLE && group.title !== TARGET_AUDIENCE_GROUP_TITLE && group.title !== CRIME_GROUP_TITLE;
      })
      .filter((group) => group.data.mapColor)
      .flatMap((group) => {
        return group.data.mapColor?.map((data) => {
          return this.activeFilters.some(el => el.featureConst === data.featureConst) ? undefined : data.featureConst;
        }).filter(el => el !== undefined);
      }) as string[];
  }

  public addFilter(feature: string): void {
    this.activeFilters.push({
      min: undefined,
      max: undefined,
      featureConst: feature
    })

    this.featuresList = this.featuresList.filter(el => el !== feature)
    this.updateFilters()
  }

  public removeFilter(feature: string): void {
    this.activeFilters = this.activeFilters.filter(el => el.featureConst !== feature)

    this.parseFeaturesIntoDropdown()
    this.updateFilters()
  }

  public updateFilters(): void {
    this.updateFiltersSubject.next(!this.updateFiltersSubject.value)
  }

}
