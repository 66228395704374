
import {MostGranularSex,} from '../../map/menu/right-menu/layers-menu/population-menu/population.service';
import {
INCOME_FROM, INCOME_TO,
  MapAiSemanticVariable,
  MapAiSemanticVariableObject,
  parseSemanticVariable,
  WORK,
  WORK_FULLTIME,
  WORK_OTHER,
} from './map-ai-semantic-variable-object';
import {IncomeRange, YearRange} from './range';
import {IncomeSample, IncomeSampleAttributes, IncomeSamples, Work} from "./income";
import {mapSex} from "./population-mapper";
import {
  IncomeDistributionByYear,
  PersonalDistributionJSON
} from "../../map/menu/right-menu/layers-menu/wealth-menu/income-distribution.service";

export function mapIncomeDistribution(
    backendIncome: PersonalDistributionJSON
): IncomeSamples {

    const samples: IncomeSample[] = backendIncome.incomeDistributionByYears.flatMap(
      mapIncomeDistributionByYear
    );

    return new IncomeSamples(samples);
}

export function mapIncomeDistributionByYear(income: IncomeDistributionByYear): IncomeSample[]{
  return mapMostGranularSamples(income.info.distribution, income.year)
}

export function mapMostGranularSamples(samples: MostGranularSex, year: number): IncomeSample[] {
  const res: IncomeSample[] = [];

  for (let key in samples) {
    res.push(mapMostGranularSample(key, samples[key], year));
  }
  return res;
}

export function mapMostGranularSample(key: MapAiSemanticVariable,
                                      population: number,
                                      year: number): IncomeSample {
  const parsedVariable: MapAiSemanticVariableObject = parseSemanticVariable(key);
  const sex = mapSex(parsedVariable);
  const incomeRange = mapIncomeRange(parsedVariable);
  const yearRange = YearRange.ofSingleYear(year);
  const work = mapWork(parsedVariable)

  const sample: IncomeSampleAttributes =
    new IncomeSampleAttributes( sex, incomeRange, yearRange, work);
  return new IncomeSample(sample, population);
}


export function mapWork(obj: MapAiSemanticVariableObject): Work {
  if (!obj[WORK]) {
    return Work.ANY
  }

  if (obj[WORK] === WORK_OTHER) {
    return Work.OTHER
  }

  if (obj[WORK] === WORK_FULLTIME) {
    return Work.FULLTIME
  }

  throw new Error('Can\'t parse work from ' + JSON.stringify(obj));
}

export function mapIncomeRange(obj: MapAiSemanticVariableObject): IncomeRange {
  if (!obj[INCOME_FROM] && !obj[INCOME_TO]) {
    return IncomeRange.ofAnyIncome();
  }
  const from = obj[INCOME_FROM] || 0;
  const to = obj[INCOME_TO] + 1 || IncomeRange.MAX_INCOME;

  return new IncomeRange(from, to);
}
