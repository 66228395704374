import { UserPersonalData } from '../authentication.service';
import * as Sentry from '@sentry/angular-ivy';
import { User } from './user.model';


export function setSentryUser(user: UserPersonalData): void {
    Sentry.setUser({
            // FIXME:setup actual user id
            id: user.email,
            email: user.email,
            username: new User(user).fullNameAndCompany(),
            segment: user.category,
        },
    );
}