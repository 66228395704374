import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-chart-warning',
  templateUrl: './chart-warning.component.html',
  styleUrls: ['./chart-warning.component.scss']
})
export class ChartWarningComponent implements OnInit {

  @Input() chartName!: string
  @Input() isToggleActive: boolean = false
  @Input() selected: boolean = false
  @Input() containsInfo: boolean = false
  @Input() comingSoon: boolean = false
  @Input() customText: string = ''
  @Input() selectedYear!: number | undefined

  constructor() { }

  ngOnInit(): void {
  }

}
