<div class="dialog-container">
  <h2>Subscription activated</h2>

  <div class="text-content">
    <span>Your Map AI subscription is activated!</span>
    <p>You have access to members-only premium content and special features. It will allow you to personalize your experience, customize your settings, and save your preferences.</p>
  </div>

  <button class="got-it-button" (click)="handleClose()">Got it</button>
</div>
