import {Component, Input, OnInit} from '@angular/core';
import {MAPS_GROUP, MenuGroupType} from "../../../../map.component";

export interface UpcomingGroupData {
  title: string,
  warningDisclaimer?: string,
}

@Component({
  selector: 'app-upcoming-group',
  templateUrl: './upcoming-group.component.html',
  styleUrls: ['./upcoming-group.component.scss',
    '../../../../../app.component.scss']
})
export class SpecialHealthCareComponent implements OnInit {
  @Input() activeMenuGroup!: MenuGroupType

  @Input() upcomingGroupData!: UpcomingGroupData

  public isSpecialCareVisible: boolean = false

  constructor() { }

  ngOnInit(): void {
  }

  protected readonly MAPS_GROUP = MAPS_GROUP;
}
