import { Pipe, PipeTransform } from '@angular/core';
import {
    AIR_POLLUTION,
    cdcBulkFeatures,
    chrBulkFeatures,
    GENDER_PAY_GAP,
    HOUSEHOLDS,
    hwwFeatures,
    LIFE_EXPECTANCY,
    MEDIAN_GROSS_RENT,
    MEDIAN_HOUSING_SALE_COST,
    MEDIAN_INCOME,
    PEOPLE_PER_MI2,
    POPULATION,
    ratioLayers,
    SPECIAL_HEALTH_CARE_NEEDS_INDEX,
    targetAudienceIndustries,
    trendLayers,
    UNHEALTHY_BEHAVIOUR_INDEX,
} from '../types/feature-data-type';

@Pipe({
    name: 'numberFormatter',
})
export class NumberFormatterPipe implements PipeTransform {
    constructor() {}

    transform(
        value: number | string,
        digits: number,
        activeLayer: string,
        isPopup?: boolean
    ): any {
        return formatNumbers(value, digits, activeLayer, isPopup);
    }
}


export function formatNumbers(value: number | string, digits: number, activeLayer: string, isPopup?: boolean) {
  let numValue: number;

  if (value === 0) return '0'

  if (!value && activeLayer != GENDER_PAY_GAP) return ''

  if (typeof value === 'string') {

    numValue = parseFloat(value);
    if (value.includes('>')) return value;
  } else {
    numValue = value;
  }

  if (trendLayers.includes(activeLayer)) {
    if (isNaN(numValue)) return ''
    return formatPercent(numValue)
  }

  if (ratioLayers.includes(activeLayer) ||
    cdcBulkFeatures.includes(activeLayer) ||
    activeLayer === UNHEALTHY_BEHAVIOUR_INDEX ||
    activeLayer === SPECIAL_HEALTH_CARE_NEEDS_INDEX
  ) {
    if (isNaN(numValue)) return ''
    return formatPercent(100 * numValue)
  }

  if (chrBulkFeatures.includes(activeLayer)) {
    if (isNaN(numValue)) return '';
    return activeLayer === AIR_POLLUTION ? numValue.toFixed(1) : numValue.toFixed(0);
  }

  if (isPopup && (activeLayer === MEDIAN_INCOME ||
    activeLayer === MEDIAN_HOUSING_SALE_COST ||
    activeLayer === MEDIAN_GROSS_RENT)) {
    return `${Math.round(numValue).toLocaleString()}`
  }

  if (activeLayer === POPULATION ||
    activeLayer === HOUSEHOLDS ||
    activeLayer === PEOPLE_PER_MI2) {
    numValue = Math.round(numValue)

    if (numValue < 100) {
      return `${numValue}`
    }
  }

  if (activeLayer === GENDER_PAY_GAP) {
    if (value == null) return ''
    if (numValue === 0) return '0'
    return `${(numValue * 100).toFixed(1)}`
  }

  if (targetAudienceIndustries.includes(activeLayer) || activeLayer === GENDER_PAY_GAP) {
    return `${(numValue * 100).toFixed(0)}`
  }

  if (hwwFeatures.includes(activeLayer)) {
    return getHwwSymbol(activeLayer, numValue)
  }

  if (activeLayer === LIFE_EXPECTANCY) {
    return `${Math.round(numValue)}`
  } else if (isNaN(numValue)) {
    return '∞';
  } else if (numValue >= 1_000_000_000) {
    return `${(numValue / 1_000_000_000).toFixed(2)}B`
  } else if (numValue >= 100_000_000) {
    return `${(numValue / 1_000_000).toFixed(0)}M`
  } else if (numValue >= 10_000_000) {
    return `${(numValue / 1_000_000).toFixed(1)}M`
  } else if (numValue >= 1_000_000) {
    return `${(numValue / 1_000_000).toFixed(2)}M`
  } else if (numValue >= 10_000) {
    return `${(numValue / 1_000).toFixed(0)}K`;
  } else if (numValue >= 1_000) {
    return `${(numValue / 1_000).toFixed(1)}K`
  } else if (numValue >= 100) {
    return numValue.toFixed(0);
  } else {
    return numValue.toFixed(digits);
  }
}

function formatPercent(percentValue: number): string {
  if (Math.abs(percentValue) > 50) return `${percentValue.toFixed(0)}`
  if (Math.abs(percentValue) > 10) return `${percentValue.toFixed(1)}`
  return `${percentValue.toFixed(2)}`
}

function getHwwSymbol(layer: string, value: number): string {
  if (layer.includes('health')) {
    if (value >= 1.03) {
      return 'A'
    } else if (value >= 0.99) {
      return 'B'
    } else if (value >= 0.95) {
      return 'C'
    } else if (value >= 0.93) {
      return 'D'
    } else {
      return 'F'
    }

  } else if (layer.includes('wealth')) {
    if (value >= 1.2) {
      return 'A'
    } else if (value >= 1) {
      return 'B'
    } else if (value >= 0.9) {
      return 'C'
    } else if (value >= 0.7) {
      return 'D'
    } else {
      return 'F'
    }

  } else if(layer.includes('wise')) {
    if (value >= 1.1) {
      return 'A'
    } else if (value >= 0.8) {
      return 'B'
    } else if (value >= 0.7) {
      return 'C'
    } else if (value >= 0.6) {
      return 'D'
    } else {
      return 'F'
    }
  }

  return value.toFixed(2)
}
