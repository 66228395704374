<div  class="user-page-container">
  <mat-progress-bar mode="indeterminate"
                    *ngIf="(loadingService.isLoadingManual | async) || (loadingService.isLoadingIntercept | async)"
                    style="position: absolute; top: 0"></mat-progress-bar>
  <div class="wrapper">
    <div class="profile-container">
      <div class="profile-overview-container">
        <div class="avatar-container">
          <img
            src="assets/icons/AvatarDefault.svg"
            alt="default avatar"
          />
<!--          <button class="change-avatar-button">-->
<!--            <img src="assets/icons/photo.svg" alt="change avatar"/>-->
<!--          </button>-->
        </div>

          <h3>{{ userData?.firstName }} {{ userData?.lastName }}</h3>
          <span class="account-type">{{
            userData?.category === 'ENTERPRISE'
              ? 'Enterprise'
              : 'Individual'
            }}</span>
          <span class="account-type" *ngIf='userData?.isAdmin'>Admin✓</span>
      </div>

      <div class="my-reports">
        <span>My reports</span>
        <span class="profile-value"> 0 </span>
      </div>

      <div class="plan-container">
        <div class="current-plan" *ngIf='userData && userData.subscription'>
          <span>Current plan</span>
          <span class="profile-value" *ngIf='!userData.isAdmin'>
            {{SubscriptionPlans.description[userData.subscription!.plan].planName}}
          </span>
          <mat-form-field *ngIf="userData && userData.isAdmin" class='profile-value'>
            <mat-label>Change subscription (admin only)</mat-label>
            <mat-select class='profile-value' [value]="SubscriptionPlans[userData.subscription!.plan]"
                        (valueChange)='onSubscriptionChangedByAdmin($event, userData.email)'
            >
              <mat-option *ngFor='let plan of SubscriptionPlans | enumToArray'
                          [value]='plan'
              > {{plan}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="current-plan" style='margin-top: 10px' *ngIf='userData && userData.subscription'>
          <span>{{ userData.subscription.isAutoRenewable ? 'Next payment' : 'Active until' }}</span>
          <span class='profile-value'>
            {{userData.subscription.isLifetime ? 'forever' :
            userData.subscription.untilUtc | date: 'mediumDate'}}
          </span>
        </div>

        <div class="current-plan" style='margin-top: 10px' *ngIf='userData && !userData.subscription'>
          <span>Purchase a subscription to unlock Map AI`s full range of features</span>
        </div>

        <ng-container *ngIf="userData && userData.subscription; else noSubscription">
          <button class="gradient-button"
                  (click)="handleAutoRenewalChange(userData.subscription.isAutoRenewable)">
            {{ userData.subscription.isAutoRenewable ? 'Cancel auto-renewal' : 'Resume auto-renewal' }}</button>
        </ng-container>
        <ng-template #noSubscription>
          <button class="gradient-button" (click)="handleSeeAllPlans()">See all plans</button>
        </ng-template>
 </div>

      <div class="need-help-container">
        <button class="white-button-gradient" (click)="onLogOutClick()">
          Log out
        </button>
      </div>
    </div>
    <div class="content-container">
      <div class="header">
        <button
          routerLink="account-settings"
          routerLinkActive="selected"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          {{ accountSettings }}
        </button>

        <button
          routerLink="subscription"
          routerLinkActive="selected"
          [routerLinkActiveOptions]="{ exact: false }">{{subscription}}</button>
      </div>
      <div class="router-outlet">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
