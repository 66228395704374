<div class="modal-container">
  <button class="close-button" (click)="close()"><img src="assets/icons/Cancel.svg" alt="close window"></button>
  <h2>Confirmation</h2>
  <div class="plan-description">
    <span>{{planName}} Subscription</span>
    <span>{{price}}</span>
  </div>

<form>
  <div class="input-wrapper" *ngIf="isRealtor">
    <div class="input-container">
      <label for="realtorID">NAR Member ID</label>
      <input
        [value]="realtorId"
        (change)="handleRealtorIdChange($event)"
        id="realtorID">
    </div>
    <div class="error-messages" *ngIf="isRealtorIdInvalid">
      <label>NAR Member ID must be 8 or 9 letters code</label>
    </div>
  </div>
</form>


  <div class="checkbox-description">
    <div class="checkbox">
        <label>
            <input [(ngModel)]='isTOSChecked' type='checkbox'>
            <span>I have read and agreed to Map AI
            <a target='_blank'
               href='https://cdn.prod.website-files.com/645fc9b1f2ae787ad9542f5d/6546b03f4b0754ad48d51786_MapAI%20Terms%20of%20Use.docx.pdf'>Terms of Use</a>
            </span>
        </label>
    </div>
    <span class="fine-print">All sales are final and non-refundable</span>
  </div>

  <button [disabled]="!isButtonAvailable()" (click)="handleToPayment()" class="gradient-button to-payment-button">To payment</button>
</div>
