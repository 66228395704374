<div
  [ngClass]="{
    'select-industry-container': dropdownType === 'industry',
    'select-level-container': dropdownType === 'level',
    'select-inner-industry-container': dropdownType === 'inner-industry'
  }"
  class="dropdown-container"
>
  <div class="dropdown-menu" [ngClass]="{'select-active': isDropdownActive}">
    <button class="dropdown-header" (click)="toggleDropdown()">
      {{ currentValue | splitLevelText:false }}
      <span class="level-layer-size"> {{ currentValue | splitLevelText:true }} </span>
    </button>
    <ul class="dropdown-list" *ngIf="isDropdownActive">
      <li
        class="menu-element"
        *ngFor="let feature of features | keyvalue: sortFeatures"
        (click)="handleItemClick(feature.value)"
      >
        {{ $any(feature.value) | splitLevelText:false }}
        <span class="level-layer-size"> {{ $any(feature.value) | splitLevelText:true }} </span>
      </li>
    </ul>
  </div>
</div>
