import {Injectable} from '@angular/core';
import {MapboxGeoJSONFeature} from 'mapbox-gl';
import {NaturalId} from '../../menu/right-menu/layers-menu/population-menu/population.service';
import {
  ARBITRARY_CELL,
  COUNTY_LEVEL_LAYER,
  H3_RES5_LEVEL_LAYER,
  H3_RES7_LEVEL_LAYER,
  LayerStoreService,
  STATE_LEVEL_LAYER
} from './layer-store.service';
import {MapBoxService} from '../mapbox.service';
import {BehaviorSubject} from "rxjs";

export const SELECTED_POLYGON = 'SELECTED_POLYGON';

@Injectable({
  providedIn: 'root',
})
export class SelectedCellService {

  public selectedCellGeoId: BehaviorSubject<NaturalId | null>  = new BehaviorSubject<NaturalId | null>(null);

  private selectedFeature: MapboxGeoJSONFeature | null = null;

  constructor(private layerStoreService: LayerStoreService,
              private mapboxService: MapBoxService) {
    this.layerStoreService.activeLevel.subscribe(level => {
      this.onLevelChange()
    });
  }

  public selectCell(feature: MapboxGeoJSONFeature): void {
    // deselect previous cell
    this.deselectCell();
    this.selectedFeature = feature;
    this.selectedCellGeoId.next(feature.properties!.external_id);

    if (feature.layer.id !== ARBITRARY_CELL) {
      this.mapboxService.map.addLayer({
        id: SELECTED_POLYGON,
        type: 'line',
        source: {
          type: 'geojson',
          data: feature,
        },
        paint: {
          'line-color': '#695dff',
          'line-width': 2,
        },
      });
    }
  }

  private onLevelChange() {
    this.deselectCell();
  }

  public isCellSelected(): boolean {
    return this.selectedCellGeoId.getValue() !== null;
  }

  public getSelectedCellGeoId(): NaturalId | null {
    return this.selectedCellGeoId.getValue();
  }

  public getSelectedFeature(): MapboxGeoJSONFeature | null {
    return this.selectedFeature;
  }

  public getCellType(): string | undefined {
    const layerId = this.getSelectedFeature()?.layer.id

    switch (layerId ?? this.layerStoreService.activeLevel.getValue()) {
      case STATE_LEVEL_LAYER:
        return 'STATE'

      case COUNTY_LEVEL_LAYER:
        return 'COUNTY'

      case H3_RES7_LEVEL_LAYER:
        return 'H3_RES_7'

      case H3_RES5_LEVEL_LAYER:
        return 'H3_RES_5'

      case ARBITRARY_CELL:
        return ARBITRARY_CELL

      default:
        throw new Error('implement switch case for ' + layerId)
    }
  }

  public deselectCell() {
    this.selectedCellGeoId.next(null);
    this.selectedFeature = null;
    if (this.mapboxService.map && this.mapboxService.map.getLayer(SELECTED_POLYGON)) {
      this.mapboxService.map.removeLayer(SELECTED_POLYGON);
      this.mapboxService.map.removeSource(SELECTED_POLYGON);
    }
  }
}
