import {Component, Input, OnInit} from '@angular/core';
import {MenuGroupData} from "../../menu-group/menu-group.component";
import {LayerStoreService} from "../../../../../mapbox/services/layer-store.service";
import {MapBoxService} from "../../../../../mapbox/mapbox.service";

@Component({
  selector: 'app-feature-disclaimer',
  templateUrl: './feature-disclaimer.component.html',
  styleUrls: ['./feature-disclaimer.component.scss',
    '../../../../../../app.component.scss',
  '../../menu-group/menu-group.component.scss',]
})
export class FeatureDisclaimerComponent implements OnInit {

  @Input() disclaimer!: MenuGroupData['disclaimer']

  constructor(public layerStore: LayerStoreService,
              private mapboxService: MapBoxService) { }

  ngOnInit(): void {
  }

  public handleZoomInClick(zoom: number): void {
    this.mapboxService.map.zoomTo(zoom)
  }
}
