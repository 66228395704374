import {Pipe, PipeTransform} from '@angular/core';
import {SchoolsPoiProperties} from "../../../../menu/right-menu/layers-menu/education/education.service";
import {DataFormatter, FormattedCellInfo} from "../../../services/data-formatter";

@Pipe({
  name: 'schoolFormat'
})
export class SchoolFormatPipe implements PipeTransform {
  transform(properties: SchoolsPoiProperties, isSeeMoreActive: boolean): FormattedCellInfo[] {
    return DataFormatter.formatSchoolsPoiFeature(properties, isSeeMoreActive);
  }
}
