import { ColorScale } from '../visualization/colorscale/colorscale';
import {
    BEAUTY,
    cdcBulkFeatures,
    chrBulkFeatures,
    COMBINED_HWW_BY_COUNTRY,
    COMBINED_HWW_BY_STATE,
    CONVENIENCE_STORES,
    CRIME_RATE,
    crimeRateByVictimsTypeBulkFeatures,
    CUMULATIVE_TRAFFIC_INTENSITY,
    DRUGSTORES,
    GENDER_PAY_GAP,
    GINI_INDEX,
    HEALTH_INDEX_BY_COUNTRY,
    HEALTH_INDEX_BY_STATE,
    HOME_OWNERSHIP_RATIO,
    HOUSEHOLDS,
    LIFE_EXPECTANCY,
    MEDIAN_GROSS_RENT,
    MEDIAN_GROSS_RENT_TREND,
    MEDIAN_HOUSEHOLD_INCOME_TREND,
    MEDIAN_HOUSING_SALE_COST,
    MEDIAN_HOUSING_SALE_COST_TREND,
    MEDIAN_INCOME,
    PEOPLE_PER_MI2,
    POPULATION,
    POPULATION_GROWTH_TREND,
    RETAIL,
    ROAD_DENSITY,
    SPECIAL_HEALTH_CARE_NEEDS_INDEX,
    UNEMPLOYMENT_RATIO,
    UNHEALTHY_BEHAVIOUR_INDEX,
    UNINSURED_RATIO,
    WEALTH_INDEX_BY_COUNTRY,
    WEALTH_INDEX_BY_STATE,
    WISE_INDEX_BY_COUNTRY,
    WISE_INDEX_BY_STATE,
} from '../../../shared/types/feature-data-type';

export interface LayerDescription {
    colorScale?: ColorScale;
    layers: string[];
    isTrend?: boolean;
    colorsToShow?: number;
}

export enum AllLayers {
  MEDIAN_INCOME = 'median_income',
  COMMON_LAYER = 'common_layer',
  GINI_INDEX = 'gini_index',
  PROGRESSIVE_SCALE = 'progressive_scale',
  TRENDS_COLORSCALE = 'trends_colorscale',
  MORE_NEGATIVE_COLORSCALE = 'more_negative_colorscale',
  GENDER_PAY_GAP_COLORSCALE = "GENDER_PAY_GAP_COLORSCALE",
  HEALTH_INDEX = 'health_index',
  WEALTH_INDEX = 'wealth_index',
  WISE_INDEX = 'wise_index',
}

export namespace AllLayers {
  export const description: { [layer in AllLayers]: LayerDescription } = {
    [AllLayers.COMMON_LAYER]: {
      colorScale: new ColorScale([
        '#D73027',
        '#F46D43',
        '#FDAE61',
        '#F8D455',
        '#DFF365',
        '#B7EFA1',
        '#7FCC72',
        '#53B87C',
        '#216869',
      ]),
      layers: [
        COMBINED_HWW_BY_COUNTRY,
        COMBINED_HWW_BY_STATE,
        DRUGSTORES,
        CONVENIENCE_STORES,
        RETAIL,
        BEAUTY,
        LIFE_EXPECTANCY,

        MEDIAN_HOUSING_SALE_COST,
        MEDIAN_GROSS_RENT,
      ],
    },

    [AllLayers.MORE_NEGATIVE_COLORSCALE]: {
      colorScale: new ColorScale([
        '#fff5f0',
        '#fee0d2',
        '#fcbba1',
        '#fc9272',
        '#fb6a4a',
        '#ef3b2c',
        '#cb181d',
        '#a50f15',
        '#67000d',
      ]),
      layers: [
        UNINSURED_RATIO,
        UNEMPLOYMENT_RATIO,
        CRIME_RATE,
        ...cdcBulkFeatures,
        ...chrBulkFeatures,
        ...crimeRateByVictimsTypeBulkFeatures,
        UNHEALTHY_BEHAVIOUR_INDEX,
        SPECIAL_HEALTH_CARE_NEEDS_INDEX,
      ],
    },

    [AllLayers.GENDER_PAY_GAP_COLORSCALE]: {
      colorScale: new ColorScale(
        [
          '#7e3fff',
          '#8852fe',
          '#9564fd',
          '#a178fc',
          '#ad88fb',
          '#b89afa',
          '#c5acf9',
          '#d2c0f8',
          '#ded0f7',
          '#e9e3f6',
          '#eee', // White in the middle to represent 0
          '#ddeef6',
          '#c4e5f7',
          '#aeddf8',
          '#93d5f9',
          '#7bccfb',
          '#63c5fc',
          '#4bbdfb',
          '#35b4fd',
          '#1badfd',
          '#06a5ff',
        ],
        undefined,
        true,
        10
      ),
      layers: [GENDER_PAY_GAP]
    },

    [AllLayers.TRENDS_COLORSCALE]: {
      colorScale: new ColorScale(
        [
          // It is meant to be an odd number with 0 in the middle, equal amount of positive and negative colors
          // order is: negative colors from most intense -> 0 -> positive colors from less intense
          // More === Green
          '#D73027',
          '#E45A40',
          '#F08253',
          '#FCAB66',
          '#FFD479',
          '#FFE08C',
          '#FFF09F',
          '#ece7f2', // White in the middle to represent 0
          '#DFF365',
          '#C3E673',
          '#A7D881',
          '#8CCB8F',
          '#70BE9D',
          '#54B2AB',
          '#38A5B9',
        ],
        undefined,
        true,
        8
      ),
      layers: [
        MEDIAN_HOUSEHOLD_INCOME_TREND,
        MEDIAN_HOUSING_SALE_COST_TREND,
        MEDIAN_GROSS_RENT_TREND,
        HOME_OWNERSHIP_RATIO,
      ],
    },

    [AllLayers.PROGRESSIVE_SCALE]: {
      colorScale: new ColorScale([
        '#fff7fb',
        '#ece7f2',
        '#d0d1e6',
        '#a6bddb',
        '#74a9cf',
        '#3690c0',
        '#0570b0',
        '#045a8d',
        '#023858',
      ]),
      layers: [
        POPULATION,
        PEOPLE_PER_MI2,
        HOUSEHOLDS,
        ROAD_DENSITY,

        CUMULATIVE_TRAFFIC_INTENSITY,
      ],
    },

    [AllLayers.MEDIAN_INCOME]: {
      colorScale: new ColorScale([
        '#D73027',
        '#F46D43',
        '#FDAE61',
        '#F8D455',
        '#DFF365',
        '#B7EFA1',
        '#7FCC72',
        '#53B87C',
        '#448A7D',
        '#175583',
        '#022F4A',
      ]),
      layers: [MEDIAN_INCOME, POPULATION_GROWTH_TREND,
      ],
    },
    [AllLayers.GINI_INDEX]: {
      colorScale: new ColorScale(
        [
          '#216869',
          '#53B87C',
          '#7FCC72',
          '#DFF365',
          '#FDAE61',
          '#F46D43',
          '#D73027',
        ],
        [
          {min: 0, max: 0.449},
          {min: 0.45, max: 0.499},
          {min: 0.5, max: 0.529},
          {min: 0.53, max: 0.549},
          {min: 0.55, max: 0.579},
          {min: 0.58, max: 0.599},
          {min: 0.6},
        ]
      ),
      layers: [GINI_INDEX],
    },
    [AllLayers.WISE_INDEX]: {
      colorScale: new ColorScale(
        [
          '#D73027',
          '#FDAE61',
          '#DFF365',
          '#7FCC72',
          '#409126',
        ],
        [
          {min: 0, max: 0.599},
          {min: 0.6, max: 0.699},
          {min: 0.7, max: 0.799},
          {min: 0.8, max: 1.099},
          {min: 1.1, max: 10},
        ]
      ),
      layers: [WISE_INDEX_BY_COUNTRY, WISE_INDEX_BY_STATE],
    },
    [AllLayers.HEALTH_INDEX]: {
      colorScale: new ColorScale(
        [
          '#D73027',
          '#FDAE61',
          '#DFF365',
          '#7FCC72',
          '#409126',
        ],
        [
          {min: 0, max: 0.929},
          {min: 0.93, max: 0.949},
          {min: 0.95, max: 0.989},
          {min: 0.99, max: 1.029},
          {min: 1.03, max: 10},
        ]
      ),
      layers: [HEALTH_INDEX_BY_STATE, HEALTH_INDEX_BY_COUNTRY],
    },
    [AllLayers.WEALTH_INDEX]: {
      colorScale: new ColorScale(
        [
          '#D73027',
          '#FDAE61',
          '#DFF365',
          '#7FCC72',
          '#409126',
        ],
        [
          {min: 0, max: 0.699},
          {min: 0.7, max: 0.899},
          {min: 0.9, max: 0.999},
          {min: 1, max: 1.199},
          {min: 1.2, max: 10},
        ]
      ),
      layers: [WEALTH_INDEX_BY_COUNTRY, WEALTH_INDEX_BY_STATE],
    },
  };
}
