<div class="personalization-container">
  <h4>Favorite location</h4>
  <span>Give each favorite cell a unique title to easily identify and organize them</span>

  <div class="input-wrapper">
    <div class="input-container">
      <label for="title">Title</label>
      <input type="text" [(ngModel)]="cellTitle" id="title">
    </div>

    <div class="input-container">
      <label for="description">Description / Comment</label>
      <input type="text" [(ngModel)]="cellDescription" id="description">
    </div>
  </div>

  <button class="gradient-button" (click)="requestCreateFavoriteCell()">Save</button>
</div>
