import {Injectable} from '@angular/core';
import {MapHttpService} from "../mapbox/services/map-http.service";
import {UserAccessService} from "../../user/access/user-access.service";
import {catchError, debounceTime, of} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {SearchGeocoderService} from "../../shared/services/search-geocoder.service";
import {LayerStoreService, shortLevelNameToTile} from "../mapbox/services/layer-store.service";
import * as mapboxgl from "mapbox-gl";
import {AuthenticationService} from "../../user/authentication.service";

export type FavoriteCell = {
  cellType: string
  description: string
  geoId: string
  latitude: number
  longitude: number
  title: string
}

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  public favoritePlaces: FavoriteCell[] = []


  constructor(private http: MapHttpService,
              private toast: ToastrService,
              private geocoderService: SearchGeocoderService,
              private layerStore: LayerStoreService,
              private accessService: UserAccessService,
              private authService: AuthenticationService) {
    this.accessService.isUserPlanUpdated
      .pipe(debounceTime(250))
      .subscribe(() => {
        // Get init favorites on load
        if (this.favoritePlaces.length === 0) {
          this.updateFavoritesList()
        }
      })
  }

  public moveToFavoriteCellLocation(cell: FavoriteCell, map: mapboxgl.Map): void {
    const zoom = this.layerStore.getSufficientZoomByLevel(cell.cellType)
    const level = shortLevelNameToTile.get(cell.cellType)
    this.geocoderService.flyToAddress([cell.longitude, cell.latitude], map, zoom)

    map.once('moveend', () => {
      this.layerStore.activeLevel.next(level!)
    })
  }

  public updateFavoritesList(): void {
    if (!this.authService.hasAccessToken()) return

    this.http.getFavoriteCells()
      .pipe(catchError(err => {
        console.warn('ERROR UPDATING FAVORITE CELLs LIST: ', err)
        return of(null)
      }))
      .subscribe(data => {
        if (data) this.favoritePlaces = data.object
      })
  }

  public createFavoriteCell(id: string, description: string, title: string): void {
    this.http.createFavoriteCell(id, description, title)
      .pipe(catchError(err => {
        console.warn('ERROR CREATING FAVORITE CELL: ', err)
        this.toast.error('Failed to add cell to favorites')
        return of(null)
      }))
      .subscribe(data => {
        this.toast.success('Cell added to favorites successfully')
        this.updateFavoritesList()
      })
  }

  public removeFavoriteCell(id: string, cellType: string): void {
    this.http.removeFavoriteCell(id, cellType)
      .pipe(catchError(err => {
        console.warn('ERROR REMOVING FAVORITE CELL: ', err)
        this.toast.error('Failed to remove cell from favorites')
        return of(null)
      }))
      .subscribe(data => {
        this.toast.success('Cell removed from favorites successfully')
        this.updateFavoritesList()
      })
  }
}
