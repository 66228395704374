import {AbstractControl} from "@angular/forms";

export function passwordValidator(control: AbstractControl): {[key: string]: any} | null {
  const password = control.value;
  if (password.length < 8 || password.length > 32) {
    return { lengthError: true };
  }
  if (!/[a-z]/.test(password)) {
    return { lowercaseError: true };
  }
  if (!/[A-Z]/.test(password)) {
    return { uppercaseError: true };
  }
  if (!/(\d|[~!?@#$%^&*_\-+[\]{}><\/\\|"'.,:;])/.test(password)) {
    return { digitOrSpecialCharError: true };
  }
  // Check for any illegal characters
  if (/[^a-zA-Z0-9~!?@#$%^&*_\-+[\]{}><\/\\|"'.,:;]/.test(password)) {
    return { illegalCharError: true };
  }
  return null;
}
