import {Pipe, PipeTransform} from '@angular/core';
import {NumberFormatterPipe} from "../../../../shared/pipes/number-formatter.pipe";

@Pipe({
  name: 'rangeTextFormatter'
})
export class RangeTextFormatterPipe implements PipeTransform {
  constructor(private numberFormatter: NumberFormatterPipe) {
  }

  transform(colorScaleColors: any[][], index: number, activeLayer: string): string {
    if (index === 0) {
      return `< ${this.numberFormatter.transform(colorScaleColors[index][0], 2, activeLayer)}`
    } else if (index !== colorScaleColors.length -1) {
      return `${this.numberFormatter.transform(colorScaleColors[index - 1][0], 2, activeLayer)}
      - ${this.numberFormatter.transform(colorScaleColors[index][0], 2, activeLayer)}`
    } else {
      return `> ${this.numberFormatter.transform(colorScaleColors[index - 1][0], 2, activeLayer)}`
    }
  }

}
