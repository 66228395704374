import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { JsonResponse } from '../../shared/api/backend-config';

@Injectable({
  providedIn: 'root'
})
export class EmailConfirmationService {

  private readonly confirmUrl= environment.apiUrl + 'user/email/confirm';

  constructor(private client: HttpClient) {

  }


  /**
   * Returned string is confirmed email.
   */
  public sendConfirmEmailLetter(): Observable<JsonResponse<String>> {
    return this.client.post<JsonResponse<String>>(this.confirmUrl, null);
  }
}
