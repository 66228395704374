
export function extractEmailSite(email: string) {
    const emailDomain = email.split('@')[1];
    if(emailDomain.includes('gmail')) {
        return 'https://mail.google.com';
    }

    if(emailDomain.includes('outlook')) {
        return 'https://outlook.live.com';
    }

    if(emailDomain.includes('yahoo')) {
        return 'https://mail.yahoo.com';
    }

    if(emailDomain.includes('mail.ru')) {
        return 'https://e.mail.ru/inbox/';
    }

    if(emailDomain.includes('yandex')) {
        return 'https://mail.yandex.ru';
    }

    return null;
}

export function getPasswordRecoveryRequestSuccess(email: string): { text: string | undefined, title: string, emailSite: string | null } {
  const emailSite = extractEmailSite(email);
  const emailSiteText = emailSite != null ? `Click me to open ${emailSite} ` : undefined;
  const title = `Please check ${email} to update your password`

  return {text: emailSiteText, title: title, emailSite: emailSite }
}

export function getPasswordRecoveryRequestError(email: string, errorMessage: string): {text: string, title: string, emailSite: string | null} {
  let emailSite = extractEmailSite(email);
  let customText
  let customTitle

  const defaultText = 'Can\'t send confirmation letter. ' +
      'Please try again later. We\'re already working on the problem '
  const defaultTitle = 'Error'

  if (errorMessage.includes('already has reset password token ')) {
    customText = emailSite != null ? `Check your ${email}. Instruction to reset your password is already sent there` : undefined
    customTitle = emailSite != null ? `Click me to open ${emailSite}` : undefined
  }

  if (errorMessage.includes('not found')) {
    emailSite = null
    customTitle = 'User not found'
    customText = `Please make sure that ${email} is correct email address`
  }

  return {text: customText ?? defaultText, title: customTitle ?? defaultTitle, emailSite: emailSite}
}
