<div class="settings-form-container">
  <form class="settings-form" [formGroup]="settingsForm">

    <div class="input-wrapper">
      <div class="input-container">
        <label for="firstName">First name</label>
        <input formControlName="firstName"
               id="firstName">
      </div>
      <div class="input-container">
        <label for="lastName">Last name</label>
        <input formControlName="lastName"
               id="lastName">
      </div>
    </div>

    <div class="input-wrapper" *ngIf="isEnterpriseAccount">
      <div class="input-container">
        <label for="companyName">Company</label>
        <input formControlName="companyName"
               id="companyName">
      </div>
      <div class="input-container">
        <label for="position">Position</label>
        <input formControlName="position"
               id="position">
      </div>
    </div>

    <div class="input-wrapper">
      <div class="input-container">
        <label  for="email">Email</label>
        <input formControlName="email"
               id="email">
        <div *ngIf='!isEmailConfirmed' class='confirm-email-wrapper'>
           <span class='link-like-text confirm-email-text'
          (click)='sendConfirmEmailLetter()'>
            Please click here to confirm email
        </span>
        </div>
      </div>

      <div class="error-messages" *ngIf="isInputStatusInvalid('email')">
        <label>Invalid email format</label>
      </div>


      <div class="input-container">
        <label  for="phone">Phone</label>
        <input formControlName="phone"
               id="phone">

      </div>
      <div class="error-messages" *ngIf="isInputStatusInvalid('phone')">
        <label>Invalid phone format</label>
      </div>
    </div>

    <div class="input-wrapper">
      <div class="input-container">
        <label  for="city">City</label>
        <input formControlName="city"
               id="city">
      </div>


      <div class="input-container">
        <label  for="stateCounty">State/County</label>
        <input formControlName="stateCounty"
               id="stateCounty">
      </div>
    </div>

    <div class="input-wrapper">
      <div class="input-container">
        <label  for="zipCode">ZIP code</label>
        <input formControlName="zipCode"
               id="zipCode">
      </div>

      <div class="input-wrapper">
        <div class="input-container">
          <label  for="country">Country</label>
          <input formControlName="country"
                 id="country">
        </div>
    </div>
    </div>

    <span class="link-like-text" (click)="requestChangePassword()">Change password</span>

  </form>

  <button class="gradient-button" (click)="onUpdateUserInfo()">Update</button>
</div>
