import {Component, OnInit} from '@angular/core';
import {IconRegistryService} from '../../services/icon-registry.service';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../../../user/authentication.service";
import {BreakpointObserverService} from "../../services/breakpoint-observer.service";
import {UserAccessService} from "../../../user/access/user-access.service";

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: [
    './navigation-menu.component.scss',
  'navigation-mobile.scss'
  ],
})
export class NavigationMenuComponent implements OnInit {
  constructor(private iconRegistry: IconRegistryService,
              private router: Router,
              private route: ActivatedRoute,
              public authService: AuthenticationService,
              public accessService: UserAccessService,
              public breakpointObserverService: BreakpointObserverService) {
    this.iconRegistry.initNavigationIcons();
  }

  ngOnInit(): void {
  }

  //check if url corresponds to route including query params for map
  isActiveWithQueryParams(route: string): boolean {
    const currentRoute = this.router.url.split('?')[0];
    const currentQueryParams = this.route.snapshot.queryParams;

    if (currentRoute !== route) {
      return false;
    }

    if (Object.keys(currentQueryParams).length === 0) {
      return true;
    }

    if (currentQueryParams.hasOwnProperty('ll')) {
      return true;
    }

    return false;
  }

}
