import {Injectable} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {MapModalData, MapRedirectModalComponent} from "../../user/map-redirect-modal/map-redirect-modal.component";
import {SubscriptionsService} from "../../user/subscriptions.service";
import {
  SubscriptionConfirmationModalComponent
} from "../../user/user/subscriptions-menu/subscription-confirmation-modal/subscription-confirmation-modal.component";
import {SubscriptionPlans} from "../../user/user/user.model";
import {LANDSCAPE_MOBILE} from "./device-orientation.service";
import {SwitchToPortraitComponent} from "../components/switch-to-portrait-modal/switch-to-portrait.component";
import {
  SubscriptionRenewalModalComponent
} from "../../user/user/subscriptions-menu/subscription-confirmation-modal/subscription-renewal-modal/subscription-renewal-modal.component";
import {
  FavoritePersonalizationModalComponent
} from "../../map/favorites/favorite-personalization-modal/favorite-personalization-modal.component";
import {PopupService} from "../../map/mapbox/services/popup.service";


@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public isModalOpen = false;

  constructor(private dialog: MatDialog,
              private subscriptionsService: SubscriptionsService,
              private popupService: PopupService) {

  }

  //TODO: Extend this service to open other modals too
  public openModal(text: MapModalData): void {
    // Check if modal is already opened otherwise it could open in multiple instances
    if (!this.isModalOpen) {
      this.isModalOpen = true;  // Set the status to open
      const dialogRef = this.dialog.open(MapRedirectModalComponent, {
        disableClose: text.disableClose,
        maxWidth: '620px',
        data: text
      });


      dialogRef.afterClosed().subscribe(() => {
        this.isModalOpen = false;
      });
    }
  }

  // public handleLimitedAccessModal(focusedFeatures: FocusedFeatures, zoom: number): void {
  //   const accessibleFeatures = this.subscriptionsService.getAccessibleFeatures().features;
  //   const focusedFeaturesArray = focusedFeatures.getFeatures();
  //
  //   const existsInFocusedFeatures = accessibleFeatures.some(
  //     accessibleFeature => focusedFeaturesArray.find(
  //       focusedFeature => focusedFeature.properties!.external_id === accessibleFeature
  //     )
  //   );
  //
  //   if (!existsInFocusedFeatures || zoom < 9) {
  //     this.openModal(moveTrialUserToAtlantaText)
  //   }
  // }

  public handleSubscriptionConfirmationModal(plan: SubscriptionPlans, isPeriodMonthly: boolean): void {
    this.dialog.open(SubscriptionConfirmationModalComponent, {
      disableClose: false,
      maxWidth: '620px',
      data: {
        plan: plan,
        isPeriodMonthly: isPeriodMonthly
      }
    })
  }

  public handleSubscriptionRenewalModal(isAutoRenewable: boolean): void {
    this.dialog.open(SubscriptionRenewalModalComponent, {
      disableClose: false,
      maxWidth: '620px',
      data: {
        isAutoRenewable: isAutoRenewable
      }
    })
  }

  public handleSwitchLandscapeModal(orientation: string): void {
    if (orientation === LANDSCAPE_MOBILE) {
      this.dialog.open(SwitchToPortraitComponent, {
        disableClose: false,
        maxWidth: '620px',
        maxHeight: '180px',
        data: {
          title: 'Rotate Your Device',
          text: 'Please rotate your device to portrait mode',
          closable: true
        }
      })
    }
  }

  public handleFavoriteCellPersonalizationModal(id: string): void {
    this.dialog.open(FavoritePersonalizationModalComponent, {
      disableClose: false,
      maxWidth: '620px',
      maxHeight: '510px',
      data: {id, title: this.popupService.popupTitle}
    })
  }
}
