import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Feature} from "@turf/helpers";
import {Plotly} from "angular-plotly.js/lib/plotly.interface";
import {ToastrService} from "ngx-toastr";
import { pieChartDataToCSV, tracesBarChartDataToCSV} from "../chart-utility";

export const COPY_ICON_SVG = 'M89.62,13.96v7.73h12.19h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02v0.02 v73.27v0.01h-0.02c-0.01,3.84-1.57,7.33-4.1,9.86c-2.51,2.5-5.98,4.06-9.82,4.07v0.02h-0.02h-61.7H40.1v-0.02 c-3.84-0.01-7.34-1.57-9.86-4.1c-2.5-2.51-4.06-5.98-4.07-9.82h-0.02v-0.02V92.51H13.96h-0.01v-0.02c-3.84-0.01-7.34-1.57-9.86-4.1 c-2.5-2.51-4.06-5.98-4.07-9.82H0v-0.02V13.96v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07V0h0.02h61.7 h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02V13.96L89.62,13.96z M79.04,21.69v-7.73v-0.02h0.02 c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v64.59v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h12.19V35.65 v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07v-0.02h0.02H79.04L79.04,21.69z M105.18,108.92V35.65v-0.02 h0.02c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v73.27v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h61.7h0.02 v0.02c0.91,0,1.75-0.39,2.37-1.01c0.61-0.61,1-1.46,1-2.37h-0.02V108.92L105.18,108.92z';
export const COPY_ICON_SVG_SIZE = 128;


@Component({
  selector: 'app-plotly-chart',
  templateUrl: './plotly-chart.component.html',
  styleUrls: ['./plotly-chart.component.scss']
})
export class PlotlyChartComponent implements OnInit, OnChanges {

  @Input() selectedYear!: number;
  @Input() naturalId!: Feature['id']
  @Input() chart!: {
    data: Plotly.Data;
    layout: Plotly.Layout;
  }
  @Input() chartStyle: { [key: string]: any; } = {'height': '430px', 'margin-top': '0px'}
  @Input() isChartVisible!: boolean
  @Input() chartName: string = ''
  @Input() e2eName: string | undefined;

  public readonly currentChartConfig: Plotly.Config = {
    displayModeBar: true,
    responsive: true,
    displaylogo: false,
    modeBarButtonsToRemove: ['zoom', 'pan', 'zoomIn', 'Autoscale', '3D', 'Cartesian', 'Geo', 'pan2d','select2d','lasso2d','resetScale2d','zoomOut2d'],
    toImageButtonOptions: {
      format: 'png',
      filename: `plotly_chart`,
      height: 600,
      width: 800,
      scale: 5
    },
    modeBarButtonsToAdd: [
      [{
        name: 'Copy to clipboard',
        icon: {
          path: COPY_ICON_SVG,
          width: COPY_ICON_SVG_SIZE, height: COPY_ICON_SVG_SIZE,
        },
        click: () => {
          this.handleSaveCsvButton()
        }
      }]
    ]
  }

  constructor(private toast: ToastrService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['naturalId']) {
      this.currentChartConfig.toImageButtonOptions.filename = `${this.naturalId}_${this.chartName}`
    }
  }

  private handleSaveCsvButton(): void {
    const data = this.chart.data
    const layout = this.chart.layout
    const type = data[0].type

    let csv = '';

    if (type === 'pie') {
      csv = pieChartDataToCSV(data, layout)
    } else if (type === 'bar') {
      csv = tracesBarChartDataToCSV(data, layout)
    } else {
      this.toast.warning('Could not copy chart for chart of type: ', type)
      return
    }

    navigator.clipboard.writeText(csv).then(() => {
      this.toast.success('Chart is copied to clipboard');
    });

  }

}
