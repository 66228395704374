<div class="filters-menu-container">

  <div class="active-filters">
    <div class="filter" *ngFor="let filter of filtersService.activeFilters">
      <div class="title">
        <span>{{filter.featureConst}}</span>
        <button (click)="filtersService.removeFilter(filter.featureConst)">remove</button>
      </div>

      <div class="inputs">
        <input
          type="text"
          placeholder="min"
          [value]="filter.min"
          (input)="updateFilterValue($event, filter, 'min')"
          (keypress)="onlyAllowNumbers($event)"
        >
        to
        <input
          type="text"
          placeholder="max"
          [value]="filter.max"
          (input)="updateFilterValue($event, filter, 'max')"
          (keypress)="onlyAllowNumbers($event)"
        >
      </div>
    </div>
  </div>

  <button class="toggle-list-button" (click)="isListOpened = !isListOpened">{{ isListOpened ? 'Close' : 'Add filter' }}</button>

  <div class="features-dropdown-list-wrapper" *ngIf="isListOpened">
    <div class="features-dropdown-list">
        <span *ngFor="let feature of filtersService.featuresList" (click)="filtersService.addFilter(feature)">{{feature}}</span>
    </div>
  </div>
</div>
