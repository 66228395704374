<div class="modal">
  <span class="title">{{currentModalData.title}}</span>

  <div class="content-container">
    <div class="text-container">
      <span class="subtitle">{{currentModalData.subtitle}}</span>
      <p class="text-content">{{currentModalData.text}}</p>
    </div>

    <div class="buttons-container">
      <button class="white-button-gradient" (click)="handleClose()">Go back</button>
      <button class="gradient-button" (click)="handleRenewalChange()">{{ currentModalData.title }}</button>
    </div>
  </div>
</div>
