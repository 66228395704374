<div class="success-container" [attr.data-cy]="'registration-success-window'">

  <div class="success-window">
    <h2>Map AI account created</h2>

    <div class="text-content">
      <span>Your free Map AI trial has been activated!</span>
      <p>Your trial includes full access to Map AI data on States,
        Counties level and sample area on Hexagons (cells) level.
        You have 3 days to try Map AI features,
        then for continuing use of MapAI functions you need to select a
        <a (click)="goToSubscriptions()" style="color: blue;">Subscription plan</a>.</p>

      <button class="to-map-button" [attr.data-cy]="'to-map-button'" (click)="goToMap()">To Map</button>
    </div>


  </div>

</div>
