import {Injectable} from '@angular/core';
import {FeatureStatus} from '../population-menu/population.service';
import {Plotly} from 'angular-plotly.js/lib/plotly.interface';
import {MapHttpService} from '../../../../mapbox/services/map-http.service';
import {JsonResponse} from '../../../../../shared/api/backend-config';
import {catchError, of} from 'rxjs';
import {RentRange} from '../../../../../shared/model/range';
import {getPercents, sortKeys} from '../shared/chart-utility';
import {MapboxGeoJSONFeature} from 'mapbox-gl';

export interface HealthInsuranceJSON {
  distribution: HealthInsurance[]
}

export interface LifeExpectancyJSON {
  byCells: LifeExpectancyByCells[]
}

export interface LifeExpectancyByCells {
  naturalId: string,
  lifeExpectancy: number
}

export interface HealthInsurance {
  sex: string,
  ageFrom: number,
  ageTo: number,
  healthInsurance: boolean,
  population: number
}

export interface HealthFeatureStatus extends FeatureStatus {
  containsInsuranceDistribution: boolean,
  insuranceDistributionYear: number
}

@Injectable({
  providedIn: 'root'
})
export class HealthService {
  //TODO: Check every once in a while, maybe other years are accessible now
  public readonly accessibleYears: number[] = [
    // 2010, 2011,
    2012, 2013, 2014, 2015,
    2016, 2017, 2018, 2019, 2020, 2021, 2022
  ];

  private static readonly insuranceAgeRanges: RentRange[] = [
    new RentRange(0, 18),
    new RentRange(18, 25),
    new RentRange(25, 35),
    new RentRange(35, 45),
    new RentRange(45, 65),
    new RentRange(65, RentRange.MAX_RENT),
  ]

  public readonly featureStatus: HealthFeatureStatus = {
    featureId: undefined,
    isSelected: false,
    containsInsuranceDistribution: false,
    insuranceDistributionYear: 2022
  }

  public readonly insuranceDistributionChart: {
    data: Plotly.Data,
    layout: Plotly.Layout
  } = {
    data: [],
    layout: {}
  }

  constructor(private http: MapHttpService) { }


  public fillChartsData(feature: MapboxGeoJSONFeature): void {
    const naturalId = feature.properties!.external_id;

    this.http.getInsuranceData(naturalId, this.featureStatus.insuranceDistributionYear)
      .subscribe((data: JsonResponse<HealthInsuranceJSON>) => {
        this.featureStatus.isSelected = true
        this.featureStatus.containsInsuranceDistribution = true
        this.featureStatus.naturalId = naturalId

        this.fillHealthInsuranceChart(data.object.distribution)
      })
      catchError(err => {
        this.featureStatus.isSelected = true
        this.featureStatus.naturalId = naturalId
        this.featureStatus.containsInsuranceDistribution = false
        console.error('ERROR FETCHING INSURANCE DISTRIBUTION', err)
        return of(null)
      })
  }

  private fillHealthInsuranceChart(data: HealthInsurance[]): void {
    const aggregatedData = this.aggregateInsuranceData(data, HealthService.insuranceAgeRanges)

    const keys = sortKeys(Object.keys(aggregatedData))

    const traces: Plotly.Data = [];

    ['Insured', 'Uninsured'].forEach(status => {
      const color = status === 'Insured' ? '#774FA0' : '#EFB743';

      // @ts-ignore
      const malePop = keys.map(key => aggregatedData[key][status]['Male']);
      // @ts-ignore
      const femalePop = keys.map(key => aggregatedData[key][status]['Female']);
      const totalPop = malePop.map((pop, i) => pop + femalePop[i]);

      const hoverTexts = totalPop.map((total, i) =>
        `% of ${status}: ${((total / totalPop.reduce((acc, curr) => acc + curr, 0)) * 100).toFixed(1)}%<br>Male: ${getPercents(total, malePop)[i].toFixed(1)}%<br>Female: ${getPercents(total, femalePop)[i].toFixed(1)}%<br>`
      );

      const totalValues = totalPop.reduce((acc, curr) => acc + curr, 0)

      if (totalValues < 10) {
        this.featureStatus.containsInsuranceDistribution = false
        return
      }

      traces.push({
        x: keys,
        y: getPercents(totalPop.reduce((acc, curr) => acc + curr, 0), totalPop),
        name: status,
        text: hoverTexts,
        textposition: 'none',
        hoverinfo: 'text',
        legendgroup: status,
        marker: { color },
        type: 'bar',
      });
    });

    this.insuranceDistributionChart.data = traces;
    this.insuranceDistributionChart.layout = {
      barmode: 'group',
      autosize: true,
      hovermode: 'closest',
      xaxis: { title: 'Age' },
      yaxis: {
        title: 'People',
        tickformat: ',.0f',
        ticksuffix: '%'
      },
      bargap: 0.3,
      bargroupgap: 0.1,
      legend: {
        x: -0.15,
        y: 1.2,
        orientation: 'h',
      },
      height: 350,
      width: 390,
      margin: {b: 35, r: 30, t: 35},
      font: {
        family: 'Rising Sun, sans-serif'
      },
    };
  }

  private aggregateInsuranceData(data: HealthInsurance[], costRangeAggregation: RentRange[]): Record<string, number> {

    let insuranceData: any

    insuranceData = data.map(el => {
        if (el.ageFrom === el.ageTo) return [];

        let ageFrom = el.ageFrom;

        let ageTo = !!el.ageTo ? el.ageTo : RentRange.MAX_RENT;

        const householdRentRange = new RentRange(ageFrom, ageTo);

        const matchingRanges = costRangeAggregation.filter(range =>
          range.containsWholeRange(householdRentRange)
        );

        return matchingRanges.map(range => ({
          sex: el.sex,
          healthInsurance: el.healthInsurance,
          range: range,
          population: el.population,
        }));
      }).flat();

    insuranceData!.sort((a: any, b: any) => {
      return a.range.fromInclusive - b.range.fromInclusive;
    });

    const aggregatedData = insuranceData.reduce((acc: any, curr: any) => {
      const rangeStr = curr.range.toInclusiveString();

      // initialize if not exist
      if (!acc[rangeStr]) {
        acc[rangeStr] = {
          Insured: {
            Male: 0,
            Female: 0,
          },
          Uninsured: {
            Male: 0,
            Female: 0,
          },
        };
      }

      // add to the correct category
      const insuranceStatus = curr.healthInsurance ? 'Insured' : 'Uninsured';
      const sex = curr.sex === 'M' ? 'Male' : 'Female';
      acc[rangeStr][insuranceStatus][sex] += curr.population;

      return acc;
    }, {} as Record<string, {Insured: {Male: number, Female: number}, Uninsured: {Male: number, Female: number}}>);

    return aggregatedData;
  }

}
