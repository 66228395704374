import { Injectable } from '@angular/core';
import { FeatureStatus } from '../population-menu/population.service';
import { Plotly } from 'angular-plotly.js/lib/plotly.interface';
import { MapHttpService } from '../../../../mapbox/services/map-http.service';
import { RoadDensityService, RoadLength } from '../road-density/road-density.service';
import { of } from 'rxjs';
import {getPieChartLayout } from "../shared/chart-utility";
import {MapboxGeoJSONFeature} from "mapbox-gl";


export interface TrafficIntensityBackend {
  distribution: RoadLength
}

export interface PhysicalEnvironmentFeatureStatus extends FeatureStatus{
  containsTrafficIntensity: boolean,
}

@Injectable({
  providedIn: 'root'
})
export class PhysicalEnvironmentService {

  public featureStatus: PhysicalEnvironmentFeatureStatus = {
    featureId: undefined,
    isSelected: false,
    containsTrafficIntensity: false
  }

  public trafficIntensityChart: {
    data: Plotly.Data,
    layout: Plotly.Layout
  } = {
    data: [],
    layout: {}
  }

  constructor(private http: MapHttpService) { }

  public fillChartsData(feature: MapboxGeoJSONFeature): void {
    const id = feature.properties?.external_id

    this.http.getTrafficIntensityChartData(id)
      .subscribe(data => {
        if (data.object.distribution) {
          this.featureStatus.naturalId = id
          this.featureStatus.isSelected = true
          this.featureStatus.containsTrafficIntensity = true
        }

        const groupedByFRC = RoadDensityService.getRoadsGroupedByFRC(data.object.distribution)

        const values = Object.values(groupedByFRC).map(el => Math.round(el))
        const labels = Object.keys(groupedByFRC).map(el => el.charAt(0).toUpperCase() + el.slice(1))

        const maxValue = Math.max(...values)

        if (!maxValue || maxValue === -Infinity) {
          this.featureStatus.naturalId = id
          this.featureStatus.containsTrafficIntensity = false
        } else {
          this.featureStatus.naturalId = id
          this.featureStatus.containsTrafficIntensity = true
        }

        this.trafficIntensityChart.data = [{
          values: values,
          labels: labels,
          type: 'pie',
          hole: 0.3,
          textposition: 'inside',
          hoverinfo: 'label+percent+value',
          hovertemplate: '&nbsp;&nbsp;%{label} AADT: %{value} (%{percent})&nbsp;&nbsp;<extra></extra>',
        }]

        this.trafficIntensityChart.layout = getPieChartLayout(values, 'Road type', 'AADT')
      }, (error) => {
        this.featureStatus.isSelected = true
        this.featureStatus.containsTrafficIntensity = false
        console.error('ERROR FETCHING TRAFFIC INTENSITY', error)
        return of(null);
      })
  }


}
