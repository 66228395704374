import { Injectable } from '@angular/core';
import { Feature, point, Polygon } from '@turf/helpers';
import booleanIntersects from '@turf/boolean-intersects';
import * as mapboxgl from 'mapbox-gl';
import {
    FillLayer,
    GeoJSONSource,
    LngLat,
    MapboxGeoJSONFeature,
} from 'mapbox-gl';
import { LayerStoreService } from '../../services/layer-store.service';
import { FeatureFocusService } from '../../../feature-focus/feature-focus.service';
import { MapBoxService } from '../../mapbox.service';
import circle from '@turf/circle';
import { ArbitraryCellService } from '../../services/arbitrary-cell.service';

export const CIRCLE_HIGHLIGHT_LAYER = 'CIRCLE_HIGHLIGHT_LAYER';

@Injectable({
  providedIn: 'root',
})
export class SelectionToolService {
  private circleRadius = 10;

  //true means that selection is active and user only have to click on map to render circle
  private _circleSelectionStatus: boolean = false;
  get circleSelectionStatus(): boolean {
    return this._circleSelectionStatus;
  }

  public lastClickCenter: LngLat | null = null;

  /**
   * Keep geometry itself as well as mapbox feature to simplify querying it.
   * Mapbox feature sometimes returned as clipped by screen,
   * so such problem is workaround by keeping geometry in service.
   */
  private _lastCircleGeometry: Feature<Polygon> | null = null;

  get lastCircleGeometry(): Feature<Polygon> | null {
    return this._lastCircleGeometry;
  }

  public isCircleRendered = false;

  constructor(
    private layerStore: LayerStoreService,
    private focusService: FeatureFocusService,
    private mapboxService: MapBoxService,
    private arbitraryCellService: ArbitraryCellService,
  ) {
  }

  public enableCircleSelection(): void {
    this._circleSelectionStatus = true;

    if (!this.mapboxService.map.getLayer(CIRCLE_HIGHLIGHT_LAYER)) {
      this.addCircleLayer(null);
      this.selectAround()
    }

  }

  public disableCircleSelection(): void {
    this._circleSelectionStatus = false
  }

  public disable(): void {
    this.isCircleRendered = false;
    this._circleSelectionStatus = false;

    this.focusService.defocusFeaturesByUserSelection(
      this.mapboxService.map,
      this.layerStore.activeLevel.getValue()
    );

    this.arbitraryCellService.resetArbitraryCell()

    this.clearLayers(this.mapboxService.map);
  }

  public changeSelectionRadius(radiusMiles: number) {
    this.circleRadius = radiusMiles;
    this.selectAround(this.lastClickCenter!);
  }

  public selectAround(center: LngLat | null = this.lastClickCenter): void {
    this.setLastClickCenter(center!)

    if (!this.circleSelectionStatus || !center) return;

    this.setLastClickCenter(center)

    let circleGeometry: Feature<Polygon>

    const features: MapboxGeoJSONFeature[] =
      this.mapboxService.map.queryRenderedFeatures(undefined, {
        layers: [this.layerStore.activeLevel.getValue()],
      });

    if (!this.isCircleRendered) {
      circleGeometry = this.getCircleGeometry(center);
      this.lastClickCenter = center;
    } else {
      circleGeometry = this.getCircleGeometry(this.lastClickCenter);
    }

    this.rerenderCircleGeometry(circleGeometry);
    this._lastCircleGeometry = circleGeometry;

    const selectedFeatures = features.filter(
      (feature: MapboxGeoJSONFeature) =>
        booleanIntersects(feature, circleGeometry.geometry)
    );

    this.focusService.focusFeaturesByUserSelection(
      selectedFeatures,
      this.mapboxService.map
    );

    this.isCircleRendered = true;
  }

  private addCircleLayer(center: LngLat | null): void {
    this.mapboxService.map.addLayer(this.getCircleHighlightLayer(center));
  }

  private rerenderCircleGeometry(geometry: Feature<any>): void {
    (this.mapboxService.map.getSource(
        CIRCLE_HIGHLIGHT_LAYER
      ) as GeoJSONSource).setData(geometry);
  }

  private clearLayers(map: mapboxgl.Map): void {
    if (map.getLayer(CIRCLE_HIGHLIGHT_LAYER)) {
      map.removeLayer(CIRCLE_HIGHLIGHT_LAYER);
      map.removeSource(CIRCLE_HIGHLIGHT_LAYER);
    }
  }

  private getCircleGeometry(center: LngLat | null): Feature<Polygon> {
    const centerPoint = point([center!.lng, center!.lat]);
    return circle(centerPoint, this.circleRadius, {
      units: 'miles',
    });
  }

  private getCircleHighlightLayer(geometry: any): FillLayer {
    // Use this layer exclusively for highlighting the circle selection, it is not about popup or charts rendering
    return {
      id: CIRCLE_HIGHLIGHT_LAYER,
      type: 'fill',
      source: {
        type: 'geojson',
        data: geometry,
      },
      paint: {
        'fill-color': '#add8e6',
        'fill-opacity': 0.6,
        'fill-outline-color': '#0000ff',
      },
    };
  }

  public setLastClickCenter(coordinates: LngLat): void {
    this.lastClickCenter = coordinates
  }
}
