import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IconRegistryService } from '../../../../shared/services/icon-registry.service';
import { SelectionToolService } from './selection-tool.service';
import { MatSliderChange } from '@angular/material/slider';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { Subject, takeUntil } from 'rxjs';
import { ActiveToolService, MapTools } from '../../../active-tool.service';
import { ArbitraryCellService } from '../../services/arbitrary-cell.service';

@Component({
    selector: 'app-selection-tool',
    templateUrl: './selection-tool.component.html',
    styleUrls: [
        './selection-tool.component.scss',
        '../../../../app.component.scss',
    ],
})
export class SelectionToolComponent implements OnInit, OnDestroy {
    @ViewChild('toggleButton') toggleButton!: MatButtonToggle;

    private readonly destroy$: Subject<boolean> = new Subject<boolean>();

    public isCircleSelectionActive!: boolean;

    public circleRadius: number = 10;
    public isSliderActive = false;

    constructor(
        public selectionService: SelectionToolService,
        private iconRegistry: IconRegistryService,
        private activeToolService: ActiveToolService,
        public combinedCellService: ArbitraryCellService
    ) {
        this.iconRegistry.initSelectionToolIcons();
    }

    ngOnInit(): void {
        this.isCircleSelectionActive =
            this.selectionService.circleSelectionStatus;

        this.activeToolService.activeTool
            .pipe(takeUntil(this.destroy$))
            .subscribe((activeTool) => {
                if (activeTool === MapTools.CIRCLE_SELECTION_TOOL) {
                    this.isSliderActive = true;
                    this.isCircleSelectionActive = true;
                    this.selectionService.enableCircleSelection();
                    this.toggleButton.checked = true;
                } else {
                    this.isSliderActive = false;
                    this.isCircleSelectionActive = false;
                    if (this.toggleButton) {
                        this.toggleButton.checked = false;
                        this.selectionService.disable();
                    }
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    public handleRequestCombinedCell(): void {
        this.combinedCellService.handleArbitraryCellCreationAndIndex(
            this.selectionService.lastCircleGeometry!
        );
        this.selectionService.disableCircleSelection();
    }

    public handleSelectNewLocation(): void {
        this.toggleRadiusSelection(false);
        this.toggleRadiusSelection(true);
    }

    changeSelectionRadius(event: MatSliderChange): void {
        this.circleRadius = event.source.value;
        this.selectionService.changeSelectionRadius(event.source.value);
    }

    toggleRadiusSelection(checked: boolean): void {
        this.isCircleSelectionActive = checked;
        if (checked) {
            this.activeToolService.activeTool.next(
                MapTools.CIRCLE_SELECTION_TOOL
            );
        } else {
            this.activeToolService.activeTool.next(MapTools.NONE);
        }
    }
}
