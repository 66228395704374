<div class="layers-info-container" *ngIf="activeMenuGroup === MAPS_GROUP">
  <div class="layers-info-title">
    <button (click)="isSpecialCareVisible = !isSpecialCareVisible" mat-icon-button>
      <mat-icon
        [ngClass]="{ 'opened-arrow-icon': isSpecialCareVisible }"
        class="arrow-right-icon"
        svgIcon="arrow_right"
      >
      </mat-icon>
    </button>Upcoming: {{upcomingGroupData.title!}}
  </div>
  <div class="charts-block toggle-wrapper">


      <app-chart-warning
        [isToggleActive]="isSpecialCareVisible"
        [comingSoon]="true"
        [customText]="upcomingGroupData.warningDisclaimer!">
      </app-chart-warning>

  </div>

</div>
