import { Component, Input, OnInit } from '@angular/core';
import { Industries } from '../../../../shared/util/industries';
import { BreakpointObserverService } from '../../../../shared/services/breakpoint-observer.service';
import { MatDialog } from '@angular/material/dialog';
import { SwitchToDesktopModalComponent } from '../../../../shared/components/switch-to-desktop-modal/switch-to-desktop-modal.component';
import { MenuGroupData } from './menu-group/menu-group.component';
import { LayersMenuService } from './layers-menu.service';
import { UpcomingGroupData } from './upcoming-group/upcoming-group.component';
import {
  CHARTS_GROUP,
  FILTERS_GROUP,
  MAPS_GROUP,
  MenuGroupType,
  PLACES_GROUP,
} from '../../../map.component';
import { FormControl, FormGroup } from '@angular/forms';
import { UserAccessService } from '../../../../user/access/user-access.service';

@Component({
  selector: 'app-layers-menu',
  templateUrl: './layers-menu.component.html',
  styleUrls: ['./layers-menu.component.scss', '../../../../app.component.scss'],
})
export class LayersMenuComponent implements OnInit {
  @Input() activeMenuGroup!: MenuGroupType;
  @Input() industries!: any;
  @Input() isDropdownActive: boolean = false;
  @Input() industryDropdown = 'inner-industry';

  public form = new FormGroup({
    activeLayer: new FormControl(''),
  });

  public readonly upcomingGroupsData: UpcomingGroupData[] = [];

  constructor(
    public breakpointObserverService: BreakpointObserverService,
    public accessService: UserAccessService,
    public layersMenuService: LayersMenuService,
    private dialog: MatDialog
  ) {
    this.industries = Industries;
  }

  ngOnInit(): void {}

  public onGraphsClick(): void {
    if (this.breakpointObserverService.isMobile.value) {
      this.dialog.open(SwitchToDesktopModalComponent, {
        maxWidth: '330px',
        disableClose: true,
        data: { title: 'Mobile version', closable: true },
      });
    }
  }

  public identify(index: number, item: MenuGroupData): string {
    return item.title;
  }

  public identifyUpcoming(index: number, item: UpcomingGroupData): string {
    return item.title;
  }

  protected readonly MAPS_GROUP = MAPS_GROUP;
  protected readonly CHARTS_GROUP = CHARTS_GROUP;
  protected readonly PLACES_GROUP = PLACES_GROUP;
  protected readonly FILTERS_GROUP = FILTERS_GROUP;
}
