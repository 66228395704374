import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class IconRegistryService {
    constructor(
        private iconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {}

    initRightMenuIcons(): void {
        this.iconRegistry.addSvgIcon(
            'arrow_right',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/arr_right_icon.svg'
            )
        );
    }

    initLevelSelectionIcons(): void {
        this.iconRegistry.addSvgIcon(
            'levelSelectionActive',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/toolsActive.svg'
            )
        );
    }

    initMapIcons(): void {
      this.iconRegistry.addSvgIcon(
        'indexesGroup',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '/assets/icons/IndexesGroupIcon.svg'
        )
      );

      this.iconRegistry.addSvgIcon(
        'chartsGroup',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '/assets/icons/ChartsGroupIcon.svg'
        )
      );

      this.iconRegistry.addSvgIcon(
        'pointsGroup',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '/assets/icons/pointsGroup.svg'
        )
      );

      this.iconRegistry.addSvgIcon(
        'tools',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '/assets/icons/tools.svg'
        )
      );

      this.iconRegistry.addSvgIcon(
        'cancel',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '/assets/icons/Cancel.svg'
        )
      );

      this.iconRegistry.addSvgIcon(
        'download',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '/assets/icons/Download.svg'
        )
      );

      this.iconRegistry.addSvgIcon(
        'share',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '/assets/icons/Share.svg'
        )
      );

        this.iconRegistry.addSvgIcon(
            'rightMenuCloseBtn',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/rightMenuCloseBtn.svg'
            )
        );

        this.iconRegistry.addSvgIcon(
            'searchIcon',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/search.svg'
            )
        );
    }

    initSelectionToolIcons(): void {
        this.iconRegistry.addSvgIcon(
            'radiusButtonActivate',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/radius-button-activate.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'radiusButtonCancel',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/radius-button-cancel.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'sliderButtonCancel',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/slider-button-cancel.svg'
            )
        );
    }

    initNavigationIcons(): void {
        this.iconRegistry.addSvgIcon(
            'logo',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/navigation-menu/logo.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'home',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/navigation-menu/home.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'map',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/navigation-menu/map.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'help',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/navigation-menu/help.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'user',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/navigation-menu/user.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
            'report',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/navigation-menu/report.svg'
            )
        );  this.iconRegistry.addSvgIcon(
            'logIn',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/navigation-menu/LogIn.svg'
            )
        );
        this.iconRegistry.addSvgIcon(
          'adminPanel',
          this.domSanitizer.bypassSecurityTrustResourceUrl(
            '/assets/icons/navigation-menu/AdminPanel.svg'
          )
        )
        this.iconRegistry.addSvgIcon(
            'pointer',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/navigation-menu/select_pointer.svg'
            )
        );
    }
}
