<div class="map-menu-container">
  <div class="groups-buttons-container-mobile">
    <button
      *ngIf="
                (breakpointObserverService.isMobile | async) ||
                (activeMenuGroup === MAPS_GROUP || activeMenuGroup === PLACES_GROUP)
            "
    >
      {{ activeMenuGroup === MAPS_GROUP ? 'Maps' : 'Places'}}
    </button>
    <span *ngIf="breakpointObserverService.isMobile | async"> / </span>
    <button
      (click)="onGraphsClick()"
      *ngIf="
                (breakpointObserverService.isMobile | async) ||
                activeMenuGroup === CHARTS_GROUP
            "
      [attr.data-cy]="'charts-button'"
    >
      Charts
    </button>
  </div>

  <div class="menu-element-container">
    <form [formGroup]="form">
      <mat-radio-group formControlName="activeLayer">
        <app-menu-group *ngFor="let groupData of layersMenuService.groupsData; trackBy:identify"
                        [groupData]="groupData"
                        [activeMenuGroup]="activeMenuGroup"
                        [form]="form"
                        [isPaidPlan]="accessService.isPaidPlanActive | async"
                        class="map-menu-element"
                        [attr.data-cy]="groupData.e2eName"
        ></app-menu-group>

        <app-upcoming-group
          *ngFor="let groupData of upcomingGroupsData; trackBy:identifyUpcoming"
          [upcomingGroupData]="groupData"
          [activeMenuGroup]="activeMenuGroup"
          class="map-menu-element"
        >

        </app-upcoming-group>
      </mat-radio-group>
    </form>

    <app-filters-menu *ngIf="activeMenuGroup === FILTERS_GROUP"></app-filters-menu>

  </div>
</div>
