
<div class="container">
  <div class="sign-up-type-window main-window" [attr.data-cy]="'sing-up-type-window'">

    <div
      (click)="goToSigninMenu()"
      class="menu-close-btn"
    >
      <img src="../../../assets/icons/rightMenuCloseBtn.svg" alt="exit">
    </div>


    <div class="menu-close-btn-mobile" (click)="goToSigninMenu()">
      <img src="../../../assets/icons/Cancel.svg" alt="exit">
    </div>

    <h2>Create Map AI account</h2>

    <div class="buttons-container type-buttons-container">
      <button class="gradient-button"
              (click)="goToCreateIndividualMenu()"
              [attr.data-cy]="'register-individual-button'"
      >Individual</button>
      <button class="white-button-gradient" (click)="goToCreateEnterpriseForm()">
        Enterprise</button>
    </div>
    <span class="log-in-span">Already have an account? <a (click)="goToSigninMenu()" class="log-in-link" >Log in</a></span>

  </div>
</div>
