import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../authentication.service';

@Component({
  selector: 'app-registration-success-window',
  templateUrl: './registration-success-window.component.html',
  styleUrls: ['./registration-success-window.component.scss'],
})
export class RegistrationSuccessWindowComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.authService.refreshUserInfo();
  }

  public goToMap(): void {
    this.authService.redirectAfterAuth();
  }

  public goToSubscriptions(): void {
    this.router.navigate(['/user/subscription']);
  }
}
