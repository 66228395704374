import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MapBoxService} from "../mapbox/mapbox.service";
import {GeocoderSearchHistory, SearchGeocoderService} from "../../shared/services/search-geocoder.service";
import {environment} from "../../../environments/environment";
import * as mapboxglType from "mapbox-gl";
import * as MapboxGeocoderType from "@mapbox/mapbox-gl-geocoder";
import {BreakpointObserverService} from "../../shared/services/breakpoint-observer.service";

declare let mapboxgl: typeof mapboxglType;
declare let MapboxGeocoder: typeof MapboxGeocoderType;

@Component({
  selector: 'app-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss']
})
export class MapSearchComponent implements OnInit, OnChanges {
  @Output() searchClose = new EventEmitter<void>;
  @Output() searchOpen = new EventEmitter<void>

  @Input() isSearchInputActive: boolean = false

  private isMobile: boolean = false

  private readonly searchGeocoder: MapboxGeocoder = new MapboxGeocoder({
    accessToken: environment.mapbox.accessToken,
    mapboxgl: mapboxgl,
    placeholder: 'Map search',
    countries: 'us',
    reverseGeocode: true,
    clearAndBlurOnEsc: true,
  })

  constructor(
    public geocoderService: SearchGeocoderService,
    private mapboxService: MapBoxService,
    private breakpointObserverService: BreakpointObserverService
  ) { }

  ngOnInit(): void {
    this.breakpointObserverService.isMobile.subscribe(mobile => {
      this.isMobile = mobile
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isSearchInputActive']) {
      if (this.mapboxService.map && this.mapboxService.map.loaded()) {
        changes['isSearchInputActive'].currentValue
          ? this.geocoderService.addGeocoderSearch(this.mapboxService.map, this.searchGeocoder, this.searchClose)
          : this.geocoderService.removeGeocoderSearch(this.mapboxService.map, this.searchGeocoder)
      }
    }
  }

  public handleJumpToAddress(item: GeocoderSearchHistory): void {
    this.geocoderService.flyToAddress([item.lng, item.lat], this.mapboxService.map)
  }

  public handleRemove(e: MouseEvent, id: string): void {
    e.stopPropagation()
    this.geocoderService.removeSearchItemFromHistory(id, this.searchGeocoder)
  }

  public handleSearchOpenButton(): void {
    this.searchOpen.emit()
  }
}
