import {Pipe, PipeTransform} from '@angular/core';
import {UserAccessService} from "../../../../../user/access/user-access.service";
import {SubscriptionPlans} from "../../../../../user/user/user.model";
import {
  CHRONIC_DISEASES_GROUP_TITLE,
  HEALTH_GROUP_TITLE,
  HOUSING_GROUP_TITLE,
  POI_GROUP_TITLE,
  WEALTH_GROUP_TITLE
} from "../layers-menu.service";

@Pipe({
  name: 'groupTitleFormatter'
})
export class GroupTitleFormatterPipe implements PipeTransform {

  constructor(private accessService: UserAccessService) {
  }

  transform(title: string): string {
    const subscription = this.accessService.getUserPlan()

    // Change group titles for different subscriptions according to https://docs.google.com/spreadsheets/d/1CJ2uHd4AVTNmAIJzIdTHm9WY7MatiMLXAeDypBfmQ8Q/edit#gid=0
    if (subscription === SubscriptionPlans.ENTERPRISE && title === WEALTH_GROUP_TITLE) {
      return 'Income'
    } else if ((subscription === SubscriptionPlans.ENTERPRISE || subscription === SubscriptionPlans.REALTOR) && title === HOUSING_GROUP_TITLE) {
      return 'Real estate'
    } else if (subscription === SubscriptionPlans.ENTERPRISE && title === CHRONIC_DISEASES_GROUP_TITLE) {
      return 'Healthcare needs'
    } else if (subscription === SubscriptionPlans.ENTERPRISE && title === POI_GROUP_TITLE) {
      return 'Competition & POI'
    } else if (subscription === SubscriptionPlans.REALTOR && title === HEALTH_GROUP_TITLE) {
      return 'Health'
    } else if ((subscription === SubscriptionPlans.COMMUNITY_COMPASS || SubscriptionPlans.REALTOR) && title === POI_GROUP_TITLE) {
      return 'Nearby amenities'
    } else if (subscription === SubscriptionPlans.KNOW_YOUR_COUNTRY && title === POI_GROUP_TITLE) {
      return 'Amenities'
    }

    return title;
  }

}
