export const VARIABLE_TYPE = 'VARIABLE';
export const VARIABLE_TYPE_POPULATION = 'POPULATION';
export const AGE_FROM = 'FROM';
export const AGE_TO = 'TO';
export const SEX = 'SEX';
export const SEX_FEMALE = 'F';
export const SEX_MALE = 'M';

export const WORK_FULLTIME = 'FULL_TIME'
export const WORK = 'WORK'
export const WORK_OTHER = 'OTHER'
export const INCOME_FROM = 'INCOMEFROM'
export const INCOME_TO = 'INCOMETO'


/**
 * Custom semantic variable format for Map AI.
 * <p>
 * Represents a dictionary of key-value pairs in a single line.
 * Any __ separates dictionary items.
 * First _ separates key from value.
 * </p>
 * <p>
 * Examples:
 * VARIABLE_POPULATION__SEX_M__FROM_20__TO_22
 * means this dictionary:
 * <PRE>
 * {`VARIABLE`:`POPULATION`, `SEX`:`M`, `FROM`:`20`, `TO`:`22`}
 * </PRE>
 * <p>
 * Invented to parse age/sex census variables which by default look like
 * `B01001A_001E` or `SEX_BY_AGE_(WHITE_ALONE)_People_who_are_White_alone_Total_Male_Under_5_years`
 * which is not convenient to parse.
 * Keeping that format for census makes frontend easier to implement as well.
 */
export type MapAiSemanticVariable = string;

/**
 * Any object got from parsing MapAiSemanticVariable.
 */
export type MapAiSemanticVariableObject = any;

export function parseSemanticVariable(name: string): MapAiSemanticVariableObject {
  const res: any = {}

  name.split('__').forEach((item) => {
    // ...to take all symbols after _
    const [key, ...value] = item.split('_');
    if (key && value) {
      res[key] = tryExtractNumber(value.join('_'));
    }
  });

  return res;
}

function tryExtractNumber(str: string): Number | string {
  const match = str.match(/\d+/);
  if (match) {
    return Number(match[0]);
  } else {
    return str;
  }
}
