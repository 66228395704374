<h4 class="title">{{ title }}</h4>
<div class="popup-list" [attr.data-cy]="'levels-popup-list'">
  <ng-container *ngIf="!isSeeMoreActive">
    <!-- Show only activeLayer feature when isSeeMoreActive === false -->
    <p *ngIf="(properties | objectToKeyValue:activeLayer)[0] as firstFeature else notAvailable">
              <span *ngIf="(firstFeature | formatFeature: true) as formattedFeature ">
                        <span class="feature-content">
                          <span class="feature-name">{{ formattedFeature.formattedKey }}:</span>
                          <span class="feature-value" [attr.data-cy]="'levels-popup-list-value'">{{ formattedFeature.formattedValue }}</span>

                          <app-feature-tooltip
                            *ngIf="firstFeature.key === POPULATION && firstFeature.value < 5"
                            [fillColor]="'#f4d196'"
                            [strokeColor]="WHITE"
                            [opacity]="1"
                            [clickable]="false"
                            [tooltipText]="'Small population. Interpret demographic data carefully.'"
                            [tooltipPosition]="'right'"
                          ></app-feature-tooltip>
                        </span>
                        <span *ngIf="comparisonResults![firstFeature.key]" class="comparison-text">
                          {{ comparisonResults![firstFeature.key] }}
                        </span>
              </span>
    </p>
  </ng-container>
  <ng-container *ngIf="isSeeMoreActive">
    <!-- Show all features when isSeeMoreActive === true -->


    <ng-container *ngFor="let feature of properties | objectToKeyValue">
      <ng-container *ngIf="feature.key | isFeatureAccessible: true">

        <p *ngIf="(feature | formatFeature: true) as formattedFeature">
        <span class="feature-content" [ngClass]="activeLayer === feature.key ? 'selected-feature' : ''">
           <span class="feature-name">{{ formattedFeature.formattedKey }}:</span>
           <span class="feature-value">{{ formattedFeature.formattedValue }}</span>
            <app-feature-tooltip
              *ngIf="feature.key === POPULATION && feature.value < 5"
              [fillColor]="'#f4d196'"
              [strokeColor]="WHITE"
              [opacity]="1"
              [clickable]="false"
              [tooltipText]="'Small population. Interpret demographic data carefully.'"
              [tooltipPosition]="'right'"
            ></app-feature-tooltip>
        </span>


          <span *ngIf="comparisonResults![feature.key]" class="comparison-text">
                  {{ comparisonResults![feature.key] }}
        </span>
        </p>
      </ng-container>
    </ng-container>
  </ng-container>
</div>


<ng-template #notAvailable>
  <p>
    <span>
      <span class="feature-content">
       <span class="feature-name">{{ activeLayer | LayerNameFormatPipe }}:</span>
       <span class="feature-value">N/A</span>
        <app-feature-tooltip
          [fillColor]="'#f4d196'"
          [strokeColor]="WHITE"
          [opacity]="1"
          [clickable]="false"
          [tooltipText]='(activeLayer | LayerNameFormatPipe) + " is not available here"'
          [tooltipPosition]="'right'"
        ></app-feature-tooltip>
      </span>
    </span>
  </p>
</ng-template>
