<div class="common-colorscale-container">
  <div class="content-wrapper">
    <div class="title-wrapper">
      <h4>{{currentLayer}}</h4>
<!--      <button class="change-link" *ngIf="!isMobile">Change</button>-->
    </div>
    <app-colorscale [colors]="colorScaleService.colorScaleColors"
                    [isCommonColorScale]="true"
                    class="colorscale"
    ></app-colorscale>
    <button class="common-colorscale-toggle-button" [attr.data-cy]="'common-colorscale-toggle-button'" *ngIf="isMobile" (click)="emitOpenMenuMobile()">
      <img src="assets/icons/dropdown-arrow.svg" alt="close data menu">
    </button>
  </div>

</div>
