import {Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, OnDestroy {
  @Input() images: string[] = [];
  @Input() imagesAlt: string[] = [];

  @Input() intervalMs: number = 10000;
  currentIndex: number = 0;
  interval: any;

  constructor() { }

  ngOnInit(): void {
    this.startAutoSlide();
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  startAutoSlide(): void {
    this.interval = setInterval(() => {
      this.nextImage();
    }, this.intervalMs);
  }

  nextImage(): void {
    this.currentIndex = (this.currentIndex + 1) % this.images.length;
  }

  selectImage(index: number): void {
    this.currentIndex = index;
    this.resetAutoSlide();
  }

  resetAutoSlide(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.startAutoSlide();
  }
}
