import { Injectable } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import centroid from '@turf/centroid';

@Injectable({
  providedIn: 'root',
})
export class MapBoxService {
  // map should be initialized after the html container is created,
  // so it is done in ngOnInit instead of constructor which requires adding !
  map!: mapboxgl.Map;
  reportsMap!: mapboxgl.Map;

  public getFeatureCenter(feature: MapboxGeoJSONFeature): any {
    //@ts-ignore
    const center = centroid(feature).geometry.coordinates;
    return center;
  }

  public simulateMapClick(center: number[]): void {
    const centerPoint = this.map.project([center[0], center[1]]);

    const clickEvent = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      clientX: centerPoint.x,
      clientY: centerPoint.y,
    });

    // Simulate click event to select feature on screen
    this.map.getCanvas().dispatchEvent(clickEvent);
  }
}
