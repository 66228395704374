import {
    PopulationSample,
    PopulationSampleAttributes,
    PopulationSamples,
    Sex,
} from './population';
import {
    AgeSexByYear,
    AgeSexDataJSON,
    MostGranularSex,
} from '../../map/menu/right-menu/layers-menu/population-menu/population.service';
import {
    AGE_FROM,
    AGE_TO,
    MapAiSemanticVariable,
    MapAiSemanticVariableObject,
    parseSemanticVariable,
    SEX,
    SEX_FEMALE,
    SEX_MALE,
} from './map-ai-semantic-variable-object';
import { AgeRange, YearRange } from './range';

export function mapAgeSexDistribution(
    backendAgeSex: AgeSexDataJSON
): PopulationSamples {

    const samples: PopulationSample[] = backendAgeSex.ageSexByYears.flatMap(
        mapAgeSexDistributionByYear
    );

    return new PopulationSamples(samples);
}

export function mapAgeSexDistributionByYear(ageSex: AgeSexByYear): PopulationSample[]{
  return mapMostGranularSamples(ageSex.info.distribution, ageSex.year)
}

export function mapMostGranularSamples(samples: MostGranularSex, year: number): PopulationSample[] {
  const res: PopulationSample[] = [];

  for (let key in samples) {
    res.push(mapMostGranularSample(key, samples[key], year));
  }
  return res;
}

export function mapMostGranularSample(key: MapAiSemanticVariable,
                                      population: number,
                                      year: number): PopulationSample {
  const parsedVariable: MapAiSemanticVariableObject = parseSemanticVariable(key);
  const sex = mapSex(parsedVariable);
  const ageRange = mapAgeRange(parsedVariable);
  const yearRange = YearRange.ofSingleYear(year);

  const sample: PopulationSampleAttributes =
    new PopulationSampleAttributes( sex, ageRange, yearRange, );
  return new PopulationSample(sample, population);
}

export function mapSex(obj: MapAiSemanticVariableObject): Sex {
  if (!obj[SEX]) {
    return Sex.ANY;
  }
  if (obj[SEX] == SEX_FEMALE) {
    return Sex.FEMALE;
  }
  if (obj[SEX] === SEX_MALE) {
    return Sex.MALE;
  }

  throw new Error('Can\'t parse sex from ' + JSON.stringify(obj));
}

export function mapAgeRange(obj: MapAiSemanticVariableObject): AgeRange {
  if (!obj[AGE_FROM] && !obj[AGE_TO]) {
    return AgeRange.ofAnyAge();
  }
  const from = obj[AGE_FROM] || 0;
  const to = obj[AGE_TO] + 1 || AgeRange.MAX_AGE;

  return new AgeRange(from, to);
}
