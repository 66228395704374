import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss',
    '../../app.component.scss',
    '../user.shared.scss'],

})
export class SignUpComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public goToCreateEnterpriseForm(): void {
    this.router.navigate(['/sign-up/enterprise'])
  }

  public goToCreateIndividualMenu(): void {
    this.router.navigate(['/sign-up/individual'])
  }

  public goToSigninMenu(): void {
    this.router.navigate(['/sign-in'])
  }


}
