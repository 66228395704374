import {groupByLambda, onlyUnique} from '../util/arrays';
import {IncomeRange, YearRange} from './range';

export enum Sex {
  MALE = 'M',
  FEMALE = 'F',
  ANY = 'ANY',
}


export enum Work {
  FULLTIME = 'FULL',
  OTHER = 'OTHER',
  ANY = 'ANY'
}

/**
 * Attributes for samples of populations.
 * Shows characteristics but not the population itself.
 */
export class IncomeSampleAttributes {
  /**
   * Males/Females or Both. Unknown should be considered as both.
   */
  readonly sex: Sex;
  readonly incomeRange: IncomeRange;
  readonly work: Work

  /**
   * Single year represents as range with single year.
   */
  readonly yearRange: YearRange;

  constructor(sex: Sex, incomeRange: IncomeRange, yearRange: YearRange, work: Work) {
    this.sex = sex;
    this.incomeRange = incomeRange;
    this.yearRange = yearRange;
    this.work = work
  }

  public equals(other: IncomeSampleAttributes): boolean {
    return JSON.stringify(this) === JSON.stringify(other);
  }

  public withIncomeRange(incomeRange: IncomeRange): IncomeSampleAttributes {
    return new IncomeSampleAttributes(
      this.sex,
      incomeRange,
      this.yearRange,
      this.work
    );
  }
}

/**
 * Sample of population satisfying given attributes.
 */
export class IncomeSample {
  public readonly attributes: IncomeSampleAttributes;

  public readonly population: number;

  public withIncomeRange(incomeRange: IncomeRange): IncomeSample {
    return new IncomeSample(
      this.attributes.withIncomeRange(incomeRange),
      this.population
    );
  }

  get yearRange(): YearRange {
    return this.attributes.yearRange;
  }

  get sex(): Sex {
    return this.attributes.sex;
  }

  get work(): Work {
    return this.attributes.work
  }

  get incomeRange(): IncomeRange {
    return this.attributes.incomeRange;
  }

  public constructor(
    attributes: IncomeSampleAttributes,
    population: number
  ) {
    this.attributes = attributes;
    this.population = population;
  }

  public hasSameAttributes(other: IncomeSample): boolean {
    return this.attributes.equals(other.attributes);
  }

  /**
   * Sum population for samples having same attributes.
   * ex, when aggregating by income, different income ranges are forces set to single
   * to sum up.
   * If samples with different attributes are passed, exception is thrown.
   */
  public summed(other: IncomeSample) {
    if (this.hasSameAttributes(other)) {
      return new IncomeSample(
        this.attributes,
        this.population + other.population
      );
    } else {
      throw new Error(
        "Can't unite samples with different attributes: " +
        JSON.stringify(this) +
        ' ' +
        JSON.stringify(other)
      );
    }
  }
}

/**
 * Populations with attributes.
 * Wrapper for an array of {@link IncomeSample} for convenient processing.
 * This class uses
 * <a href="https://www.oreilly.com/library/view/java-8-pocket/9781491901083/apa.html">Fluent API</a>
 * <a href="https://en.wikipedia.org/wiki/Fluent_interface">Fluent API 2</a>.
 */
export class IncomeSamples {
  private readonly samples: IncomeSample[];

  public constructor(samples: IncomeSample[]) {
    this.samples = samples;
  }

  public filter(
    predicate: (sample: IncomeSample) => boolean
  ): IncomeSamples {
    return new IncomeSamples(this.samples.filter(predicate));
  }

  public filterByYearRange(yearRange: YearRange) {
    return this.filter((pop) =>
      yearRange.containsWholeRange(pop.yearRange)
    );
  }

  /**
   * @return samples with exactly same sex as given.
   */
  public filterSex(sexToRemain: Sex): IncomeSamples {
    return new IncomeSamples(
      this.samples.filter((s) => s.sex === sexToRemain)
    );
  }

  public filterWork(workToRemain: Work): IncomeSamples {
    return new IncomeSamples(
      this.samples.filter((s) => s.work === workToRemain)
    )
  }

  public sortedByYear(): IncomeSamples {
    return new IncomeSamples(
      this.samples.sort(
        (a, b) => a.yearRange.fromInclusive - b.yearRange.fromInclusive
      )
    );
  }

  public sortedByIncome(): IncomeSamples {
    return new IncomeSamples(
      this.samples.sort(
        (a, b) => a.incomeRange.fromInclusive - b.incomeRange.fromInclusive
      )
    );
  }

  public populations(): number[] {
    return this.samples.map((s) => Math.round(s.population));
  }

  /**
   * Total population of all samples.
   */
  public sum() {
    return this.samples.map((s) => s.population).reduce((a, b) => a + b, 0);
  }

  public byYears(): IncomeSamplesByYears {
    const map = groupByLambda(this.samples, (s) => s.yearRange.singleYear);
    return new IncomeSamplesByYears(map);
  }

  /**
   * @return sample where population is summed up inside each of given ranges.
   * NB! If males and females (or any property) are there for same ranges,
   * they are summed up.
   * If one wants to have only one sex/age/other properties,
   * use filter first like {@link IncomeSamples#filterSex}.
   *
   * @param ranges range to sum up population inside.
   * Caller is responsible for ranges to not be overlapped.
   *
   * This method is basically scum.
   * It works for our single case.
   * If one needs more of it, make sure it works properly.
   */

  public sumInsideIncomeRanges(ranges: IncomeRange[]): IncomeSamples {
    const res = [];

    for (let incomeRange of ranges) {


      const summedSample = IncomeSamples.sumSamples(
        this.samples
          .flatMap((s) => this.distributeOverIncomeRanges(s, ranges))
          .filter((s) => incomeRange.containsWholeRange(s.incomeRange))
          .map((s) =>  s.withIncomeRange(incomeRange))
      );

      if (summedSample !== undefined) {
        res.push(summedSample);
      }
    }

    return new IncomeSamples(res);
  }

  private distributeOverIncomeRanges(sample: IncomeSample, ranges: IncomeRange[]): IncomeSample[] {
    const incomeDistributionCoefficients = new Map([
      [150000, 0.5],
      [200000, 0.3],
      [IncomeRange.MAX_INCOME, 0.2]
    ]);

    // Check if the income sample falls within the range of 100,000 to MAX_INCOME
    if (sample.incomeRange.fromInclusive === 100000 && sample.incomeRange.toExclusive === IncomeRange.MAX_INCOME) {
      const higherRanges = ranges.filter(range => range.fromInclusive >= 100000);

      // Distribute the population of this sample over these higher ranges
      return higherRanges.map(range => {
        // Use available coefficient or default
        const distributionCoefficient = incomeDistributionCoefficients.get(range.toExclusive) || (1 / higherRanges.length);
        const populationForRange = sample.population * distributionCoefficient;

        const attributes = new IncomeSampleAttributes(
          sample.attributes.sex,
          range,
          sample.attributes.yearRange,
          sample.attributes.work
        );

        return new IncomeSample(attributes, populationForRange);
      });
    }

    return [sample];
  }

  private static sumSamples(
    samples: IncomeSample[]
  ): IncomeSample | undefined {
    if (samples.length === 0) {
      return undefined;
    }
    return samples.reduce((s, o) => s.summed(o));
  }

  public uniqueIncomeRangesStrings(): string[] {
    return onlyUnique(
      this.samples
        .map((s) => s.incomeRange)
        .sort((a, b) => a.fromInclusive - b.fromInclusive)
        .map((s) => s.toInclusiveString())
    );
  }
}

export class IncomeSamplesByYears {
  private readonly incomeByYears: Map<number, IncomeSample[]>;

  constructor(incomeByYears: Map<number, IncomeSample[]>) {
    this.incomeByYears = incomeByYears;
  }

  public asArray(): Array<[number, IncomeSamples]> {
    const array: Array<[number, IncomeSamples]> = [];

    for (let [key, value] of this.incomeByYears.entries()) {
      array.push([key, new IncomeSamples(value)]);
    }
    return array.sort((a, b) => a[0] - b[0]);
  }
}
