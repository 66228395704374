import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {UserModule} from './user/user.module';
import {SharedModule} from './shared/shared.module';
import {MapModule} from './map/map.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthInterceptor} from './user/auth-interceptor';
import {Router} from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import {ToastrModule} from 'ngx-toastr';
import {DatePipe} from "@angular/common";
import {ErrorInterceptor} from "./shared/api/error.interceptor";

//prettier-ignore
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        UserModule,
        SharedModule,
        MapModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
    ],
  providers: [{
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler(),
  }, {
    provide: Sentry.TraceService,
    deps: [Router],
  },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    DatePipe
  ],
    bootstrap: [AppComponent],
})
export class AppModule {}
