
<div class="popup-container" [ngClass]="cssClass">

  <span class="title">{{title}}</span>
    <p class="text-content">
      {{textContent}}
    </p>

  <div class="buttons-container">
    <button mat-button class="close-button" (click)="handleClose()">Got it</button>

    <button class="never-show-tips-button" (click)="handleDontShowAgain()" *ngIf="closable">Skip guide</button>
  </div>
</div>
<div class="arrow-pointer" [ngClass]="cssClass"></div>
