
<div class="modal">
  <button *ngIf="!disableClose" class="close-button" (click)="close()"><img src="assets/icons/Cancel.svg" alt="close window"></button>
  <h3 *ngIf="headerText">{{headerText}}</h3>
  <p *ngIf="subheaderText" style="margin-bottom: -10px">{{subheaderText}}</p>
  <p *ngIf="mainText"> {{mainText}}
    <a *ngIf="linkText" (click)="goToLink()">{{linkText}}.</a>
  </p>
  <button *ngIf="buttonText" class="gradient-button" (click)="goToButton()">{{buttonText}}</button>
  <div class="disclaimer-text-container" *ngIf="disclaimerText">
    <button class="disclaimer-button" (click)="isDisclaimerOpened= !isDisclaimerOpened">Disclaimer</button>
    <p *ngIf="isDisclaimerOpened">{{disclaimerText}}</p>
    <button *ngIf="isDisclaimerOpened" (click)="isDisclaimerOpened= false" class="disclaimer-button-hide">Hide</button>
  </div>

</div>

