<div class="favorites-container" xmlns="http://www.w3.org/1999/html">
    <button
      (click)="handleOpenFavorites()"
      *ngIf="!isFavoritesVisible"
      class="favorites-toggle-button"
    >
      <app-favorite-icon
        strokeColor="#a3a3a3"
        fillColor="transparent"
        strokeWidth="1px"
      ></app-favorite-icon>
    </button>

  <div class="favorites-dropdown-wrapper" *ngIf="isFavoritesVisible" (click)="toggleDropdown()">
    Favorites
    <button class="cancel" (click)="handleCloseFavorites($event)">
      <mat-icon svgIcon="cancel"></mat-icon>
    </button>
  </div>

  <div class="dropdown-content" *ngIf="isDropdownOpened && isFavoritesVisible">
    <div class="scrollable-content" *ngIf="favoritesService.favoritePlaces.length > 0; else noFavorites">
      <div class="cell-content" *ngFor="let item of favoritesService.favoritePlaces">
        <p class="cell-text" (click)="handleMoveToFavoriteCell(item)">
          <span class="cell-title">{{item.title}}</span>
          <span class="cell-description">{{item.description}}</span>
        </p>

        <button (click)="handleRemoveFavorite(item.geoId, item.cellType)" (mouseover)="hoveredId = item.geoId" (mouseout)="hoveredId = ''">
          <app-favorite-icon
            height="12px"
            width="12px"
            strokeColor="#CA0018"
            [fillColor]="hoveredId === item.geoId ? '#fff' : '#CA0018'"
          ></app-favorite-icon> </button>
      </div>
    </div>

    </div>
</div>

<ng-template #noFavorites>
  <div class="cell-content">
    <p class="cell-text">
      <span class="cell-description">There's no favorite cells yet, but you can add them by clicking <app-favorite-icon
        height="12px"
        width="12px"
        strokeColor="#CA0018"
        fillColor="#fff"
      ></app-favorite-icon> in the popup </span>
    </p>


  </div>
</ng-template>
