import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { ACCESS_TOKEN, AuthenticationService } from './authentication.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  private refreshTokenRetryCount = 0;
  private maxRefreshTokenRetries = 3;

  constructor(
    private authService: AuthenticationService,
    private toast: ToastrService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this.authService.hasAccessToken()) {
      const accessToken = this.authService.getTokens()[ACCESS_TOKEN];

      const authRequest = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + accessToken),
      });

      return next.handle(authRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 && this.authService.hasAccessToken()) {
            return this.handle401Error(authRequest, next);
          } else if (error.status === 429) {
            this.toast.warning('Too many request, please try later');
          }

          return throwError(() => error);
        })
      );
    } else {
      return next.handle(req);
    }
  }

  private handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenRetryCount = 0;
      this.refreshTokenSubject.next(null);

      return this.refreshTokenWithLimit().pipe(
        switchMap((accessToken) => {
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(accessToken);
          return next.handle(this.addToken(req, accessToken)); // Retry the original request
        }),
        catchError((err) => {
          this.refreshTokenInProgress = false;
          this.authService.logout(); // Log out on refresh failure
          return throwError(() => err);
        })
      );
    } else {
      // Queue the request until the token refresh completes
      return this.refreshTokenSubject.pipe(
        filter((token): token is string => token !== null),
        take(1), // Only take the first valid token emitted
        switchMap((accessToken) => {
          // Retry the original request with the new token
          return next.handle(this.addToken(req, accessToken));
        })
      );
    }
  }

  private refreshTokenWithLimit(): Observable<string> {
    if (this.refreshTokenRetryCount >= this.maxRefreshTokenRetries) {
      console.error('Max refresh attempts reached');
      return throwError(() => new Error('Max refresh attempts reached'));
    }

    this.refreshTokenRetryCount++;

    return this.authService.refreshToken().pipe(
      map((response) => {
        return response.object.tokens.access;
      }),
      tap(() => {
        this.refreshTokenRetryCount = 0; // Reset retry count on successful refresh
      }),
      catchError((err) => {
        if (this.refreshTokenRetryCount >= this.maxRefreshTokenRetries) {
          this.refreshTokenRetryCount = 0;
          this.authService.logout(); // Log out after exceeding max retries
        }
        return throwError(() => err);
      })
    );
  }

  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    // Clone the request and add the new token
    return req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + token),
    });
  }
}
