import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { JsonResponse } from '../shared/api/backend-config';
import { ARBITRARY_CELL } from '../map/mapbox/services/layer-store.service';
import { ReportTypes } from '../user/user/user.model';

export interface RequestPricesResponse {
  paymentUrl: string;
  totalPriceCents: number;
  reports: {
    reportType: ReportTypes;
    quantity: number;
    priceCents: number;
  }[];
}

export interface RequestPricesBody {
  reports: {
    location: {
      cellId: string,
      cellType: string
      name: string,
    },
    type: string
  }[]
}

export interface RequestedReport {
  url?: string,
  name: string,
  orderedAt: string,
  type: string,
  status: string
}

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  public lastCreatedCellId: string | null = null
  public lastSelectedReportType: ReportTypes | null = null
  public pricesData: RequestPricesResponse | null = null

  public selectedLocation: string | null = null
  public selectedRadius: number | null = null


  constructor(private http: HttpClient) {
  }

  public getRequestedReports(): Observable<JsonResponse<RequestedReport[]>> {
    return this.http.get<JsonResponse<RequestedReport[]>>(`${environment.apiUrl}report/my`)
  }

  public requestPrices(): Observable<JsonResponse<RequestPricesResponse>> {
    const body = this.getRequestPricesBody()
    return this.http.post<JsonResponse<RequestPricesResponse>>(`${environment.apiUrl}report/order`, body)
  }

  public getRequestPricesBody(): RequestPricesBody {
    return {
      reports: [
        {
          location: {
            cellId: this.lastCreatedCellId!,
            cellType: ARBITRARY_CELL,
            name: this.selectedLocation! + ', '+ this.selectedRadius + ' miles',
          },
          type: this.lastSelectedReportType!
        }
      ]
    }
  }

  public setSelectedReportType(reportType: ReportTypes): void {
    this.lastSelectedReportType = reportType
  }

  public resetData(): void {
    this.lastCreatedCellId = null
    this.pricesData = null
  }
}
