import {AfterViewInit, Component, NgZone, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthenticationService, ENTERPRISE, INDIVIDUAL, RegisterData} from '../../authentication.service';
import {JsonResponse} from '../../../shared/api/backend-config';
import {passwordValidator} from '../passwordValidation';
import {AuthDataJSON, EMAIL_KEY} from '../../sign-in/sign-in.component';
import {catchError, of} from 'rxjs';
import {GoogleAuth0Response, renderGoogleSignIn} from '../../oauth';
import {inputAnimations} from "../../../shared/util/inputAnimations";

export interface AuthDataRegisterJSON {
  access: string,
  refresh: string
}


@Component({
  selector: 'app-register-individual',
  templateUrl: './register-individual.component.html',
  styleUrls: ['./register-individual.component.scss',
    '../../../app.component.scss',
  '../../user.shared.scss'],
  animations: inputAnimations
})
export class RegisterIndividualComponent implements OnInit, AfterViewInit {
  public emailShakeState: string = 'inactive';
  public passwordShakeState: string = 'inactive';
  public formSubmitted = false;
  public hidePassword = true;

  public emailOccupiedErrorStatus: boolean = false

  public signInForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    password: ['', [Validators.required, passwordValidator]],
    passwordConfirm: ['', [Validators.required]],
    companyName: ['']
  })


  constructor(private fb: FormBuilder,
              private router: Router,
              private authService: AuthenticationService,
              private ngZone: NgZone) {
  }

  ngOnInit(): void {
    if (sessionStorage.getItem(EMAIL_KEY)) {
      this.signInForm.get('email')?.setValue(sessionStorage.getItem(EMAIL_KEY))
    }
  }

  ngAfterViewInit() {
    renderGoogleSignIn('google-sign-in-register', this.handleCredentialResponse.bind(this));
  }

  private handleCredentialResponse(response: GoogleAuth0Response) {
    this.authService.loginGoogle(response)
      .subscribe((data: JsonResponse<AuthDataJSON>) => {
        if (!!data.object.tokens.access){
          this.ngZone.run(() => {
            sessionStorage.removeItem(EMAIL_KEY)
            this.router.navigate(['/']);
          });
        }
      })
  }

  public onSubmit(): void {
    this.formSubmitted = true;

    if (!this.signInForm.invalid && this.signInForm.value.password === this.signInForm.value.passwordConfirm) {
      const body: RegisterData = {
        email: this.signInForm.value.email!,
        firstName: this.signInForm.value.firstName!,
        lastName: this.signInForm.value.lastName!,
        password: this.signInForm.value.password!,
        companyName: this.signInForm.value.companyName!,
        category: this.isEnterpriseRoute() ? ENTERPRISE : INDIVIDUAL
      };

      this.authService.register(body)
        .pipe(
          catchError(error => {
            if (error.error.errorMessage === `Email ${this.signInForm.get('email')?.value} already used`) {
              this.emailOccupiedErrorStatus = true
              console.error(error.error.errorMessage);
              return of (null)
            }
            console.error(error)
            return of(null);
          })
        )
        .subscribe((data: JsonResponse<AuthDataRegisterJSON> | null) => {
          if (data && !!data.object.access) {
            sessionStorage.removeItem(EMAIL_KEY)
            this.router.navigate(['/sign-up/success']);
          }
        });
    }
  }

  public isInputStatusInvalid(inputName: string) {
    return this.signInForm.get(inputName)?.invalid && (this.signInForm.get(inputName)?.touched || this.formSubmitted || this.signInForm.get(inputName)?.dirty);
  }

  public closeSignInMenu(): void {
    this.router.navigate(['/'])
  }


  public checkInputs(): void {
    if (this.isInputStatusInvalid('email')) {
      this.emailShakeState = 'active';
    }
    if (this.isInputStatusInvalid('password')) {
      this.passwordShakeState = 'active';
    }
  }

  public goToSigninMenu(): void {
    this.router.navigate(['/sign-in'])
  }

  public isEnterpriseRoute(): boolean {
    return this.router.url.includes('sign-up/enterprise');
  }

  public doesPasswordMatch(): boolean {
    return this.signInForm.get('passwordConfirm')?.touched ? this.signInForm.get('password')?.value === this.signInForm.get('passwordConfirm')?.value : true
  }
}
