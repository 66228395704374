import { Injectable } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { JsonResponse } from '../../../../../shared/api/backend-config';
import { CrimeRateByYearsResponse } from '../points.service';
import { PopupService } from '../../../../mapbox/services/popup.service';
import { MapHttpService } from '../../../../mapbox/services/map-http.service';
import { MapBoxService } from '../../../../mapbox/mapbox.service';
import {
    ORI_ICON_LAYER,
    ORI_LAYER,
} from '../../../../mapbox/services/layer-store.service';

@Injectable({
    providedIn: 'root',
})
export class CrimeService {
    constructor(
        private mapboxService: MapBoxService,
        private popupService: PopupService,
        private http: MapHttpService
    ) {}

    public handleOriClick(
        feature: MapboxGeoJSONFeature,
        coordinates: mapboxgl.LngLat
    ): void {
        this.http.getCrimeDataByOri(feature.properties!.ori).subscribe({
            next: (data: JsonResponse<CrimeRateByYearsResponse>) => {
                const crimesByOri = data.object.crimesByOri;

                if (crimesByOri.length === 0) {
                    console.warn(
                        'No info on this police station',
                        feature.properties!.ori
                    );
                }

                const latestYearData = crimesByOri.sort((a, b) => {
                    return b.year - a.year;
                })[0];

                const crimesByLocation: { [key: string]: number } = {};
                for (let crime in latestYearData.crimesByLocation) {
                    crimesByLocation['BY_LOCATION_' + crime] =
                        latestYearData.crimesByLocation[crime];
                }

                feature.properties = {
                    ...feature.properties,
                    ...latestYearData.crimes,
                    ...crimesByLocation,
                };

                this.popupService.handlePopup(feature, coordinates);
            },
            error: (error: Error) => {
                feature.properties = {
                    ...feature.properties,
                    errorDisclaimer: 'This area lacks sufficient data',
                };

                this.popupService.handlePopup(feature, coordinates);

                console.warn(error, feature.properties!.ori);
            },
        });
    }

    public setPointsLayerVisibility(checked: boolean): void {
        if (checked) {
            this.setOriLayerVisible();
        } else {
            this.hideOriLayer();
        }
    }

    private setOriLayerVisible(): void {
        this.mapboxService.map.setLayoutProperty(
            ORI_LAYER,
            'visibility',
            'visible'
        );
        this.mapboxService.map.setLayoutProperty(
            ORI_ICON_LAYER,
            'visibility',
            'visible'
        );
    }

    private hideOriLayer(): void {
        this.mapboxService.map.setLayoutProperty(
            ORI_LAYER,
            'visibility',
            'none'
        );
        this.mapboxService.map.setLayoutProperty(
            ORI_ICON_LAYER,
            'visibility',
            'none'
        );
    }

    public addOriLayer(minzoom: number): void {
        const layer = ORI_LAYER;

        if (!this.mapboxService.map.hasImage('cancel')) {
            this.mapboxService.map.loadImage(
                'assets/images/Police.png',
                (error, image) => {
                    if (error || !image) {
                        console.warn(
                            error ?? 'Error loading police station icon'
                        );
                        return;
                    }
                    this.mapboxService.map.addImage('cancel', image);
                }
            );
        }

        this.mapboxService.map.addLayer({
            id: layer,
            type: 'circle',
            source: layer,
            'source-layer': layer,
            maxzoom: 24,
            minzoom: minzoom,
            layout: {
                visibility: 'none',
            },
            paint: {
                'circle-radius': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    9,
                    [
                        'case',
                        ['==', ['feature-state', 'selected'], true],
                        ['*', 3, 2], // If selected, double the radius (3 * 2)
                        3, // Otherwise, use a radius of 3
                    ],
                    15,
                    [
                        'case',
                        ['==', ['feature-state', 'selected'], true],
                        ['*', 15, 2], // If selected, double the radius (15 * 2)
                        15, // Otherwise, use a radius of 15
                    ],
                ],
                'circle-color': '#333',
                'circle-opacity': [
                    'case',
                    ['==', ['feature-state', 'selected'], true],
                    1,
                    0.7,
                ],
            },
        });

        if (!this.mapboxService.map.getLayer(ORI_ICON_LAYER))
            this.mapboxService.map.addLayer({
                id: ORI_ICON_LAYER,
                type: 'symbol',
                source: layer,
                'source-layer': layer,
                maxzoom: 24,
                minzoom: minzoom,
                layout: {
                    'icon-image': 'cancel',
                    'icon-size': 0.55,
                    visibility: 'none',
                },
            });
    }
}
