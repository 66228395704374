import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MapUrlService } from '../../map/mapbox/services/map-url.service';
import {
  MAPBOX_CHANGE_ZOOM,
  MAPBOX_JUMP_TO,
  ROUTER_NAVIGATE,
} from './map-redirect-modal-text';
import { MapBoxService } from '../../map/mapbox/mapbox.service';

export interface MapModalData {
  headerText?: string;
  subHeaderText?: string;
  mainText?: string;
  URLtoGo?: string;
  linkText?: string;
  linkURL?: string;
  buttonText?: string;
  actionType?:
    | typeof ROUTER_NAVIGATE
    | typeof MAPBOX_JUMP_TO
    | typeof MAPBOX_CHANGE_ZOOM;
  jumpToZoom?: number;
  disableClose?: boolean;
  disclaimerText?: string;
}


@Component({
  selector: 'app-map-redirect-modal',
  templateUrl: './map-redirect-modal.component.html',
  styleUrls: ['./map-redirect-modal.component.scss',
  '../user.shared.scss',
  '../../app.component.scss']
})
export class MapRedirectModalComponent implements OnInit {

  headerText: string | undefined
  mainText: string | undefined
  URLtoGo: string | undefined
  linkText: string | undefined
  buttonText: string | undefined
  linkURL: string | undefined
  actionType: string | undefined
  subheaderText: string | undefined
  disableClose: boolean | undefined
  disclaimerText: string | undefined
  isDisclaimerOpened: boolean | undefined
  jumpToZoom: number | undefined

  constructor(private dialogRef: MatDialogRef<MapRedirectModalComponent>,
              public router: Router,
              private urlService: MapUrlService,
              private mapboxService: MapBoxService,
              @Inject(MAT_DIALOG_DATA) public data: MapModalData) {
    this.headerText = data.headerText
    this.mainText = data.mainText
    this.URLtoGo = data.URLtoGo
    this.linkText = data.linkText
    this.buttonText = data.buttonText
    this.linkURL = data.linkURL
    this.actionType = data.actionType
    this.subheaderText = data.subHeaderText
    this.disableClose = data.disableClose
    this.disclaimerText = data.disclaimerText
    this.jumpToZoom = data.jumpToZoom
  }

  ngOnInit(): void {
  }

  public close(): void {
    this.dialogRef.close()
  }

  public goToButton(): void {
    if (this.actionType === ROUTER_NAVIGATE) {
      this.navigateTo(this.URLtoGo)
    } else if (this.actionType === MAPBOX_JUMP_TO) {
      this.urlService.initWithParams(this.mapboxService.map, this.urlService.getUrlParams(this.URLtoGo))
    } else if (this.actionType === MAPBOX_CHANGE_ZOOM) {
      this.mapboxService.map.flyTo({zoom: this.jumpToZoom})
    }
    this.dialogRef.close();
  }

  public goToLink(): void {
    this.navigateTo(this.linkURL)
    this.dialogRef.close();
  }

  private navigateTo(url: string | undefined): void {
    this.router.navigate([url]);
  }

}
