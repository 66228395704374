import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    AuthenticationService,
    UserPersonalData,
} from '../authentication.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../map/mapbox/services/loading.service';
import { SubscriptionPlans } from './user.model';
import { UserAccessService } from '../access/user-access.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ModalService } from '../../shared/services/modal.service';
import { Meta, Title } from '@angular/platform-browser';
import { settingsPageTags } from '../../shared/util/seo-tags';

const ACCOUNT_SETTINGS = 'Account settings';
const MY_SEARCHES = 'My searches'
const SUBSCRIPTION = 'Subscription'

type ButtonSelection = typeof ACCOUNT_SETTINGS | typeof MY_SEARCHES | typeof SUBSCRIPTION

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss',
    '../../app.component.scss'],
})
export class UserComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<boolean>();

  public readonly accountSettings = ACCOUNT_SETTINGS
  public readonly mySearches = MY_SEARCHES
  public readonly subscription = SUBSCRIPTION

  public selectedButton: ButtonSelection | null = null;

  public userData: UserPersonalData | null = null;


  constructor(public authService: AuthenticationService,
              private router: Router,
              private toast: ToastrService,
              private accessService: UserAccessService,
              private modalService: ModalService,
              public loadingService: LoadingService,
              private meta: Meta,
              private title: Title) {
    this.authService.userPersonalData
      .pipe(takeUntil(this.destroy$))
      .subscribe(userChange => {
        this.userData = userChange
      });
  }

  ngOnInit(): void {
    this.title.setTitle(settingsPageTags.title)
    this.meta.addTags(settingsPageTags.tags)
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public onLogOutClick(): void {
    this.authService.logout()
  }

  public handleSeeAllPlans(): void {
    this.router.navigate(['user/subscription'])

    const subscriptions = document.querySelectorAll('.subscriptions-container');
    if (subscriptions[0]) {
      subscriptions[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  public handleAutoRenewalChange(isAutoRenewable: boolean): void {
    this.modalService.handleSubscriptionRenewalModal(isAutoRenewable)
  }

  public onSubscriptionChangedByAdmin(plan: SubscriptionPlans, email: string) {
    this.accessService.grantSubscription(email, plan, 1_000, false)
        .subscribe({
          next: () => {
            this.toast.success('Subscription plan is changed to ' + plan );
            // trigger info update to propagate plan change
            this.authService.refreshUserInfo();
          },
          error: () => this.toast.error( 'Failed to change subscription plan')
        }); }

  protected readonly SubscriptionPlans = SubscriptionPlans;
}
