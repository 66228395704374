export function getFRCLabels(roadClass: string): number {
  if (roadClass.includes('motorway') || roadClass.includes('motorway_link')) {
    return 0;
  }
  if (roadClass.includes('trunk') || roadClass.includes('trunk_link')) {
    return 1;
  }
  if (roadClass.includes('primary') || roadClass.includes('primary_link')) {
    return 2;
  }
  if (roadClass.includes('secondary') || roadClass.includes('secondary_link')) {
    return 3;
  }
  if (roadClass.includes('tertiary') || roadClass.includes('tertiary_link')) {
    return 4;
  }
  if (roadClass.includes('residential')) {
    return 5;
  }
  if (roadClass.includes('unclassified')) {
    return 6;
  }
  if (roadClass.includes('service')) {
    return 7;
  }
  return 8;
}
