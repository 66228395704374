import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserAgentService {
  constructor() {
    this.isUserAgentIos()
  }

  public isUserAgentIos(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;

    return isIOS
  }
}
