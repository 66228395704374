import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ColorscaleService} from "../mapbox/visualization/colorscale/colorscale.service";

@Component({
  selector: 'app-common-colorscale',
  templateUrl: './common-colorscale.component.html',
  styleUrls: ['./common-colorscale.component.scss',
  '../../app.component.scss']
})
export class CommonColorscaleComponent implements OnInit {
  @Input() currentLayer!: string
  @Input() isMobile: boolean = false

  @Output() openMenuMobile = new EventEmitter<void>();

  constructor(public colorScaleService: ColorscaleService) { }

  ngOnInit(): void {
  }

  public emitOpenMenuMobile(): void {
    this.openMenuMobile.emit();
  }
}
