import { Component, OnInit } from '@angular/core';
import { FeatureFilter, FiltersMenuService } from './filters-menu.service';

@Component({
  selector: 'app-filters-menu',
  templateUrl: './filters-menu.component.html',
  styleUrls: ['./filters-menu.component.scss'],
})
export class FiltersMenuComponent implements OnInit {
  public isListOpened: boolean = false;

  constructor(public filtersService: FiltersMenuService) {}

  ngOnInit(): void {
    this.filtersService.parseFeaturesIntoDropdown();
  }

  public updateFilterValue(
    event: Event,
    filter: FeatureFilter,
    input: string
  ): void {
    const inputValue = (event.target as HTMLInputElement).value.trim();
    (filter as any)[input] = inputValue === '' ? undefined : Number(inputValue);
    this.filtersService.updateFilters();
  }

  public onlyAllowNumbers(event: KeyboardEvent): void {
    const allowedKeys = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '.',
      '-',
      'Backspace',
    ];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  protected readonly Math = Math;
  protected readonly Number = Number;
}
