import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  SubscriptionPlanDescription,
  SubscriptionPlans,
} from '../../user.model';
import { ModalService } from '../../../../shared/services/modal.service';

@Component({
  selector: 'app-plan-with-description',
  templateUrl: './plan-with-description.component.html',
  styleUrls: [
    './plan-with-description.component.scss',
    '../../../../app.component.scss',
  ],
})
export class PlanWithDescriptionComponent implements OnInit {
  @Output() toggleOuterButton: EventEmitter<void> = new EventEmitter();

  @Input() planInfo!: SubscriptionPlanDescription;
  @Input() isThisSubscriptionActive: boolean = false;
  @Input() isPeriodMonthly!: boolean;
  @Input() isSubscriptionUrlSelected!: boolean;

  public isSeeIndicatorsActive: boolean = false;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  public handleSubscriptionClick(plan: SubscriptionPlans): void {
    this.modalService.handleSubscriptionConfirmationModal(
      plan,
      this.isPeriodMonthly
    );
  }
}
