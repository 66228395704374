import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-favorite-icon',
  templateUrl: './favorite-icon.component.html',
  styleUrls: ['./favorite-icon.component.scss']
})
export class FavoriteIconComponent implements OnInit {
  @Input() strokeColor!: string
  @Input() fillColor!: string
  @Input() height = '24px'
  @Input() width = '24px'
  @Input() strokeWidth = '1.25px'


  constructor() { }

  ngOnInit(): void {
  }

}
