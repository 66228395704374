<h4 *ngIf="properties.schoolName || properties.publicSchoolName" class="title school-name">
  {{ properties.schoolName ? (properties.schoolName | titlecase) : (properties.publicSchoolName | titlecase) }}
</h4>

<div class="school-subheader" *ngIf="properties.type">
  <div class="school-grade" *ngIf="properties[SCHOOL_GRADE_SCALE]" [style.background-color]="getGradeColor(properties[SCHOOL_GRADE_SCALE]!)">
    {{ properties[SCHOOL_GRADE_SCALE] }}
  </div>

  <span class="school-grade-numeric" *ngIf="properties[SCHOOL_GRADE_NUMERIC]">
    {{ properties[SCHOOL_GRADE_NUMERIC].toFixed(0) }}%
  </span>

  <span class="school-type">
    {{ properties.type }}
  </span>
</div>

<div class="popup-list">
  <ng-container *ngFor="let feature of properties | schoolFormat:isSeeMoreActive">
    <p *ngIf="feature.valueExists">
      <span class="feature-name">{{ feature.readableFeatureName }}:</span>
      <span class="feature-value">{{ feature.readableFeatureValue }}</span>
    </p>
  </ng-container>

  <p *ngIf="isSeeMoreActive && hasRaceData()" class="feature-name">Enrollment by race/ethnicity:</p>
  <table *ngIf="isSeeMoreActive && hasRaceData()" class="school-race-table">
    <tr class="header-row">
      <td *ngFor="let race of getRaces()">{{ race.acronym }}</td>
    </tr>
    <tr class="value-row">
      <td *ngFor="let race of getRaces()">{{ $any(properties)[race.key] }}</td>
    </tr>
  </table>
</div>


