import { Injectable } from '@angular/core';
import {
    ORI_LAYER,
    PRIVATE_SCHOOLS_LAYER,
    PUBLIC_SCHOOLS_LAYER,
    SourceDataStore,
} from '../../../mapbox/services/layer-store.service';
import { PopupService } from '../../../mapbox/services/popup.service';
import * as mapboxgl from 'mapbox-gl';
import { LngLat, MapboxGeoJSONFeature } from 'mapbox-gl';
import { CrimeService } from './crime/crime.service';
import {
    EducationService,
    schoolsPoiLayers,
} from './education/education.service';

export const pointsLayersList = [
    ORI_LAYER,
    PUBLIC_SCHOOLS_LAYER,
    PRIVATE_SCHOOLS_LAYER,
];

export interface CrimeRateByYearsResponse {
  crimesByOri: CrimeRateByYear[]
}

export interface CrimeRateByYear {
  year: number;
  crimes: {
    [key: string]: number
  },
  crimesByLocation: {
    [key: string]: number
  }
}

@Injectable({
  providedIn: 'root'
})
export class PointsService {

  constructor(
    private popupService: PopupService,
    private crimeService: CrimeService,
    private educationService: EducationService
  ) {
  }

  public addPointsLayers(sourceData: SourceDataStore, type: string, map: mapboxgl.Map): void {

    Object.keys(sourceData[type]).forEach((el) => {
      const layerId = sourceData[type][el as keyof typeof sourceData[typeof type]].type
      if (map.getLayer(layerId)) return

      if (layerId === ORI_LAYER) {
        this.crimeService.addOriLayer(sourceData[type][el as keyof typeof sourceData[typeof type]].minzoom)
      } else if (schoolsPoiLayers.includes(layerId)) {
        this.educationService.addSchoolsLayers(layerId, sourceData[type][el as keyof typeof sourceData[typeof type]].minzoom)
      }
    })
  }

  public handlePointsLayerSelection(feature: MapboxGeoJSONFeature, coordinates: LngLat) {
    this.popupService.popup.remove()

    if (feature.layer.id === ORI_LAYER) {
      this.crimeService.handleOriClick(feature, coordinates)
    } else if (feature.layer.id === PUBLIC_SCHOOLS_LAYER || feature.layer.id === PRIVATE_SCHOOLS_LAYER) {
      this.educationService.handleSchoolClick(feature, coordinates)
    }
  }
}
