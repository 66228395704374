<div class="forgot-password-container container">
  <div class="forgot-password-window main-window" *ngIf="!formSubmitted; else toLogin">
    <div
      (click)="goToSigninMenu()"
      class="menu-close-btn"
    >
      <img src="../../../assets/icons/rightMenuCloseBtn.svg" alt="exit">
    </div>

    <div class="menu-close-btn-mobile" (click)="goToSigninMenu()">
      <img src="../../../assets/icons/Cancel.svg" alt="exit">
    </div>

    <h2>Forgot password</h2>

    <form class="forgot-password-form" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
    <div class="input-wrapper">
      <div class="input-container">
        <label for="email">Email</label>
        <label *ngIf="isInputStatusInvalid('email')" for="email" class="invalid-email-label">Invalid
          email format</label>
        <input formControlName="email"
               [@shake]="emailShakeState"
               (@shake.done)="emailShakeState='inactive'"
               [class.invalid-input]="isInputStatusInvalid('email')"
               (ngModelChange)="formSubmitted = false"
               id="email">

      </div>
    </div>
    </form>

    <button class="gradient-button" (click)="onSubmit()">Reset password</button>

    <span class="go-to-signup">Do not have an account? <span class="link-like-text" (click)="goToSignupMenu()">Create account</span></span>
  </div>
</div>

<ng-template #toLogin>
  <div class="forgot-password-window main-window">
    <h2>Check your email</h2>

    <span class="check-your-email-span">An email with instructions to reset your password has been sent to your inbox.</span>
    <button (click)="goToSigninMenu()" class="gradient-button">To Log in</button>
  </div>
</ng-template>
