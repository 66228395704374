<mat-button-toggle
    #toggleButton
    (change)="toggleRadiusSelection($event.source.checked)"
    class="radius-toggle-btn"
>
    <mat-icon
        svgIcon="{{
            !isCircleSelectionActive
                ? 'radiusButtonActivate'
                : 'radiusButtonCancel'
        }}"
    >
    </mat-icon>
</mat-button-toggle>

<div *ngIf="isCircleSelectionActive" class="circle-slider-container">
    <h4>Radius</h4>
    <mat-slider
        (change)="changeSelectionRadius($event)"
        [(ngModel)]="circleRadius"
        class="cirlce-radius-slider"
        max="50"
        min="1"
        step="0.5"
  ></mat-slider>
  <p>{{ circleRadius }} miles</p>

  <div class="button-wrapper">
    <button (click)="handleRequestCombinedCell()"
            [ngClass]="(combinedCellService.cellCreationStatus | async) === 'in-progress' ? 'loading' : ((combinedCellService.cellCreationStatus | async) === 'error' ? 'error' : '')"
            class="gradient-button"
            [matTooltip]="'Click the point on the map'"
            [matTooltipPosition]="'right'"
            [disabled]="!selectionService.isCircleRendered"
    >{{ (combinedCellService.cellCreationStatus | async) === 'error' ? 'Error, try again' :
      ((combinedCellService.cellCreationStatus | async) === 'in-progress' ? 'Please wait' : 'Combine data')
      }}</button>

    <button *ngIf="selectionService.isCircleRendered"
            (click)="handleSelectNewLocation()"
            class="white-button-gradient reset-cell-button"
    >Reset radius</button>
  </div>


    <button (click)="toggleRadiusSelection(false)" class="slider-close-button">
        <mat-icon svgIcon="sliderButtonCancel"></mat-icon>
    </button>
</div>
