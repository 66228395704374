import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { AdminGuard } from './shared/guards/admin.guard';

const routes: Routes = [
    {
        path: 'info',
        title: 'Info - Map AI',
        loadChildren: () =>
            import('./support/info.module').then((m) => m.InfoModule),
    },
    {
        path: 'admin',
        title: 'Admin - Map AI',
        loadComponent: () =>
            import('./admin/admin.component').then((c) => c.AdminComponent),
        canLoad: [AdminGuard],
        canActivate: [AdminGuard],
    },
    {
        path: 'reports',
        title: 'Reports - Map AI',
        loadChildren: () =>
            import('./reports/reports.module').then((m) => m.ReportsModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
    },
    {
        path: 'user',
        title: 'User - Map AI',
        loadChildren: () =>
            import('./user/user.module').then((m) => m.UserModule),
        canActivate: [AuthGuard],
    },
    {
        path: '',
        title: 'Map AI',
        loadChildren: () => import('./map/map.module').then((m) => m.MapModule),
    },
    //TODO Fix sign-in/sign-up routes
    // {
    //   path: '**',
    //   title: 'Not Found',
    //   pathMatch: 'full',
    //   loadComponent: () => import('./shared/not-found/not-found.component').then(c => c.NotFoundComponent)
    // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: NoPreloading})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
