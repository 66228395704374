<svg class="icon" [ngStyle]="{'fill': fillColor, 'height': height, 'width': width}" width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_5034_7830)">
    <path [ngStyle]="{'stroke': strokeColor, 'stroke-width': strokeWidth}" d="M11.9625 6.8779L11.9625 6.87795L6.99962 12.1021L2.03732 6.87797L2.03723 6.87788C1.15083 5.94509 1.0162 4.67481 1.43352 3.61924C1.85041 2.56473 2.78783 1.79175 4.00713 1.79175C5.18079 1.79175 5.75438 2.41702 6.20723 2.91068C6.34841 3.06458 6.47786 3.20569 6.61009 3.31107L6.99974 3.6216L7.38929 3.31095C7.52049 3.20633 7.64941 3.06601 7.7902 2.91279C8.24369 2.41924 8.82024 1.79175 9.99209 1.79175C11.2156 1.79175 12.1506 2.56644 12.5655 3.62188C12.9813 4.6794 12.8441 5.9501 11.9625 6.8779Z" stroke="currentColor" stroke-width="1.25"/>
  </g>
  <defs>
    <clipPath id="clip0_5034_7830">
      <rect width="14" height="14" fill="white"/>
    </clipPath>
  </defs>
</svg>
