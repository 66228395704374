import { UserTip } from '../map.component';

export interface PopupLocation {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  x?: string;
  y?: string;
}

export const SEARCH = 'Search';
export const RADIUS_SELECTION = 'Radius selection';
export const LEVEL_SELECTION = 'Level selection';
export const MAPS = 'Maps';
export const CHARTS = 'Charts';

export const LENSES = 'Places';

export const userTips: UserTip[] = [
  {
    cssClass: 'indexesGroup',
    title: MAPS,
    textContent: 'To start exploring Map AI features, select the desired menu setting. Map colors will reflect the selected setting',
    mobile: true,
    closable: false
  },
  {
    cssClass: 'chartsGroup',
    title: CHARTS,
    textContent: 'To find out more about the location, go to Charts menu and access a variety of structured data',
    closable: false
  },
  {
    cssClass: 'lensesGroup',
    title: LENSES,
    textContent: 'Points provide focused access to data at various locations on the map',
    closable: false
  },
  {
    cssClass: 'search-open-button',
    title: SEARCH,
    textContent: 'Enter the address of the location you want to find',
    mobile: true,
    closable: true
  },
  // {
  //   cssClass: 'selection-tool',
  //   title: RADIUS_SELECTION,
  //   textContent: 'To limit the data display area with the Radius tool, select the desired location and choose the radius.'
  // },
  {
    cssClass: 'level-selection',
    title: LEVEL_SELECTION,
    textContent: 'You can choose the level of data display: state, county or cell (hexagon)',
    mobile: true,
    closable: true
  },

]

export function getPopupPosition(tip: UserTip, pointsTo: HTMLElement, isMobile: boolean): PopupLocation {
  const title = tip.title

  const rect = pointsTo.getBoundingClientRect()
  if (!isMobile) {

    if (title === SEARCH || title === RADIUS_SELECTION) {
      return {
        top: `${rect.top + rect.height + 30}px`,
        left: `${rect.left}px`
      }
    } else if (title === LEVEL_SELECTION) {
      return {
        top: `${rect.top + rect.height + 30}px`,
        left: `${rect.left + (rect.width / 2) - 35}px`
      }
    } else if (title === MAPS || title === CHARTS || title === LENSES) {
      return {
        top: `${rect.top - 10}px`,
        left: `${rect.left - 360}px`
      }
    }
    return {}
  } else {

    const viewportWidth = window.innerWidth;

    if (title === MAPS) {
      return {
        // As top we use common colorscale height +20px
        top: `${125}px`,
        // Find the middle of the screen
        left: `${(viewportWidth - 330) / 2}px`
      }
    } else if (title === SEARCH) {
      return  {
        top: `${rect.bottom - 220}px`,
        left: `${rect.left - 330 + 50}px`
      }
    } else if (title === LEVEL_SELECTION) {
      return  {
        // As top we use same value as for MAPS + level selection height and gap between them
        top: `${125 + 50}px`,
        left: `${(viewportWidth - 330) / 2}px`
      }
    }
    return {}
  }

}
