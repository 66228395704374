import {Injectable} from '@angular/core';
import {NaturalId} from "../map/menu/right-menu/layers-menu/population-menu/population.service";
import {MapHttpService} from "../map/mapbox/services/map-http.service";
import {SubscriptionPlans} from "./user/user.model";
import {catchError, Observable, of} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {JsonResponse} from "../shared/api/backend-config";
import {environment} from '../../environments/environment';

export interface AccessibleFeaturesIds {
  h3r7Cells: NaturalId[],
  h3r5Cells: NaturalId[]
}

export enum AccessibleFeatureState {
  LIMITED,
  ALL,
}

// If user payment card is linked and additional actions in stripe are not required for payment, then paymentUrl is null
export type SubscriptionPaymentUrl = { paymentUrl: string | null}

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {
  private readonly apiURL = environment.apiUrl
  private readonly userSubscriptionURL = this.apiURL + 'user/subscription/'

  // public accessibleFeaturesList: NaturalId[] = [];
  public accessibleFeatureState: AccessibleFeatureState = AccessibleFeatureState.ALL;

  constructor(private http: HttpClient,
              private mapHttp: MapHttpService) { }

  public handleSubscriptionPayment(planName: SubscriptionPlans, isPeriodMonthly: boolean): Observable<JsonResponse<SubscriptionPaymentUrl>> {
    return this.http
      .post<JsonResponse<SubscriptionPaymentUrl>>(`${this.userSubscriptionURL}?plan=${planName}&monthly=${isPeriodMonthly}`, null)
      .pipe(
        catchError((err) => {
          console.error(`SubscriptionsService, SUBSCRIPTION_PAYMENT_REQUEST: ${planName}`)
          throw of(null)
        })
      )
  }

  public updateSubscriptionAutoRenewal(isAutoRenewable: boolean): Observable<JsonResponse<any>> {
    return this.http.post<JsonResponse<any>>(`${this.userSubscriptionURL}${isAutoRenewable ? 'pause' : 'resume'}`, {})
  }

  // public setAccessibleFeaturesId(): void {
  //   this.mapHttp.getAccessibleFeatures()
  //     .subscribe(data => {
  //       if (data.object.h3r5Cells && data.object.h3r7Cells) {
  //         this.accessibleFeaturesList = [...data.object.h3r5Cells, ...data.object.h3r7Cells];
  //         this.accessibleFeatureState = AccessibleFeatureState.LIMITED;
  //       } else {
  //         this.accessibleFeatureState = AccessibleFeatureState.ALL;
  //       }
  //     });
  // }

  // public getAccessibleFeatures(): { state: AccessibleFeatureState, features: NaturalId[] } {
  //   return {
  //     state: this.accessibleFeatureState,
  //     features: this.accessibleFeaturesList,
  //   };
  // }

  public setAccessibleFeaturesAll(): void {
    this.accessibleFeatureState = AccessibleFeatureState.ALL
  }

  // public getIsAllFeaturesAvailable(): boolean {
  //   return this.getAccessibleFeatures().state === AccessibleFeatureState.ALL
  // }

  // public getIsFeatureAccessible(id: NaturalId): boolean {
  //   return this.getAccessibleFeatures().features.includes(id)
  // }
}
