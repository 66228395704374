import {MapboxGeoJSONFeature} from 'mapbox-gl';
import {writtenInStateFeatures} from "../../shared/types/feature-data-type";


/**
 * Focused features are ones that user interested the most,
 * so min/max and other analysis is counted for that features.
 */
export class FocusedFeatures {
  /**
   * Keeps all variables (distance, population, etc)
   * in the zone of interest like current selection or visible screen.
   */
  private readonly features: MapboxGeoJSONFeature[];

  public constructor(features: MapboxGeoJSONFeature[]) {
    this.features = features;
  }

  public isBeingLoaded(): boolean {
    return this.features.some(f => f.state.isBeingLoaded === true);
  }

  // TODO:refactor string to some type
  public ofType(layerType: string): NumberFeatures {
    const values = this.features
      .map((feature) => {
        if (writtenInStateFeatures.includes(layerType)){
          return feature.state[layerType] ?? null
        }


        return feature.properties![layerType] ?? null
      });
    return new NumberFeatures(values);
  }

  public getFeatures(): MapboxGeoJSONFeature[] {
    return this.features;
  }
}

/**
 * Bulk of features presented by numbers.
 * @example population
 */
export class NumberFeatures {
  private readonly _values;

  public constructor(values: number[]) {
    this._values = values;
  }

  public values(): number[] {
    return this._values;
  }
}
