<mat-nav-list class="navigation-menu-panel" *ngIf="!(breakpointObserverService.isMobile | async)">
  <mat-icon svgIcon="logo" class="logo-icon" routerLink="/"></mat-icon>

  <div class="navmenu-links">
    <a mat-icon-button href="http://map-ai.co" target="_blank">
      <mat-icon svgIcon="home"></mat-icon>
    </a>

    <a mat-icon-button routerLink="/">
      <mat-icon
        svgIcon="pointer"
        class="pointer-icon"
        [class.active-link-pointer]="isActiveWithQueryParams('/')"
      ></mat-icon>
      <mat-icon
        svgIcon="map"
        [class.active-link]="isActiveWithQueryParams('/')"
      ></mat-icon>
    </a>

    <a mat-icon-button routerLink="reports">
      <mat-icon
        svgIcon="pointer"
        class="pointer-icon"
        routerLinkActive="active-link-pointer"
        [routerLinkActiveOptions]="{ exact: false }"
      ></mat-icon>
      <mat-icon
        svgIcon="report"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: false }"
      ></mat-icon>
    </a>

    <a mat-icon-button routerLink="info">
      <mat-icon
        svgIcon="pointer"
        class="pointer-icon"
        routerLinkActive="active-link-pointer"
        [routerLinkActiveOptions]="{ exact: false }"
      ></mat-icon>
      <mat-icon
        svgIcon="help"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: false }"
      ></mat-icon>
    </a>
  </div>

  <div class="user-icon-container">
    <a *ngIf="accessService.getIsAdmin()"
       mat-icon-button
       [routerLink]="'admin'">
      <mat-icon
        routerLinkActive="active-link"
        svgIcon="adminPanel"
        class="admin-icon"
      ></mat-icon>
    </a>
    <a mat-icon-button class="user-icon-circle" [routerLink]="authService.hasAccessToken() ? 'user' : 'sign-in'">
      <mat-icon
        [svgIcon]="authService.hasAccessToken() ? 'user' : 'logIn'"
        class="user-icon"></mat-icon>
    </a>
  </div>

</mat-nav-list>

<nav class="navigation-menu-panel-mobile" *ngIf="breakpointObserverService.isMobile | async">

  <div class="navmenu-links">
    <a href="http://map-ai.co" target="_blank">
      <mat-icon svgIcon="home"></mat-icon>
    </a>

    <a [routerLink]="'/'">
      <mat-icon
        svgIcon="pointer"
        class="pointer-icon"
        [class.active-link-pointer]="isActiveWithQueryParams('/')"
      ></mat-icon>
      <mat-icon
        svgIcon="map"
        [class.active-link]="isActiveWithQueryParams('/')"
      ></mat-icon>
    </a>

    <a [routerLink]="authService.hasAccessToken() ? 'user' : 'sign-in'" class="user-icon">
      <mat-icon
        svgIcon="pointer"
        class="pointer-icon"
        routerLinkActive="active-link-pointer"
        [routerLinkActiveOptions]="{ exact: false }"
      ></mat-icon>
      <mat-icon
        [svgIcon]="authService.hasAccessToken() ? 'user' : 'logIn'"
        [class.active-link]="isActiveWithQueryParams('/user')"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: false }"
      ></mat-icon>
    </a>

    <a *ngIf="accessService.getIsAdmin()"
       mat-icon-button
       [routerLink]="'admin'">
      <mat-icon
        svgIcon="pointer"
        class="pointer-icon"
        routerLinkActive="active-link-pointer"
        [routerLinkActiveOptions]="{ exact: false }"
      ></mat-icon>
      <mat-icon
        svgIcon="adminPanel"
        class="admin-icon"
      ></mat-icon>
    </a>

    <a [routerLink]="'/reports'">
      <mat-icon
        svgIcon="pointer"
        class="pointer-icon"
        routerLinkActive="active-link-pointer"
        [routerLinkActiveOptions]="{ exact: false }"
      ></mat-icon>

      <mat-icon
        svgIcon="report"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: false }"
      ></mat-icon>
    </a>

    <a [routerLink]="'/info'">
      <mat-icon
        svgIcon="pointer"
        class="pointer-icon"
        routerLinkActive="active-link-pointer"
        [routerLinkActiveOptions]="{ exact: false }"
      ></mat-icon>
      <mat-icon
        svgIcon="help"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: false }"
      ></mat-icon>
    </a>
  </div>
</nav>

