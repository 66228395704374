<div class="carousel-container">
  <div class="image-container">
    <img *ngIf="images.length"
         [src]="images[currentIndex]"
         width="400"
         height="600"
         [alt]="imagesAlt[currentIndex]">
  </div>

  <div class="indicators">
    <button *ngFor="let image of images; let i = index"
          [class.active]="i === currentIndex"
          (click)="selectImage(i)">
    </button>
  </div>
</div>
