export class Buckets {

  constructor(private bucketBounds: number[]) {
    if (bucketBounds.length > 1) {
      for (let i = 1; i < bucketBounds.length; i++) {
        if (bucketBounds[i] < bucketBounds[i] - 1) {
          throw Error('Buckets must be ordered ASC, got ' + bucketBounds);
        }
      }
    }
  }

  public countBucketsWithin(values: number[]): number[] {
    const res = [];
    let min: number;
    let max: number;

    for (let i = 0; i < this.bucketBounds.length + 1; i++) {
      if (i < this.bucketBounds.length) {
        max = this.bucketBounds[i];
      } else {
        max = Number.POSITIVE_INFINITY;
      }

      if (i > 0) {
        min = this.bucketBounds[i - 1];
      } else {
        min = Number.NEGATIVE_INFINITY;
      }
      let elementsWithin = 0;
      values.forEach(v => {
        if (v >= min && v < max) {
          elementsWithin++;
        }
      });
      res.push(elementsWithin)
    }

    return res;
  }
}