import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ChartControlBlock} from "../../menu-group/menu-group.component";
import {FormControl} from "@angular/forms";
import {FeatureStatus} from "../../population-menu/population.service";
import {UserAccessService} from "../../../../../../user/access/user-access.service";

@Component({
  selector: 'app-chart-block',
  templateUrl: './chart-block.component.html',
  styleUrls: ['./chart-block.component.scss',
  '../../../../../../app.component.scss',]
})
export class ChartBlockComponent implements OnInit {

  @Output() changeSelectedYear = new EventEmitter<{ year: number, usedFor: string }>()

  @Input() chart!: ChartControlBlock
  @Input() toggle!: FormControl
  @Input() featureStatus!: FeatureStatus
  @Input() disabled!: boolean

  public readonly defaultChartStyle = {'height': '430px', 'margin-top': '0px'}


  public wasYearSelected = true


  constructor(private accessService: UserAccessService) { }

  ngOnInit(): void {
    if (this.toggle) {
      this.toggle.valueChanges.subscribe(value => {
        if (value) {
          if (!this.accessService.checkAccessIfUnauthorized()) {
            return;
          }
        }
      })
    }
  }

  public onSelectedYearChange(data: { year: number, usedFor: string }): void {
    this.changeSelectedYear.emit(data)
  }

}
