<div>
  <h4 *ngIf="properties!['agency_name']" class="title">{{ properties!['agency_name'] }}</h4>
  <div *ngIf="properties!['errorDisclaimer']" class="error-disclaimer">
    <p>{{ properties!['errorDisclaimer'] }}</p>
  </div>
  <div class="popup-list" *ngIf="!properties!['errorDisclaimer']">
    <ng-container *ngFor="let group of groupedCrimes">
      <h4 class="group-title" *ngIf="group.categories.length > 0">{{ group.groupName }}: {{ isSeeMoreActive ? '' : group.totalValue }}</h4>
      <ul *ngIf="isSeeMoreActive">
        <ng-container *ngFor="let category of group.categories">
          <li *ngIf="category.count > 0">
            <p class="category">
              <span class="category-name">{{ category.categoryName }}:</span>
              <span class="category-value">{{ category.count }}</span>
            </p>
          </li>
        </ng-container>
      </ul>
    </ng-container>

    <h4 class="group-title" *ngIf="isSeeMoreActive && groupedByLocation.length > 0">Crimes by facility type, %</h4>
    <ul *ngIf="isSeeMoreActive">
      <ng-container *ngFor="let location of groupedByLocation">
        <li>
          <p class="category">
            <span class="category-name">{{ location.locationName }}:</span>
            <span class="category-value">{{ location.percentage }}%</span>
          </p>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
