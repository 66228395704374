import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserverService } from './shared/services/breakpoint-observer.service';
import { LayersMenuService } from './map/menu/right-menu/layers-menu/layers-menu.service';
import { AuthenticationService } from './user/authentication.service';
import { catchError, of, take } from 'rxjs';
import { setSentryUser } from './user/user/user.util';
import { UserAccessService } from './user/access/user-access.service';
import { DeviceOrientationService } from './shared/services/device-orientation.service';
import { ModalService } from './shared/services/modal.service';

export const REDIRECT_AFTER_LOGIN_PATH = 'REDIRECT_AFTER_LOGIN_PATH';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'map-ai';

  constructor(private router: Router,
              public breakpointObserverService: BreakpointObserverService,
              public menuService: LayersMenuService,
              private authService: AuthenticationService,
              private accessService: UserAccessService,
              private orientationService: DeviceOrientationService,
              private modalService: ModalService) {
    this.handleOrientationSubscription()
  }

  ngOnInit() {
    this.accessService.checkMapUnauthorizedAccessibility()
    this.accessService.triggerSiteOpenedEvent()

    this.handleIsLoggedInSubscription()
    this.orientationService.detectOrientation()
  }

  public isAllowedUnauthorized(): boolean {
    const currentUrl = this.router.url;
    const allowedPaths = ['password-recovery', 'user/password/reset', 'sign-up', 'sign-in', 'sign-up/individual', 'sign-up/enterprise', 'info' ];

    // Handle case when user is logged in and resets password, this way we show him sidenav and do not redirect from reset page
    if (currentUrl.includes('user/password/reset') && this.authService.getUserEmail()) {
      return false
    }

    return allowedPaths.some(path => currentUrl.includes(path));
  }

  private handleIsLoggedInSubscription(): void {
    if (this.authService.hasAccessToken()) {
      this.authService.userPersonalData
        .pipe(take(1),
          catchError((err) => {
            if (this.isAllowedUnauthorized()) {
              return of(null)
            }

            this.router.navigate(['sign-in'])
            this.authService.logout()
            throw err
          }))
        .subscribe(data => {
          if (data) {
            this.accessService.setUserPlan(data)
            setSentryUser(data)
            if (this.isAllowedUnauthorized()) {
              this.router.navigate(['/'])
            }
          }
        })

      this.authService.refreshUserInfo()
    }
  }

  private handleOrientationSubscription(): void {
    this.orientationService.orientation.subscribe(orientation => {
      this.modalService.handleSwitchLandscapeModal(orientation)
    })
  }

}
